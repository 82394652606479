import React from 'react';
import logo from './hotel_logo.png';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import Slider from './CardSlider';
import Header from './Header';

const Booking = () => {
  return (
    <div>
    <Header />
  

    <section class="breadcrumb-area overlay-dark-2 bg-3">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb-text text-center">
                    <h2>Classic Room Details</h2>
                    <p>A quality of classic room details in maskva</p>
                    <div class="breadcrumb-bar">
                        <ul class="breadcrumb">
                            <li><Link to="/">Home</Link></li>
                            <li>Room Details</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


    <section class="room-details pt-90">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-lg-8 col-12">
                    <div class="room-slider-wrapper">
                    <div class="room-slider">
                    <Slider />
                    </div>
                        
                        
                    </div>
                    <div class="room-details-text">
                        <h3 class="room-details-title">Description Of Classic Room</h3>
                        <p>The Moskva Hotel, a Madurai hotel, features 44 elegantly appointed spacious rooms, including 9 Standard Rooms, 12 Deluxe Rooms, 16 Fortune Club Rooms, 3 Suites, and 5 Exclusive Club Rooms, considered the best among all the hotels in Madurai. The rooms offer a wide range of modern amenities to suit the needs of the discerning traveler.</p>
                        <p>Moskva Hotel is a 3-star property located 15 kilometers from Madurai Airport, 900 meters from Madurai Junction Railway Station, and one kilometer from Periyar Bus Stand. Hotel Moskva features luxurious and well-appointed rooms equipped with modern conveniences such as in-room amenities like cable TV</p>
                        <p>Built around the Meenakshi Amman Temple, Madurai, in many ways, can stake claim to being the soul of Tamil Nadu. Its position as a leading destination for global travel and business events has evolved as a popular destination for travellers of all categories.</p>
                    </div>
                    <div class="room-facilities">
                        <h3 class="room-details-title">room facilities</h3>
                        <div class="single-facility">
                            <span><i class="zmdi zmdi-check"></i>Single Room</span>
                            <span><i class="zmdi zmdi-check"></i>70 sq mt</span>
                            <span><i class="zmdi zmdi-check"></i>3 Persons</span>
                            <span><i class="zmdi zmdi-check"></i>Free Internet</span>
                        </div>
                        <div class="single-facility">
                            <span><i class="zmdi zmdi-check"></i>Breakfast Inclide</span>
                            <span><i class="zmdi zmdi-close"></i>Free wi-fi</span>
                            <span><i class="zmdi zmdi-check"></i>Private Balcony</span>
                            <span><i class="zmdi zmdi-check"></i>Free Newspaper</span>
                        </div>
                        <div class="single-facility">
                            <span><i class="zmdi zmdi-check"></i>Full AC</span>
                            <span><i class="zmdi zmdi-close"></i>Flat Screen TV</span>
                            <span><i class="zmdi zmdi-check"></i>Beach View</span>
                            <span><i class="zmdi zmdi-close"></i>Room Service</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-12">
                    <div class="sidebar-widget">
                        <h3 class="room-details-title">your reservation</h3>
                        <form action="#" method="post" class="search-form">
                            <div class="form-container fix">
                                
                                <Link to="/bookingformfetch" className=""><button type="submit" class="search default-btn">Book Now</button></Link>
                               
                            </div>
                        </form>
                    </div>
                    <div class="sidebar-widget">
                        <div class="c-info-text">
                            <p>If you have any question please don't hesitate to contact us</p>
                        </div>
                        <div class="c-info">
                            <span><i class="zmdi zmdi-phone"></i></span>
                            <span>+91-8489914838<br />+91-452 4219063</span>
                        </div>
                        <div class="c-info">
                            <span><i class="zmdi zmdi-email"></i></span>
                            <span>info@moskvahotel.in<br />info@moskvahotel.in</span>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="room-area">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h3 class="room-details-title">similar rooms</h3>
                    </div>
                </div>
            </div>
            <div className="container-fluid overflow-hidden">
      <div className="single-room small">
        <img src="assets/img/moskva-photos/roomsmall/home/DSC0998.jpg" alt="" />
        <div className="room-hover text-center">
          <div className="hover-text">
            <h3><Link to="room-details.html">Classic</Link></h3>
            <p>
            A string of amenities at this hotel cover the major aspects of a comfortable and easy-going stay. Primary services involve room.
            </p>
            <div className="room-btn">
              <Link to="/booking" className="default-btn">DETAILS</Link>
            </div>
          </div>
          <div className="p-amount">
          <span className="count">Double</span>
            <span>₹2500</span>
            <span className="count">Per Night</span>
          </div>
          <div className="p-amount1" >
          <span className="count">Single</span>  
          <span>₹2100</span>
            <span className="count">Per Night</span>
          </div>
        </div>
      </div>
      <div className="single-room large">
        <img src="assets/img/moskva-photos/roomsmall/home/ch/dsc2.jpg" alt="" />
        <div className="room-hover text-center">
          <div className="hover-text">
            <h3><Link to="room-details.html">Continental</Link></h3>
            <p>A string of amenities at this hotel cover the major aspects of a comfortable and easy-going stay. Primary services involve room.
            </p>
            <div className="room-btn">
              <Link to="/continental" className="default-btn">DETAILS</Link>
            </div>
          </div>
          <div className="p-amount">
          <span className="count">Double</span> 
            <span>₹3000</span>
            <span className="count">Per Night</span>
          </div>
          <div className="p-amount1" >
          <span className="count">Single</span>  
          <span>₹2500</span>
            <span className="count">Per Night</span>
          </div>
        </div>
      </div>
      <div className="single-room small">
        <img src="assets/img/moskva-photos/roomsmall/home/ch/DSC0980.jpg" alt="" />
        <div className="room-hover text-center">
          <div className="hover-text">
            <h3><Link to="room-details.html">Deluxe Suit</Link></h3>
            <p>
            A string of amenities at this hotel cover the major aspects of a comfortable and easy-going stay. Primary services involve room.
            </p>
            <div className="room-btn">
              <Link to="/deluxe" className="default-btn">DETAILS</Link>
            </div>
          </div>
          <div className="p-amount">
          <span className="count">Double</span> 
            <span>₹3500</span>
            <span className="count">Per Night</span>
          </div>
          <div className="p-amount1" >
          <span className="count">Single</span>  
          <span>₹3000</span>
            <span className="count">Per Night</span>
          </div>
        </div>
      </div>
      <div className="single-room medium">
        <img src="assets/img/moskva-photos/roomsmall/home/ch/DSCN6881.jpg" alt="" />
        <div className="room-hover text-center">
          <div className="hover-text">
            <h3><Link to="room-details.html">Presidential Suite</Link></h3>
            <p>
            A string of amenities at this hotel cover the major aspects of a comfortable and easy-going stay. Primary services involve room.
            </p>
            <div className="room-btn">
              <Link to="/presidential" className="default-btn">DETAILS</Link>
            </div>
          </div>
          <div className="p-amount">
          <span className="count">Double</span> 
            <span>₹6000</span>
            <span className="count">Per Night</span>
          </div>
          <div className="p-amount1" >
          <span className="count">Single</span>  
          <span>₹6000</span>
            <span className="count">Per Night</span>
          </div>
        </div>
      </div>
    </div>
        </div>
    </section>

    

    <section className="newsletter-area bg-light">
      <div className="container">
        <div className="row">
          <div className="col-xl-8 mx-auto col-12">
            <div className="newsletter-container">
              <h3>NewsLetter Sign-Up</h3>
              <div className="newsletter-form">
                <form action="#" id="mc-form" className="mc-form fix">
                  <input id="mc-email" type="email" name="email" placeholder="Enter your E-mail"/>
                  <button id="mc-submit" type="submit" className="default-btn">subcribes</button>
                </form>
               
                <div className="mailchimp-alerts">
                  <div className="mailchimp-submitting"></div>
                  
                  <div className="mailchimp-success"></div>
                 
                  <div className="mailchimp-error"></div>
                  
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>



    <footer className="footer-area">
      
      <div className="footer-widget-area bg-dark">
        <div className="container">
          <div className="row mb-n60">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <div className="footer-logo">
                  <Link to="#"><img src={logo} alt="Oestin" style={{ width: "14rem" }} /></Link>
                </div>
                <p>
                Moskva Hotel is a 3-star property located 15 kilometers from Madurai Airport, 900 meters from Madurai Junction Railway Station, and one kilometer from Periyar Bus Stand.
                </p>
                <div className="social-icons">
                  <Link to="#"><i className="zmdi zmdi-facebook"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-instagram"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-rss"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-twitter"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-pinterest"></i></Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 offset-lg-1 col-lg-4  col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <h3>contact us</h3>
                <div className="c-info">
                  <span><i className="zmdi zmdi-pin"></i></span>
                  <span>57, Tamilsangam Road, Madurai,  <br />Tamil Nadu 625001</span>
                </div>
                <div className="c-info">
                  <span><i className="zmdi zmdi-email"></i></span>
                  <span>info@moskvahotel.in<br />info@moskvahotel.in</span>
                </div>
                <div className="c-info">
                  <span><i className="zmdi zmdi-phone"></i></span>
                  <span>+91-8489914838<br />+91-452 4219063</span>
                </div>
                <div style={{display:"flex"}}>
                <div className="footer-logo">
                    <a href="https://play.google.com/store/games?hl=en&gl=US&pli=1"><img src="assets/img/moskva-photos/small/plays.png" alt="Oestin" style={{ width: "10rem" }} /></a>
                  </div>
                  <div className="footer-logo" style={{paddingLeft:"5px"}}>
                    <a href="https://www.apple.com/in/app-store/"><img src="assets/img/moskva-photos/small/play.png" alt="Oestin" style={{ width: "10rem" }} /></a>
                  </div>
                  </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <h3>quick links</h3>
                <ul className="footer-list">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/rooms">Rooms</Link></li>
                <li><Link to="/benquetmenu">Banquets Menu</Link></li>
                <li><Link to="/about">About</Link></li>
                <li><Link to="/resturants">Resturants</Link></li>
                <li><Link to="/bars">Bar</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <h3>collections</h3>
                <div className="instagram-image">
                <div className="footer-img">
                  <Link to="#"><img src="assets/img/moskva-photos/small/DSC0962.jpg" alt=""/></Link>
                </div>
                <div className="footer-img">
                  <Link to="#"><img src="assets/img/moskva-photos/small/DSC0980.jpg" alt=""/></Link>
                </div>
                <div className="footer-img">
                  <Link to="#"><img src="assets/img/moskva-photos/small/DSC1004.jpg" alt="" /></Link>
                </div>
                <div className="footer-img">
                  <Link to="#" ><img src="assets/img/moskva-photos/small/DSC1063.jpg" alt="" /></Link>
                </div>
                <div className="footer-img">
                  <Link to="#"><img src="assets/img/moskva-photos/small/DSC1071.jpg" alt="" /></Link>
                </div>
                <div className="footer-img">
                  <Link to="#" ><img src="assets/img/moskva-photos/small/DSC1092.jpg" alt="" /></Link>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div className='text-center'> 
        <p className="designFont">© 2023 MOSKVA All rights reserved. Designed By JOFINITY</p>
        </div>
      </div>
      
      
     
    </footer>
    </div>
  )
}

export default Booking