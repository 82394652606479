import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { BiRupee } from 'react-icons/bi';
import logo from './../hotel_logo.png';
import Navbar from './../Navbar';
//import ChildSelection from '../bookForm/childSelection';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import Appsubmitbutton from "../components/appsubmitbutton/Appsubmitbutton";
import { useFirestore } from './../../hooks/useFirestore';
import { useAuthContext } from './../../hooks/useAuthContext';
import Header from '../Header';

const Deluxebookdetail = ({post}) => {
    const [count, setCount] = useState(1); // useState returns a pair. 'count' is the current state. 'setCount' is a function we can use to update the state.
    //const [stock, setStock] = useState(availableItems);
    const [startDate, startTime] = useState(new Date());
    const [endDate, endTime] = useState(new Date());
    const [duration, setDuration] = useState("");
    //const [isChecked, setIsChecked] = useState(false);
    const [food, setFood] = React.useState(false);
    const [bed, setBed] = React.useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [location, setLocation] = useState("");
    const [phonenumber, setPhonenumber] = useState("");
    const [expect, setExpect] = useState("");
    const [validationError, setValidationError] = useState("");
  
  //Child Section States:
  const [isVisible, setIsVisible] = useState(false);
  // const [isShow, setIsShow] = useState(false);
  const [child, setChild] = useState(0);
  const [firstage, setFirstage] = useState(0);
  const [secondage, setSecondage] = useState(0);
  const [thirdage, setThirdage] = useState(0);
  const [fourage, setFourage] = useState(0);
  const [fiveage, setFiveage] = useState(0);
  const [secondVisible, setSecondVisible] = useState(false);
  const [thirdVisible, setThirdVisible] = useState(false);
  const [fourVisible, setFourVisible] = useState(false);
  const [fiveVisible, setFiveVisible] = useState(false);
  const [aboveChild, setAboveChild] = useState(false);
  const [belowChild, setBelowChild] = useState(false);
  const childChoose = () => {
    setChild(child + 1);
    if (child === 0) {
        setIsVisible(true);
        setBelowChild(false);
    } else if (child === 1) {
        setSecondVisible(true);
    }
    else if (child === 2) {
        setThirdVisible(true);
    }
    else if (child === 3) {
        setFourVisible(true);
    }
    else if (child === 4) {
        setFiveVisible(true);
    }
    else if (child === 5) {
        //alert('Not Allowd to above five child on per room');
        toast("Not Allowd to above five child on per room", { 
            position: "top-center",
            theme:"dark"
           });
        setAboveChild(true);
        setChild(child - 0);
    }
    
  }
  const childReduce = () => {
    setChild(child - 1);
    if (child === 1) {
        setIsVisible(false);
    } else if (child === 2) {
        setSecondVisible(false);
    }
    else if (child === 3) {
        setThirdVisible(false);
    }
    else if (child === 4) {
        setFourVisible(false);
    }
    else if (child === 5) {
        setFiveVisible(false);
        //alert('Not Allowd to above five child on per room');
        setAboveChild(false);
        setChild(child - 1);
    }
    else if (child >= 0) {
      //  alert('No Response- Please Refresh the page');
        setBelowChild(true);
        setChild(0);
    }
  }
  const FirstChildAgePlus = () => {
    setFirstage(firstage + 1);
    if(firstage >= 6 ){
       toast("Charges Will be applicable for above six years!", { 
        position: "top-center",
        theme:"dark"
       });
        setFirstage(firstage + 0);
    }
  
  }
  const FirstChildAgeReduce = () => {
    setFirstage(firstage - 1);
  
  }
  const SecondChildAgePlus = () => {
    setSecondage(secondage + 1);
  
  }
  const SecondChildAgeReduce = () => {
    setSecondage(secondage - 1);
  
  }
  const ThirdChildAgePlus = () => {
    setThirdage(thirdage + 1);
  
  }
  const ThirdChildAgeReduce = () => {
    setThirdage(thirdage - 1);
  
  }
  const FourChildAgePlus = () => {
    setFourage(fourage + 1);
  
  }
  const FourChildAgeReduce = () => {
    setFourage(fourage - 1);
  
  }
  const FiveChildAgePlus = () => {
    setFiveage(fiveage + 1);
  
  }
  const FiveChildAgeReduce = () => {
    setFiveage(fiveage - 1);
  
  }
  
  
  
  
  
    const {user,isAuthReady} = useAuthContext();
    const navigate = useNavigate()
    console.log(post.id);
    console.log(post.Price);
    console.log(post.Double);
    console.log(post.Third);
    var quantity = post.Price;
     var amount=  post.Discount;
    //const [fruit, setFruit] = useState();
  
    const timeCalc = () => {
      var start = moment(startDate);
      var end = moment(endDate);
      //console.log(end.diff(start, "days", true) + ' days');
      setDuration(end.diff(start, "days") + 0);
    }
    // var a = moment(new Date()); //todays date
    //var b = moment("2022-12-16"); // another date
  
    //var days = b.diff(a, 'days') ;
    //console.log(days)
    //console.log(availableItems[0].quantity);
    const increaseQuantity = () => {
     // const currentItems = [...stock];
      quantity += 0;
      //currentItems[index].amount += 1;
     // setStock(currentItems);
      setCount(count+1);
      //console.log(duration * jsonObj[0]['quantity']);
     // var daysPrice = duration * availableItems[0].quantity;
      // var final = daysPrice + 1500;
    //  console.log("Select the room price " + daysPrice);
   
    
    };
  
    const decreaseQuantity = () => {
      //const currentItems = [...stock];
  
      if (quantity > 1) {
        quantity -= 0;
        //currentItems[index].amount -= 1;
        //setStock(currentItems);
      }
      setCount(count-1);
    };
  
    // const changeHandler = (e) => {
    //   if (e.target.value === 'country') {
    //     setIsVisible(true);
    //   } else {
    //     setIsVisible(false);
    //   }
    // };
    // const changeRoomHandler = (e) => {
    //   if (e.target.value === 'roomCh') {
    //     setIsShow(true);
    //   } else {
    //     setIsShow(false);
    //   }
    // };
    var fd = post.Food;
  if( food === false ){
    console.log('check');
    //var durationPrice = duration * availableItems[0].quantity;
    var f1 = 0 * count;
    var FoodYes = 'Yes';
    //console.log(FoodYes);
  }
  else{
    console.log('not check');
    //var durationPrice = duration * availableItems[0].quantity - 100;
   // var f1 = fd * count;
    var f1 = fd * duration * count;
    var FoodYes = 'No';
    //console.log(FoodYes);
  }
  
    
  const handleChange = () => {
      setFood(!food);
    };
  
    const handleBedChange = () => {
      setBed(!bed);
    };
    if( bed === false ){
      var BedYes = 'King';
      console.log(BedYes);
    }
    else{
      var BedYes = 'Twin';
      console.log(BedYes);
    }
  
  
  console.log(f1);
  //Room Names Showns
  var rmName = post.RoomName;
  var roomname = rmName+ " Room";
  console.log(roomname);
    //console.log(count);
    var durationPrice = duration * quantity;
    //console.log(durationPrice + 400 + 500 + durationPrice);
    var due;
    var d = post.Double;
    var th = post.Third;
    const v1 = d * duration;
    const v2 = th * duration;
      if(count === 1) {
        due = 0 - f1 ;
       }
       else if(count === 2)
       {
        
        due =  v1 - f1;
       }
       else if(count === 3)
       {
        due = v1 + v2 - f1;
       }
       else if(count === 4)
       {
        //alert('Please Select the second Room');
        toast("Please Select the second Room", { 
          position: "top-center",
          theme:"dark"
         });
        due =  durationPrice + v1 + v2 - f1;
       }
       else if(count === 5)
       {
        due =  durationPrice + v1 + v2 + v1 - f1;
       }
       else if(count === 6)
       {
        due =  durationPrice + v1 + v2 + v1 + v2 - f1;
       }
       else if(count === 7)
       {
       // alert('Please Select the third Room');
        toast("Please Select the third Room", { 
          position: "top-center",
          theme:"dark"
         });
        due =  durationPrice + v1 + v2 + v1 + v2 + durationPrice - f1;
       }
       else if(count === 8)
       {
        due =  durationPrice + v1 + v2 + v1 + v2 + durationPrice + v1 - f1;
       }
       else if(count === 9)
       {
        due =  durationPrice + v1 + v2 + v1 + v2 + durationPrice + v1 + v2 - f1;
       }
       else if(count === 10)
       {
        //alert('Please Select the fourth Room');
        toast("Please Select the fourth Room", { 
          position: "top-center",
          theme:"dark"
         });
        due =  durationPrice + v1 + v2 + v1 + v2 + durationPrice + v1 + v2 + durationPrice - f1;
       }
       else 
       {
        due =  durationPrice + durationPrice;
       }
   // console.log("Single Room " + duration * availableItems[0].quantity);
    //console.log("Duration of days Room " + duration * availableItems[0].quantity);
    var durationsPrice = duration * quantity;
    var discountPrice = amount;
    var totals = durationsPrice - discountPrice + due;
    var taxPrice = durationPrice + due;
    var t1 = 12;
    var ct = 6
    var tax = taxPrice * t1 / 100;
    var cgst = taxPrice * ct / 100;
    var sgst = taxPrice * ct / 100;
    //var total = totals + tax + cgst + sgst;
    var total = totals + tax;
    //console.log(tax);
    //console.log(total);
    // var stringify = JSON.stringify(stock);
    //var jsonObj = JSON.parse(stringify);
    // console.log(duration * jsonObj[0]['quantity']);
    const {addDocument,error} = useFirestore('booking');
    const handleSubmit = async (e) => {
      e.preventDefault();
      if (!name) {
        setValidationError("Name should not be empty");
        return;
      }
      if (!email) {
        setValidationError("Email should not be empty");
        return;
      }
      if (!phonenumber) {
        setValidationError("PhoneNumber should not be empty");
        return;
      }
      if (!startDate) {
        setValidationError("Please Choose the Check in Date");
        return;
      }
      if (!endDate) {
        setValidationError("Please Choose the Check Out Date");
        return;
      }
      if (!duration) {
        setValidationError("Duration should not be empty");
        return;
      }
      if (!FoodYes) {
        setValidationError("Please Confirm the Breakfase If you need or not");
        return;
      }
  
      // addDocument({ title, body: content, userId:user.uid})
      addDocument({ Name: name, Email: email, Message: location, PhoneNumber: phonenumber,RoomType:roomname, ExpectationTime: expect, Startsdate:startDate, EndsDate:endDate, Adult: count, Child:child, FirstAge:firstage, DurationTime: duration, Food: FoodYes, Bed: BedYes, Price: taxPrice, CgstTax: cgst, SGSTTax: sgst, TotalPrice: total,Tax:tax, Discount: amount, userId:user.uid})
      navigate('/deluxebookingview')
  
    };
    return (
      <div>
      <Header />
        <section class="breadcrumb-area overlay-dark-2 bg-3">
      <div class="container">
          <div class="row">
              <div class="col-12">
                  <div class="breadcrumb-text text-center">
                      <h2>Booking the Deluxe Room</h2>
                      <p>A quality room of deluxe room details in maskva</p>
                      <div class="breadcrumb-bar">
                          <ul class="breadcrumb">
                              <li><Link to="/">Home</Link></li>
                              <li>Room Details</li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
        <section className="pt-5 ">
          <div className="container ">
         
            <div className="row">
              <div className="col-md-10 mx-auto col-12 card shadow-lg border-0 p-4">
                <div>
                  <h1 className="display-4 section-title text-center labelHead">Booking the Room</h1>
                </div>
                <div className="row">
                  <div className="col-md-6 col-12 my-auto">
                    <img src="assets/img/moskva-photos/about.jpg" className="" alt="selected room" style={{ width: "100%", height: "25rem" }} />
                  </div>
                  <div className="col-md-6 col-12 my-auto">
                  
                    <table className="table">
                      <thead className="thead-light">
                        <tr>
                          <th>Room Type</th>
                          <td className="labelHead">{post.RoomName} Room - {post.Price} / per day</td>
                        </tr>
                        <tr>
                          <th>Name</th>
                          <td> <input class="form-control" value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder="Enter the Name" /> </td>
                        </tr>
                        
                        <tr>
                          <th>Email</th>
                          <td> <input class="form-control" value={email} onChange={(e) => setEmail(e.target.value)} type="text" placeholder="Enter the Email" /></td>
                        </tr>
                        <tr>
                          <th>Phone Number</th>
                          <td> <input class="form-control" type="number" value={phonenumber} onChange={(e) => setPhonenumber(e.target.value)} placeholder="Enter the Phone Number" /></td>
                        </tr>
                        <tr>
                          <th>Message</th>
                          <td> <input class="form-control" type="text" value={location} onChange={(e) => setLocation(e.target.value)} placeholder="Enter the Location" /></td>
                        </tr>
                        <tr>
                          <th>Expectation Time</th>
                          <td> <input class="form-control" type="text" value={expect} onChange={(e) => setExpect(e.target.value)} placeholder="Enter the Expectation Time" /></td>
                        </tr>
                       
  
  
  
  
  
                      </thead>
                    </table>
                  </div>
                </div>
                <div className="row my-3 checkDate">
                  <div className="col-md-3 col-12 form-container fix">
                    <label htmlFor="Fromdate" className="font-weight-bolder labelHead">Check In </label>
                    <div className="">
  
                      <div class=" ">
  
                      <DatePicker selected={startDate} value={startDate} onChange={startTime}  minDate={moment().toDate()} />
                      </div>
  
                    </div>
                  </div>
                  <div className="col-md-3 col-12">
                    <label htmlFor="Todate" className="font-weight-bolder mr-3 labelHead">Check Out </label>
                    <div className="">
  
                      <div class="">
                        <DatePicker selected={endDate} value={endDate} onChange={endTime} minDate={moment().toDate()} />
  
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-12">
  
                    <div className="">
  
                      <div class="pt-1">
  
                        <button className="btn btn-white  search default-btn text-center" onClick={() => timeCalc(startDate, endDate)}>
                          Confirm The Date  {duration}
                        </button>
                        <h6 className="font-weight-bolder pb labelHead pt-2">Number of days : <span class="labelPrice">{duration}</span></h6>
                      </div>
                    </div>
                  </div>
  
                </div>
                
                <div className="row my-3">
                  <div className="col-md-6 col-12 form-container fix">
                  <label htmlFor="Fromdate" className="font-weight-bolder labelHead">Adults </label>
                  <div className="">
                    
                      <div key="">
                       <span className="roomName"></span>  <button className="btnAddColor" onClick={() => increaseQuantity()}>+</button><span className="addCount">{count}</span>
                        <button className="btnAddColor" onClick={() => decreaseQuantity()}>-</button>
                      </div>
                  
                  </div>
                  </div>
                  <div className="col-md-6 col-12">
                  <label htmlFor="Fromdate" className="font-weight-bolder labelHead">Children </label>
                  <div>
                  <div className=''>
                      <button className="btnAddColor" disabled={aboveChild} onClick={childChoose}>+</button> <span className="addCount"> {child} </span> <button disabled={belowChild} className="btnAddColor" onClick={childReduce} >-</button>
                  </div>
                  <div className='row'>
                      <div className='col-md-4 col-12'>
                          {isVisible ? (
                              <div class="pt-3 pb-3">
                                  <label htmlFor="Todate" className="font-weight-bolder mr-3 labelHead">Age of Selected First Child </label>
                                  <div className=''>
                                      <button className="btn btnAddColor" onClick={FirstChildAgePlus}>+</button> {firstage}  <button className="btn btnAddColor" onClick={FirstChildAgeReduce} >-</button>
                                  </div>
                              </div>
                          ) : null}
                      </div>
                      <div className='col-md-4 col-12'>{secondVisible ? (
                          <div class="pt-3 pb-3">
                              <label htmlFor="Todate" className="font-weight-bolder mr-3 labelHead">Age of Selected Second Child </label>
                              <div className=''>
                                  <button className="btn btnAddColor" onClick={SecondChildAgePlus}>+</button> {secondage}  <button className="btn btnAddColor" onClick={SecondChildAgeReduce} >-</button>
                              </div>
                          </div>
                      ) : null} </div>
                      <div className='col-md-4 col-12'>
                          {thirdVisible ? (
                              <div class="pt-3 pb-3">
                                  <label htmlFor="Todate" className="font-weight-bolder mr-3 labelHead">Age of Selected Third Child </label>
                                  <div className=''>
                                      <button className="btn btnAddColor" onClick={ThirdChildAgePlus}>+</button> {thirdage}  <button className="btn btnAddColor" onClick={ThirdChildAgeReduce} >-</button>
                                  </div>
                              </div>
                          ) : null}
                      </div>
                      <div className='col-md-4 col-12'>
                          {fourVisible ? (
                              <div class="pt-3 pb-3">
                                  <label htmlFor="Todate" className="font-weight-bolder mr-3 labelHead">Age of Selected Fourth Child </label>
                                  <div className=''>
                                      <button className="btn btnAddColor" onClick={FourChildAgePlus}>+</button> {fourage}  <button className="btn btnAddColor" onClick={FourChildAgeReduce} >-</button>
                                  </div>
                              </div>
                          ) : null}
                      </div>
                      <div className='col-md-4 col-12'>
      
                          {fiveVisible ? (
                              <div class="pt-3 pb-3">
                                  <label htmlFor="Todate" className="font-weight-bolder mr-3 labelHead">Age of Selected Fivth Child </label>
                                  <div className=''>
                                      <button className="btn btnAddColor" onClick={FiveChildAgePlus}>+</button> {fiveage}  <button className="btn btnAddColor" onClick={FiveChildAgeReduce} >-</button>
                                  </div>
                              </div>
                          ) : null}
                      </div>
                      <ToastContainer />
                  </div>
      
      
              </div>
                  </div>               
                </div>
               
                <div className="row my-3">
               
                 
                </div>
                <div className="row pt-3">
                <div className="col-md-6 col-12">
                <h6 class="labelHead">If You Need a BreakFast</h6>
                <div style={{display :"flex" , justifyContent : "space-evenly"}} >
                <div>
                
                <input type="radio" checked={!food} value={FoodYes} onChange={handleChange}  style={{height:"17px", width: "28px" , verticalAlign : "sub" }} />
                
                <label className="king">YES</label>
              </div>
                <div>
                
                <input type="radio" checked={food} value={FoodYes} onChange={handleChange}  style={{height:"17px", width: "28px" , verticalAlign : "sub" }} />
                
                <label className="king">NO</label>
                </div>
                
              </div>
                      </div>
                <div className="col-md-6 col-12">
                <h6 class="labelHead">Bed Preference</h6>
                
                <div style={{display :"flex" , justifyContent : "space-evenly"}} >
                <div>
                <input type="radio" checked={!bed} onChange={handleBedChange}  style={{height:"17px", width: "28px" , verticalAlign : "sub" }}  />
                <label className="king">KING</label> 
                </div>
                  
                
                <div>
                  <input type="radio" checked={bed} onChange={handleBedChange}  style={{height:"17px", width: "28px" , verticalAlign : "sub" }} />
                  
                  <label className="king">TWIN</label>
                  </div>
              </div>
           
                      </div>
                      
                </div>
                <div className="row">
             
                </div>
                
                <div className="row pt-5">
                  <div className="col-md-6 col-12 author-name">
                  <div class="">
                  <h6 class="labelHead">Description</h6>
                  <p className='pt-2 labelHead'>Please make sure Booking time is from 12 pm to 12 pm. </p>
                  <p className='pt-2 labelHead'>Early Check in to be 750rs will be applicable</p>
  
                </div>
                  </div>
                  <div className="col-md-6 col-12 author-name PriceShown">
                    <h6 className="font-weight-bold pt-3">Price  : <span className="labelPrice badge badge-info text-black"><BiRupee /> {durationPrice + due}</span></h6>
                    <h6 className="font-weight-bold ">Taxes - 12%: <span className="labelPrice badge badge-info text-black"><BiRupee /> {tax}</span></h6>
                    <h6 className="font-weight-bold ">CGST - 6%: <span className="labelPrice badge badge-info text-black"><BiRupee /> {cgst}</span></h6>
                    <h6 className="font-weight-bold ">SGST - 6%: <span className="labelPrice badge badge-info text-black"><BiRupee /> {sgst}</span></h6>
                    <h6 className="font-weight-bold ">DiscountPrice : <span className="labelPrice badge badge-info text-black"><BiRupee /> {amount}</span></h6>
                    <h6 className="font-weight-bold pt-3 ">Total Price to be paid : <span className="labelPrice badge badge-info text-black"><BiRupee /> {total}</span></h6>
                  
                    
                    </div>
                  
                </div>
                <div className="row my-4">
                  <div className="col-md-6 col-12">
                    <div className="form-group">
  
                    </div>
                  </div>
                 
                  <div className="col-md-6 col-12 my-auto">
                  
                    <div className="col-md-6 col-12 float-right">
                    <button className="btn btn-white  search default-btn text-center" onClick={handleSubmit} data-toggle="modal" data-target="#thanks">Confirm Booking</button>
                    </div>
                  </div>
                </div>
                {validationError && (
                  <div className="alert alert-danger" role="alert">
                    {validationError}
                  </div>
                )}
                {/* {data.length !== 0 && (
                  <div className="alert alert-success" role="alert">
                    Post Edited Successfully!
                  </div>
                )} */}
                {
                  error && <div className="alert alert-danger" role="alert">
                    {error}
                </div>
                }
              </div>
            </div>
            
            
          </div>
          <ToastContainer />
        </section>
        <footer className="footer-area pt-5 mt-5">
        
        <div className="footer-widget-area bg-dark">
          <div className="container">
            <div className="row mb-n60">
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-60">
                <div className="single-footer-widget">
                  <div className="footer-logo">
                    <Link to="#"><img src={logo} alt="Oestin" style={{ width: "14rem" }} /></Link>
                  </div>
                  <p>
                  Moskva Hotel is a 3-star property located 15 kilometers from Madurai Airport, 900 meters from Madurai Junction Railway Station, and one kilometer from Periyar Bus Stand.
                  </p>
                  <div className="social-icons">
                    <Link to="#"><i className="zmdi zmdi-facebook"></i></Link>
                    <Link to="#"><i className="zmdi zmdi-instagram"></i></Link>
                    <Link to="#"><i className="zmdi zmdi-rss"></i></Link>
                    <Link to="#"><i className="zmdi zmdi-twitter"></i></Link>
                    <Link to="#"><i className="zmdi zmdi-pinterest"></i></Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 offset-lg-1 col-lg-4  col-md-6 col-sm-6 col-12 mb-60">
                <div className="single-footer-widget">
                  <h3>contact us</h3>
                  <div className="c-info">
                    <span><i className="zmdi zmdi-pin"></i></span>
                    <span>57, Tamilsangam Road, Madurai,  <br />Tamil Nadu 625001</span>
                  </div>
                  <div className="c-info">
                    <span><i className="zmdi zmdi-email"></i></span>
                    <span>info@moskvahotel.in<br />info@moskvahotel.in</span>
                  </div>
                  <div className="c-info">
                    <span><i className="zmdi zmdi-phone"></i></span>
                    <span>+91-8489914838<br />+91-452 4219063</span>
                  </div>
                  <div style={{display:"flex"}}>
                <div className="footer-logo">
                    <a href="https://play.google.com/store/games?hl=en&gl=US&pli=1"><img src="assets/img/moskva-photos/small/plays.png" alt="Oestin" style={{ width: "10rem" }} /></a>
                  </div>
                  <div className="footer-logo" style={{paddingLeft:"5px"}}>
                    <a href="https://www.apple.com/in/app-store/"><img src="assets/img/moskva-photos/small/play.png" alt="Oestin" style={{ width: "10rem" }} /></a>
                  </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12 mb-60">
                <div className="single-footer-widget">
                  <h3>quick links</h3>
                  <ul className="footer-list">
                  <li><Link to="/">Home</Link></li>
                  <li><Link to="/rooms">Rooms</Link></li>
                  <li><Link to="/benquetmenu">Banquets Menu</Link></li>
                  <li><Link to="/about">About</Link></li>
                  <li><Link to="/resturants">Resturants</Link></li>
                  <li><Link to="/bars">Bar</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-60">
                <div className="single-footer-widget">
                  <h3>collections</h3>
                  <div className="instagram-image">
                    <div className="footer-img">
                      <Link to="#"><img src="assets/img/moskva-photos/small/DSC_0962.jpg" alt=""/></Link>
                    </div>
                    <div className="footer-img">
                      <Link to="#"><img src="assets/img/moskva-photos/small/DSC_0980.jpg" alt=""/></Link>
                    </div>
                    <div className="footer-img">
                      <Link to="#"><img src="assets/img/moskva-photos/small/DSC_1004.jpg" alt="" /></Link>
                    </div>
                    <div className="footer-img">
                      <Link to="#" ><img src="assets/img/moskva-photos/small/DSC_1063.jpg" alt="" /></Link>
                    </div>
                    <div className="footer-img">
                      <Link to="#"><img src="assets/img/moskva-photos/small/DSC_1071(1).jpg" alt="" /></Link>
                    </div>
                    <div className="footer-img">
                      <Link to="#" ><img src="assets/img/moskva-photos/small/DSC_1092.jpg" alt="" /></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='text-center'> 
        <p className="designFont">© 2023 MOSKVA All rights reserved. Designed By <a className="designFont" href="https://jofinity.com/">JOFINITY</a></p>
        </div>
        </div>
        
        
       
      </footer>
      </div>
  
    )
  }
  
  export default Deluxebookdetail