import React from 'react';
import logo from './hotel_logo.png';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import Header from './Header.js';

const Resturants = () => {
  return (
    <div>
    <Header />

      <section className="breadcrumb-area overlay-dark-2 bg-3">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-text text-center">
                <h2>MOSKVA RESTURANTS</h2>
                <p>A quality food of Moskva </p>
                <div className="breadcrumb-bar">
                  <ul className="breadcrumb">
                    <li><Link to="index.html">Home</Link></li>
                    <li>Moskva Resturats</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>





      <section class="team-area pt-90 team-section">
        <div class="container">
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div className="section-title text-center">
                <h3>NON - VEGETARIAN</h3>
                <p className="pb-10">Arbatskaya Restaurant, the best homemade food online service in Madurai. Especially, we provide the dishes that are healthy, nutrition and organic. We prepare a variety of fresh foods with the taste of our customers in mind. Arbatskaya Restaurant lets them delve in their passion for cooking and delivering tasty, yummy and healthy foods.  </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-sm-6">
              <div class="single-team">
                <div class="team-image"><img src="assets/img/moskva-photos/gallery/muttonGravy.jpg" alt="" style={{ height: "397px", width: "379px" }} /></div>
                <div class="team-hover">
                  <h4>Mutton Gravy</h4>
                  <span class="block">( Mutton Gravy )</span>
                  <div className="address-distance fix">
                    <ul class="list-group resturantList text-center">
                      <li class="list-group-item ">MUTTON ROGAN JOSH</li>
                      <li class="list-group-item ">MUTTON MILAGU CURRY</li>
                      <li class="list-group-item">MUTTON KURMA</li>
                      <li class="list-group-item ">HOMESTYLE MUTTON CURRY (WITH POTATO)</li>

                    </ul>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="single-team">
                <div class="team-image"><img src="assets/img/moskva-photos/gallery/specialmottongravy.jpg" alt="" style={{ height: "397px", width: "379px" }} /></div>
                <div class="team-hover">
                  <h4>Mutton Gravy Special</h4>
                  <span class="block">( Mutton Gravy Special )</span>
                  <div className="address-distance fix">
                    <ul class="list-group resturantList text-center">
                      <li class="list-group-item ">MUTTON BRIYANI</li>
                      <li class="list-group-item ">MUTTON NILGIRI KURMA</li>
                      <li class="list-group-item">MUTTON CHETTINADU</li>
                      <li class="list-group-item">VIRUDHUNAGAR MUTTON CURRY</li>
                      <li class="list-group-item">KHEEMA MUTTER CURRY</li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="single-team">
                <div class="team-image"><img src="assets/img/moskva-photos/gallery/muttondry.jpg" alt="" style={{ height: "397px", width: "379px" }} /></div>
                <div class="team-hover">
                  <h4>Mutton Dry</h4>
                  <span class="block">( Mutton Dry )</span>
                  <div className="address-distance fix">
                    <ul class="list-group resturantList text-center">
                      <li class="list-group-item ">MUTTON CHUKKA</li>
                      <li class="list-group-item ">MUTTON PEPPER FRY</li>
                      <li class="list-group-item">MUTTON COCONUT FRY</li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>

            <div class="col-lg-4 col-sm-6">
              <div class="single-team">
                <div class="team-image"><img src="assets/img/moskva-photos/gallery/Chicken Gravy Recipe.jpg" alt="" style={{ height: "397px", width: "379px" }} /></div>
                <div class="team-hover">
                  <h4>Chicken Gravy</h4>
                  <span class="block">( Chicken Gravy )</span>
                  <div className="address-distance fix">
                    <ul class="list-group resturantList text-center">
                      <li class="list-group-item ">ANDHRA CHICKEN CURRY</li>
                      <li class="list-group-item ">KOZHI VARUTHA CURRY</li>
                      <li class="list-group-item">CHICKEN CHETTINADU CURRY</li>
                      <li class="list-group-item">BUTTER CHICKEN MASALA</li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="single-team">
                <div class="team-image"><img src="assets/img/moskva-photos/gallery/specialchickengravy.jpg" alt="" style={{ height: "397px", width: "379px" }} /></div>
                <div class="team-hover">
                  <h4>Chicken Gravy Special</h4>
                  <span class="block">( Chicken Gravy Special )</span>
                  <div className="address-distance fix">
                    <ul class="list-group resturantList text-center">
                      <li class="list-group-item ">KOZHI MILAGU CURRY</li>
                      <li class="list-group-item ">CHICHEN KURMA</li>
                      <li class="list-group-item">MURGH AFGANI/MUGHLAI</li>
                      <li class="list-group-item">CHICKEN BRIYANI/RAITHA</li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="single-team">
                <div class="team-image"><img src="assets/img/moskva-photos/gallery/chickendry.jpg" alt="" style={{ height: "397px", width: "379px" }} /></div>
                <div class="team-hover">
                  <h4>Chicken Dry</h4>
                  <span class="block">( Chicken Dry )</span>
                  <div className="address-distance fix">
                    <ul class="list-group resturantList text-center">
                      <li class="list-group-item ">KARAIKUDI KOZHI FRY</li>
                      <li class="list-group-item ">CHICKEN-65</li>
                      <li class="list-group-item">CHILLI CHICKEN</li>
                      <li class="list-group-item ">SECZWAN CHICKEN</li>
                      <li class="list-group-item ">CHICKEN MANCHURIAN</li>
                      <li class="list-group-item">CHICKEN PEPPER FRY</li>
                      <li class="list-group-item">KADAAI CHICKEN</li>
                      <li class="list-group-item">CONTI ROAST CHICKEN</li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="single-team">
                <div class="team-image"><img src="assets/img/moskva-photos/gallery/fishgravy.jpg" alt="" style={{ height: "397px", width: "379px" }} /></div>
                <div class="team-hover">
                  <h4>Fish Gravy</h4>
                  <span class="block">( Fish Gravy )</span>
                  <div className="address-distance fix">
                    <ul class="list-group resturantList text-center">
                      <li class="list-group-item ">MADURAI MEEN KOLAMBU</li>
                      <li class="list-group-item ">FISH MOILEE</li>
                      <li class="list-group-item">MALABAR FISH CURRY</li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">

            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="single-team">
                <div class="team-image"><img src="assets/img/moskva-photos/gallery/fish dry.png" alt="" style={{ height: "397px", width: "379px" }} /></div>
                <div class="team-hover">
                  <h4>Fish Dry</h4>
                  <span class="block">( Fish Dry )</span>
                  <div className="address-distance fix">
                    <ul class="list-group resturantList text-center">
                      <li class="list-group-item ">MASALA FRIED FISH</li>
                      <li class="list-group-item ">MEEN ROAST (SHALLOW FRY)</li>
                      <li class="list-group-item">CHILLI FISH / FISH AMRITSARI</li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>


          </div>
        </div>
      </section>

      <section className="newsletter-area bg-light">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 mx-auto col-12">
              <div className="newsletter-container">
                <h3>NewsLetter Sign-Up</h3>
                <div className="newsletter-form">
                  <form action="#" id="mc-form" className="mc-form fix">
                    <input id="mc-email" type="email" name="email" placeholder="Enter your E-mail" />
                    <button id="mc-submit" type="submit" className="default-btn">subcribes</button>
                  </form>

                  <div className="mailchimp-alerts">
                    <div className="mailchimp-submitting"></div>

                    <div className="mailchimp-success"></div>

                    <div className="mailchimp-error"></div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="footer-area">

        <div className="footer-widget-area bg-dark">
          <div className="container">
            <div className="row mb-n60">
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-60">
                <div className="single-footer-widget">
                  <div className="footer-logo">
                    <Link to="#"><img src={logo} alt="Oestin" style={{ width: "14rem" }} /></Link>
                  </div>
                  <p>
                  Moskva Hotel is a 3-star property located 15 kilometers from Madurai Airport, 900 meters from Madurai Junction Railway Station, and one kilometer from Periyar Bus Stand.
                  </p>
                  <div className="social-icons">
                    <Link to="#"><i className="zmdi zmdi-facebook"></i></Link>
                    <Link to="#"><i className="zmdi zmdi-instagram"></i></Link>
                    <Link to="#"><i className="zmdi zmdi-rss"></i></Link>
                    <Link to="#"><i className="zmdi zmdi-twitter"></i></Link>
                    <Link to="#"><i className="zmdi zmdi-pinterest"></i></Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 offset-lg-1 col-lg-4  col-md-6 col-sm-6 col-12 mb-60">
                <div className="single-footer-widget">
                  <h3>contact us</h3>
                  <div className="c-info">
                    <span><i className="zmdi zmdi-pin"></i></span>
                    <span>57, Tamilsangam Road, Madurai,  <br />Tamil Nadu 625001</span>
                  </div>
                  <div className="c-info">
                    <span><i className="zmdi zmdi-email"></i></span>
                    <span>info@moskvahotel.in<br />info@moskvahotel.in</span>
                  </div>
                  <div className="c-info">
                    <span><i className="zmdi zmdi-phone"></i></span>
                    <span>+91-8489914838<br />+91-452 4219063</span>
                  </div>
                  <div style={{display:"flex"}}>
                  <div className="footer-logo">
                      <a href="https://play.google.com/store/games?hl=en&gl=US&pli=1"><img src="assets/img/moskva-photos/small/plays.png" alt="Oestin" style={{ width: "10rem" }} /></a>
                    </div>
                    <div className="footer-logo" style={{paddingLeft:"5px"}}>
                      <a href="https://www.apple.com/in/app-store/"><img src="assets/img/moskva-photos/small/play.png" alt="Oestin" style={{ width: "10rem" }} /></a>
                    </div>
                    </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12 mb-60">
                <div className="single-footer-widget">
                  <h3>quick links</h3>
                  <ul className="footer-list">
                  <li><Link to="/">Home</Link></li>
                    <li><Link to="/rooms">Rooms</Link></li>
                    <li><Link to="/benquetmenu">Banquets Menu</Link></li>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/resturants">Resturants</Link></li>
                    <li><Link to="/bars">Bar</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-60">
                <div className="single-footer-widget">
                  <h3>collections</h3>
                  <div className="instagram-image">
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC0962.jpg" alt=""/></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC0980.jpg" alt=""/></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC1004.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#" ><img src="assets/img/moskva-photos/small/DSC1063.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC1071.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#" ><img src="assets/img/moskva-photos/small/DSC1092.jpg" alt="" /></Link>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div className='text-center'> 
          <p className="designFont">© 2023 MOSKVA All rights reserved. Designed By <a className="designFont" href="https://jofinity.com/">JOFINITY</a></p>
          </div>
        </div>



      </footer>
    </div>
  )
}

export default Resturants