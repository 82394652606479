import React,{useState} from "react";
import Appsubmitbutton from "../../components/appsubmitbutton/Appsubmitbutton";
import "./Signup.css";
import { useAuthentication } from './../../hooks/useAuthentication';
import logo from "../../components/hotel_logo.png";
import Navbar from '../../components/Navbar';
import { Link } from 'react-router-dom';
import Header from "../../components/Header";

export default function Signup() {

   const [email,setEmail] = useState('')
   const [password,setPassword] = useState('')
   const [lastName,setLastName] = useState('')
   const [firstName,setFirstName] = useState('')

   const {signup,error} = useAuthentication()

   const [validationError,setValidationError] = useState(null)
   const handleSubmit = (e) => {
        e.preventDefault()

        if (!email) {
            setValidationError('Email cannot be empty')
            return
        }
        else if (!password) {
            setValidationError('Password cannot be empty')
            return
        }
        else if (!firstName) {
            setValidationError('First Name cannot be empty')
            return
        }
        else if (!lastName) {
            setValidationError('Last Name cannot be empty')
            return
        }
        setValidationError(null)

        console.log({email,password,lastName,firstName})
        signup({email,password,lastName,firstName})
   }

  return (
    <div> 
    <Header />
  <section className="breadcrumb-area overlay-dark-2 bg-3">
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="breadcrumb-text text-center">
          <h2>MOSKVA Register</h2>
          <p>Please Register the Moskva </p>
          <div className="breadcrumb-bar">
            <ul className="breadcrumb">
              <li><Link to="index.html">Home</Link></li>
              <li>Moskva Register</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    <div className="formcontainer pb-5 pt-5 mt-5 mb-5 signup">
   
    <h2 className="titleSpacing">Signup</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Email address
          </label>
          <input
            type="email"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            value={email} onChange={(e) => setEmail(e.target.value)}
          />
          <div id="emailHelp" className="form-text">
            We'll never share your email with anyone else.
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">
            Password
          </label>
          <input
            type="password"
            className="form-control"
            id="exampleInputPassword1"
            value={password} onChange={(e) => setPassword(e.target.value)}

          />
        </div>
        <div className="mb-3">
          <label htmlFor="firstname" className="form-label">
            First Name
          </label>
          <input
            type="text"
            className="form-control"
            id="firstname"
            value={firstName} onChange={(e) => setFirstName(e.target.value)}

          />
        </div>
        <div className="mb-3">
          <label htmlFor="lastname" className="form-label">
            Last Name
          </label>
          <input
            type="text"
            className="form-control"
            id="lastname"
            value={lastName} onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        {
            validationError && <div className="alert alert-danger" role="alert">
                {validationError}
            </div>
        }
        {
            error && <div className="alert alert-danger" role="alert">
                {error}
            </div>
        }
        <div className="float-end">
          <Appsubmitbutton title="Signup" />
        </div>
        <div id="emailHelp" className="form-text">
           <label className="form-label"> Already have a account : </label>  <Link to="/login" className="newusersign">Login</Link>
          </div>
      </form>
    </div>
    <footer className="footer-area">

        <div className="footer-widget-area bg-dark">
          <div className="container">
            <div className="row mb-n60">
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-60">
                <div className="single-footer-widget">
                  <div className="footer-logo">
                    <Link to="#"><img src={logo} alt="Oestin" style={{ width: "14rem" }} /></Link>
                  </div>
                  <p>
                  Moskva Hotel is a 3-star property located 15 kilometers from Madurai Airport, 900 meters from Madurai Junction Railway Station, and one kilometer from Periyar Bus Stand. 
                  </p>
                  <div className="social-icons">
                    <Link to="#"><i className="zmdi zmdi-facebook"></i></Link>
                    <Link to="#"><i className="zmdi zmdi-instagram"></i></Link>
                    <Link to="#"><i className="zmdi zmdi-rss"></i></Link>
                    <Link to="#"><i className="zmdi zmdi-twitter"></i></Link>
                    <Link to="#"><i className="zmdi zmdi-pinterest"></i></Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 offset-lg-1 col-lg-4  col-md-6 col-sm-6 col-12 mb-60">
                <div className="single-footer-widget">
                  <h3>contact us</h3>
                  <div className="c-info">
                    <span><i className="zmdi zmdi-pin"></i></span>
                    <span>57, Tamilsangam Road, Madurai,  <br />Tamil Nadu 625001</span>
                  </div>
                  <div className="c-info">
                    <span><i className="zmdi zmdi-email"></i></span>
                    <span>info@moskvahotel.in<br />info@moskvahotel.in</span>
                  </div>
                  <div className="c-info">
                    <span><i className="zmdi zmdi-phone"></i></span>
                    <span>+91-8489914838<br />+91-452 4219063</span>
                  </div>
                  <div style={{display:"flex"}}>
                <div className="footer-logo">
                    <a href="https://play.google.com/store/games?hl=en&gl=US&pli=1"><img src="assets/img/moskva-photos/small/plays.png" alt="Oestin" style={{ width: "10rem" }} /></a>
                  </div>
                  <div className="footer-logo" style={{paddingLeft:"5px"}}>
                    <a href="https://www.apple.com/in/app-store/"><img src="assets/img/moskva-photos/small/play.png" alt="Oestin" style={{ width: "10rem" }} /></a>
                  </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12 mb-60">
                <div className="single-footer-widget">
                  <h3>quick links</h3>
                  <ul className="footer-list">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/rooms">Rooms</Link></li>
                    <li><Link to="/benquetmenu">Banquets Menu</Link></li>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/resturants">Resturants</Link></li>
                    <li><Link to="/bars">Bar</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-60">
                <div className="single-footer-widget">
                  <h3>collections</h3>
                  <div className="instagram-image">
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC0962.jpg" alt=""/></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC0980.jpg" alt=""/></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC1004.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#" ><img src="assets/img/moskva-photos/small/DSC1063.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC1071(1).jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#" ><img src="assets/img/moskva-photos/small/DSC1092.jpg" alt="" /></Link>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div className='text-center'> 
        <p className="designFont">© 2023 MOSKVA All rights reserved. Designed By JOFINITY</p>
        </div>
        </div>



      </footer>
    </div>
  );
}
