import React,{useState} from 'react'

const Paymentrazor = () => {
    const [amount, setamount] = useState('');
    const handleSubmit = (e)=>{
        e.preventDefault();
        if(amount === ""){
            alert("Please Enter the Amount");
        }
        else{
           // alert(amount);
           var options = {
        key: "rzp_test_M8hQFPqwmsAEwr",
        key_secret:"Oxea2tQe7M071SZ8JU9FOf4s",
        amount: amount *100,
        currency:"INR",
        name:"STARTUP_PROJECTS",
        description:"for testing purpose",
        handler: function(response){
          alert(response.razorpay_payment_id);
        },
        prefill: {
          name:"Velmurugan",
          email:"mvel1620r@gmail.com",
          contact:"7904425033"
        },
        notes:{
          address:"Razorpay Corporate office"
        },
        theme: {
          color:"#3399cc"
        }
    }
        var pay = new window.Razorpay(options);
      pay.open();
        }
    }

  return (
    <div> <div >
     <h2>Razorpay Payment Integration Using React</h2>
     <br/>
     <input type="text" placeholder='Enter Amount' value={amount} onChange={(e)=>setamount(e.target.value)} />
     <br/><br/>
     <button onClick={handleSubmit}>submit</button>
    </div></div>
  )
}

export default Paymentrazor