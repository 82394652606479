import React, { useState, useEffect } from "react";
//import "./Editpost.css";
import { useLocation, useNavigate } from "react-router-dom";
//import { useFetch } from "./../../hooks/useFetch";
import Appsubmitbutton from "../../components/appsubmitbutton/Appsubmitbutton";
import { useFirestore } from './../../hooks/useFirestore';
import logo from "../../components/hotel_logo.png";
import Navbar from '../../components/Navbar';
import { Link } from 'react-router-dom';

export default function Editpost() {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [money, setMoney] = useState("");
  const [breaks, setBreaks] = useState("");
  const [disc, setDisc] = useState("");
  const [validationError, setValidationError] = useState("");
  const [modifiedField,setModifiedField] = useState({})

  const navigate = useNavigate();

  const location = useLocation();

  const { state: post } = location;

  const {updateDocument,error} = useFirestore('posts')

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!title) {
      setValidationError("Price should not be empty");
      return;
    }
    if (!content) {
      setValidationError("Double Price should not be empty");
      return;
    }
    if (!money) {
      setValidationError("Third Price should not be empty");
      return;
    }
    if (!breaks) {
      setValidationError("Food Price should not be empty");
      return;
    }
    if (!disc) {
      setValidationError("Discount Price should not be empty");
      return;
    }
    setValidationError("");
    console.log(modifiedField);
    updateDocument(post.id,modifiedField)
    navigate('/adminpanels')
  };

  useEffect(() => {
    setTitle(post.Price)
    setContent(post.Double)
    setMoney(post.Third)
    setBreaks(post.Food)
    setDisc(post.Discount)
    
  }, [post.Price,post.Double,post.Third,post.Food,post.Discount]);

//console.log(post.RoomName);
  const onTitleChange = (e) => {
    setTitle(e.target.value)
    setModifiedField({...modifiedField,Price:e.target.value})
  }

  const onContentChange = (e) => {
    setContent(e.target.value)
    setModifiedField({...modifiedField,Double:e.target.value})

  }

  const onMoneyChange = (e) => {
    setMoney(e.target.value)
    setModifiedField({...modifiedField,Third:e.target.value})

  }

  const onBreaksChange = (e) => {
    setBreaks(e.target.value)
    setModifiedField({...modifiedField,Food:e.target.value})

  }

  const onDiscChange = (e) => {
    setDisc(e.target.value)
    setModifiedField({...modifiedField,Discount:e.target.value})

  }

  return (
    <div >
    <header className="header-area fixed header-sticky" >
    <div className="container">
      <div className="row">
        <div className="col-xl-5 col-lg-4 col-sm-4 col-12">
          <div className="logo">
            <Link to="#"><img src={logo} alt="Oestin" style={{ width: "21rem" }} /></Link>
          </div>
        </div>
        <div className="col-xl-7 col-lg-8 col-sm-8 col-12">
          <div className="header-top fix">
            <div className="header-contact">
              <span className="text-theme">Contact:</span>
              <span>0123456789</span>
            </div>
            <div className="header-links">
              <Link to="https://www.facebook.com/"><i className="zmdi zmdi-facebook"></i></Link>
              <Link to="https://twitter.com/"><i className="zmdi zmdi-twitter"></i></Link>
              <Link to="https://plus.google.com/"><i className="zmdi zmdi-google-plus"></i></Link>
              <Link to="https://www.instagram.com/"><i className="zmdi zmdi-instagram"></i></Link>
              <Link to="https://www.pinterest.com/"><i className="zmdi zmdi-pinterest"></i></Link>
            </div>
          </div>

          <div className="main-menu d-none d-lg-block">
            <Navbar />
          </div>

        </div>
      </div>
    </div>

    <div className="mobile-menu-area">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="mobile-menu">
              <nav id="dropdown">
                <Navbar />
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>

  </header>
  <section class="breadcrumb-area overlay-dark-2 bg-3">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb-text text-center">
                    <h2>Price Change</h2>
                    
                    <div class="breadcrumb-bar">
                        <ul class="breadcrumb">
                            <li><Link to="/">Home</Link></li>
                            <li>Room Price Changes</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<h1 className="text-center pt-3">{post.RoomName}</h1>
    <div className="container  pt-3">
   
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>
            <h6>Price</h6>
          </label>
          <input
            type="text"
            className="form-control"
            value={title}
            onChange={onTitleChange}
          />
        </div>
        <div className="form-group">
          <label>
            <h6>Double Price</h6>
          </label>
          <input
          type="text"
            className="form-control"
            value={content}
            onChange={onContentChange }
          />
        </div>
        <div className="form-group">
          <label>
            <h6>Third Price</h6>
          </label>
          <input
            type="text"
            className="form-control"
            value={money}
            onChange={onMoneyChange}
          />
        </div>
        <div className="form-group">
          <label>
            <h6>Food Price</h6>
          </label>
          <input
            type="text"
            className="form-control"
            value={breaks}
            onChange={onBreaksChange}
          />
        </div>
        <div className="form-group">
          <label>
            <h6>Discount Price</h6>
          </label>
          <input
            type="text"
            className="form-control"
            value={disc}
            onChange={onDiscChange}
          />
        </div>
        {validationError && (
          <div className="alert alert-danger" role="alert">
            {validationError}
          </div>
        )}
        {/* {data.length !== 0 && (
          <div className="alert alert-success" role="alert">
            Post Edited Successfully!
          </div>
        )} */}
        {
          error && <div className="alert alert-danger" role="alert">
            {error}
        </div>
        }
        
        <div className="float-end pt-3">
          <Appsubmitbutton title="Update"/>
        </div>
      </form>
    </div>
    </div>
  );
}
