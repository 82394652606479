import React from 'react';
import logo from './hotel_logo.png';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import Header from './Header.js';

const About = () => {
  return (
    <div>
    <Header />
      <section className="breadcrumb-area overlay-dark-2 bg-3">	
      <div className="container">
          <div className="row">
              <div className="col-12">
                  <div className="breadcrumb-text text-center">
                      <h2>Moskva - About</h2>
                      <p>About the details of Moskva.</p>
                      <div className="breadcrumb-bar">
                          <ul className="breadcrumb">
                              <li><Link to="index.html">Home</Link></li>
                              <li>Moskva - About</li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
  <section className="room-area pt-90">
            <div className="container">
                <div className="row">
                    <div className=" mx-auto">
                        <div className="section-title ">
                            <h3 className="text-center">About</h3>
                            <p>On the banks of River Vagai, Madurai, an ancient city of India in the state of Tamil Nadu with rich culture and traditions and high religious importance, is a place to visit. Boasting of some of the finest examples of architecture, a wide array of sightseeing places, surrounded by scenic beauty and spiritual environment, a tour to Madurai city presents an enchanting travel experience to every visitor. Once in Madurai there are many places to see. Here are a few places to visit in Madurai. </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="room-list">
                            <div className="row">
                                <div className="col-lg-5 col-md-6">
                                    <Link to="#"><img src="https://static.wixstatic.com/media/75e127_c3c2ec4f6fe9448cb73c6be15f01f2c4~mv2.jpg/v1/fill/w_394,h_197,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/sri-meenakshi.jpg" alt="" style={{ height : "242px" }} /></Link>
                                </div>
                                <div className="col-lg-7 col-md-6 align-self-center">
                                    <div className="room-list-text">
                                        <h3><Link to="#">Meenakshi Amman Temple</Link></h3>
                                        <p>
                                        Also known as Meenakshi Sundareswarar temple, this temple is one of the most prominent shrines in India. Being one of the oldest temples of India, it is not only religiously significant but also presents an excellent example of Dravidian architecture. It is devoted to Lord Sundareswarar and his divine consort Goddess Meenakshi. Built during the Pandyan reign, this temple has seen various contributions from rulers of other dynasties as well. Its intricate carvings and majestic structures mesmerises everyone while its spiritual aura and associated legends enthral every devotee who come here to offer prayers. </p>
                                        <div className="address-distance fix">
                                    <span>Distance : 2km from moskva</span>
                                    <span></span>
                                </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="room-list">
                            <div className="row">
                                <div className="col-lg-5 col-md-6">
                                    <Link to="#"><img src="https://static.wixstatic.com/media/75e127_a4e3fde8c7094f4e87893fca57089d71~mv2.jpg/v1/fill/w_394,h_197,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/thirumalai.jpg" alt="" style={{ height : "242px" }} /></Link>
                                </div>
                                <div className="col-lg-7 col-md-6 align-self-center">
                                    <div className="room-list-text">
                                        <h3><Link to="#">Thirumalai Nayak Palace</Link></h3>
                                        <p>Built in 1635 during the reign of King Thirumalai Nayak as his residence, this palace presents a beautiful amalgamation of Dravidian and Islamic architectural styles. Built by an Italian architect, this palace is worth exploring, especially by those who like history and architecture. It has been later renovated in 1866-1872. Sound and light show which is conducted every evening at this palace is worth watching. after being restored in various phases, it is under the maintenance of Tamil Nadu Archaeological Department. </p>
                                        <div className="address-distance fix">
                                    <span>Distance : 2km from moskva</span>
                                    <span></span>
                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="room-list">
                            <div className="row">
                                <div className="col-lg-5 col-md-6">
                                    <Link to="#"><img src="https://static.wixstatic.com/media/75e127_a14b660a4e6e4012b544a891d60f8791~mv2.jpg/v1/fill/w_394,h_197,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/samanar.jpg" alt=""  style={{ height : "242px" }} /></Link>
                                </div>
                                <div className="col-lg-7 col-md-6 align-self-center">
                                    <div className="room-list-text">
                                        <h3><Link to="#">Samanar Hills / Samanar Malai</Link></h3>
                                        <p>At a distance of 12 km from Madurai, Samanar Hills is located in Keelakuyilkudi village. It has been declared as protected monument by the Archaeological Survey of India. This hill has rich historical and religious associations dating back to 1st century A.D. Some of the inscriptions on the rocks here depict presence Jain monks here, who are said to have stayed here in the caves. Along with religious association, Samanar hills are also popular for its mesmerising scenic. </p>
                                        <div className="address-distance fix">
                                        <span>Distance : 2km from moskva</span>
                                        <span></span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="room-list">
                            <div className="row">
                                <div className="col-lg-5 col-md-6">
                                    <Link to="#"><img src="https://static.wixstatic.com/media/75e127_c44c40ad15714adabe30af45ecab4d1a~mv2.jpg/v1/fill/w_394,h_197,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/azhagar.jpg" alt="" style={{ height : "242px" }} /></Link>
                                </div>
                                <div className="col-lg-7 col-md-6 align-self-center">
                                    <div className="room-list-text">
                                        <h3><Link to="#">Azhagar Kovil / Alagar koyil Temple</Link></h3>
                                        <p>Located 21 km from Madurai city at the foothills of Alagar Hills, Alagar Koyil Temple is another masterpiece to be visited on a tour to Madurai. Dedicated to Lord Vishnu, this captivating temple has historical as well as religious high significance. Dating back to Sangam Period, it is said to be often visited by Pandyan king Malayadwaja Pandyan. Some of the additions to this temple were done during reign of Nayak Kings. </p>
                                        <div className="address-distance fix">
                                    <span>Distance : 2km from moskva</span>
                                    <span></span>
                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="room-list">
                            <div className="row">
                                <div className="col-lg-5 col-md-6">
                                    <Link to="#"><img src="https://static.wixstatic.com/media/75e127_28acf71ac8d54221946ba1f598bb7c61~mv2.jpg/v1/fill/w_394,h_197,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/thiruparankundram.jpg" alt="" style={{ height : "242px" }} /></Link>
                                </div>
                                <div className="col-lg-7 col-md-6 align-self-center">
                                    <div className="room-list-text">
                                        <h3><Link to="#">Thiruparankundram Murugan Temple</Link></h3>
                                        <p>This temple is an arupadaiveedu, which means one of the six important temple dedicated Lord Murugan. Carved out of a mountain this temple presents alluring beauty. It is said to be built during Pandyan reign in the 8th century. There are also separate shrines for Lord Shiva, Lord Vinayaka, Lord Vishnu, goddess Durga and many other deities. While visiting this temple, devotees can also visit theertham such as Sanyasi Kinaru, Kasi Sunai, Sathiya Koopam, Saravana Poigai and Lakshmi Theertham. </p>
                                        <div className="address-distance fix">
                                    <span>Distance : 2km from moskva</span>
                                    <span></span>
                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="room-list">
                            <div className="row">
                                <div className="col-lg-5 col-md-6">
                                    <Link to="#"><img src="https://static.wixstatic.com/media/75e127_97e7b79805a349668e0c726ab30254c6~mv2.jpg/v1/fill/w_391,h_197,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/gandhi.jpg" alt="" style={{ height : "242px" }} /></Link>
                                </div>
                                <div className="col-lg-7 col-md-6 align-self-center">
                                    <div className="room-list-text">
                                        <h3><Link to="#">Gandhi Memorial Museum</Link></h3>
                                        <p>This museum was originally a palace of Rani Mangammal from Naick dynasty which was built in 1670 A.D. This majestic palace was given to All India Gandhi Smarak Nidhi by the Government of Tamil Nadu for the purpose of building Gandhi Memorial Museum. Spread over 13 acres of land, this museum is consisted of many souvenirs which present a glimpse into the life of Mahatma Gandhi and various historical significant events. A wide range of activities are also conducted here on regular basis. </p>
                                        <div className="address-distance fix">
                                    <span>Distance : 2km from moskva</span>
                                    <span></span>
                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="room-list">
                            <div className="row">
                                <div className="col-lg-5 col-md-6">
                                    <Link to="#"><img src="assets/img/IMAGERES/MADURAIAIRPORT.jpg" alt="" style={{ height : "242px" }} /></Link>
                                </div>
                                <div className="col-lg-7 col-md-6 align-self-center">
                                    <div className="room-list-text">
                                        <h3><Link to="#">Airport</Link></h3>
                                        <p>This museum was originally a palace of Rani Mangammal from Naick dynasty which was built in 1670 A.D. This majestic palace was given to All India Gandhi Smarak Nidhi by the Government of Tamil Nadu for the purpose of building Gandhi Memorial Museum. Spread over 13 acres of land, this museum is consisted of many souvenirs which present a glimpse into the life of Mahatma Gandhi and various historical significant events. A wide range of activities are also conducted here on regular basis. </p>
                                        <div className="address-distance fix">
                                    <span>Distance : 2km from moskva</span>
                                    <span></span>
                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="room-list">
                            <div className="row">
                                <div className="col-lg-5 col-md-6">
                                    <Link to="#"><img src="assets/img/IMAGERES/Periyar.jpg" alt="" style={{ height : "242px" }} /></Link>
                                </div>
                                <div className="col-lg-7 col-md-6 align-self-center">
                                    <div className="room-list-text">
                                        <h3><Link to="#">Periyar Junction</Link></h3>
                                        <p>This museum was originally a palace of Rani Mangammal from Naick dynasty which was built in 1670 A.D. This majestic palace was given to All India Gandhi Smarak Nidhi by the Government of Tamil Nadu for the purpose of building Gandhi Memorial Museum. Spread over 13 acres of land, this museum is consisted of many souvenirs which present a glimpse into the life of Mahatma Gandhi and various historical significant events. A wide range of activities are also conducted here on regular basis. </p>
                                        <div className="address-distance fix">
                                    <span>Distance : 2km from moskva</span>
                                    <span></span>
                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="room-list">
                            <div className="row">
                                <div className="col-lg-5 col-md-6">
                                    <Link to="#"><img src="assets/img/IMAGERES/mgr.jpg" alt="" style={{ height : "242px" }} /></Link>
                                </div>
                                <div className="col-lg-7 col-md-6 align-self-center">
                                    <div className="room-list-text">
                                        <h3><Link to="#">MGR Busstand</Link></h3>
                                        <p>This museum was originally a palace of Rani Mangammal from Naick dynasty which was built in 1670 A.D. This majestic palace was given to All India Gandhi Smarak Nidhi by the Government of Tamil Nadu for the purpose of building Gandhi Memorial Museum. Spread over 13 acres of land, this museum is consisted of many souvenirs which present a glimpse into the life of Mahatma Gandhi and various historical significant events. A wide range of activities are also conducted here on regular basis. </p>
                                        <div className="address-distance fix">
                                    <span>Distance : 2km from moskva</span>
                                    <span></span>
                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>


       

       




      <footer className="footer-area">
      
      <div className="footer-widget-area bg-dark">
        <div className="container">
          <div className="row mb-n60">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <div className="footer-logo">
                  <Link to="#"><img src={logo} alt="Oestin" style={{ width: "14rem" }} /></Link>
                </div>
                <p>
                Moskva Hotel is a 3-star property located 15 kilometers from Madurai Airport, 900 meters from Madurai Junction Railway Station, and one kilometer from Periyar Bus Stand.
                </p>
                <div className="social-icons">
                  <Link to="#"><i className="zmdi zmdi-facebook"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-instagram"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-rss"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-twitter"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-pinterest"></i></Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 offset-lg-1 col-lg-4  col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <h3>contact us</h3>
                <div className="c-info">
                  <span><i className="zmdi zmdi-pin"></i></span>
                  <span>57, Tamilsangam Road, Madurai,  <br />Tamil Nadu 625001</span>
                </div>
                <div className="c-info">
                  <span><i className="zmdi zmdi-email"></i></span>
                  <span>info@moskvahotel.in<br />info@moskvahotel.in</span>
                </div>
                <div className="c-info">
                  <span><i className="zmdi zmdi-phone"></i></span>
                  <span>+91-8489914838<br />+91-452 4219063</span>
                </div>
                <div style={{display:"flex"}}>
                <div className="footer-logo">
                    <a href="https://play.google.com/store/games?hl=en&gl=US&pli=1"><img src="assets/img/moskva-photos/small/plays.png" alt="Oestin" style={{ width: "10rem" }} /></a>
                  </div>
                  <div className="footer-logo" style={{paddingLeft:"5px"}}>
                    <a href="https://www.apple.com/in/app-store/"><img src="assets/img/moskva-photos/small/play.png" alt="Oestin" style={{ width: "10rem" }} /></a>
                  </div>
                  </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <h3>quick links</h3>
                <ul className="footer-list">
                <li><Link to="/">Home</Link></li>
                    <li><Link to="/rooms">Rooms</Link></li>
                    <li><Link to="/benquetmenu">Banquets Menu</Link></li>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/resturants">Resturants</Link></li>
                    <li><Link to="/bars">Bar</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <h3>collections</h3>
                <div className="instagram-image">
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC0962.jpg" alt=""/></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC0980.jpg" alt=""/></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC1004.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#" ><img src="assets/img/moskva-photos/small/DSC1063.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC1071.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#" ><img src="assets/img/moskva-photos/small/DSC1092.jpg" alt="" /></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='text-center'> 
        <p className="designFont">© 2023 MOSKVA All rights reserved. Designed By <a className="designFont" href="https://jofinity.com/">JOFINITY</a></p>
        </div>
      </div>
      
      
     
    </footer>
    </div>
  )
}

export default About