import { useState } from 'react';
import { useEffect } from 'react';
import { collection, onSnapshot, query, orderBy } from 'firebase/firestore';
import { db } from './../firebase/Config';

export const useFetchCollection = (fbcollection) => {

    const [documents, setDocuments] = useState(null)

    useEffect(() => {
        let collectionRef = collection(db, fbcollection)

        //  let queryRef = query(collectionRef,orderBy("createdAt","desc"))

        const unsub = onSnapshot(collectionRef, (snapshot) => {
            let results = []

            snapshot.docs.forEach((doc) => {
                results.push({...doc.data(), id: doc.id })
            })
            setDocuments(results)

        })

        return () => unsub()

    }, [fbcollection])


    return { documents }
    // console.log({ documents });
}