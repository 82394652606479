import React from 'react'
import { BiRupee } from 'react-icons/bi';

const Usersshow = ({detail}) => {
  var c = detail.Startsdate.toDate().toString();
    var b = detail.EndsDate.toDate().toString();
    var roomcount;
    if(detail.Adult <= 3){
      roomcount = 1;
    }
    else if(detail.Adult < 7)
    {
      roomcount = 2;
    }
    else if(detail.Adult < 10)
    {
      roomcount = 3;
    }
    else if(detail.Adult === 10)
    {
      roomcount = 4;
    }

  return (
    <div><section className="pt-5 ">
    <div className="container ">
    <div className="row">
        <div className="col-md-10 mx-auto col-12 card shadow-lg border-0 p-4">
        <div className="row">
        <div>
        <h1 className="display-4 section-title text-center labelHead">Confirmed Booking with PaymentID : {detail.paymentid}</h1>
      </div> 
            <div className="col-md-6 col-12 my-auto">
            
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th>Room Type</th>
                    <td className="labelHead">{detail.RoomType} </td>
                  </tr>
                  <tr>
                    <th>Name</th>
                    <td>{detail.Name} </td>
                  </tr>
                  
                  <tr>
                    <th>Email</th>
                    <td>{detail.Email} </td>
                  </tr>
                  <tr>
                    <th>Phone Number</th>
                    <td>{detail.PhoneNumber} </td>
                  </tr>
                  <tr>
                    <th>Location</th>
                    <td>{detail.Message} </td>
                  </tr>
                  <tr>
                    <th>Expectation Time</th>
                    <td>{detail.ExpectationTime} </td>
                  </tr>
                  <tr>
                    <th>Adults</th>
                    <td>{detail.Adult} </td>
                  </tr>
                  <tr>
                    <th>Child</th>
                    <td>{detail.Child} </td>
                  </tr>
                  <tr>
                  <th>Child Age</th>
                  <td>{detail.FirstAge} </td>
                </tr>
                <tr>
                    <th>Check In Date</th>
                    <td>{c} </td>
                  </tr>
                  <tr>
                    <th>Check Out Date</th>
                    <td>{b} </td>
                  </tr>
                  <tr>
                    <th>Duration Days</th>
                    <td>{detail.DurationTime} </td>
                  </tr>
                  <tr>
                    <th>Food</th>
                    <td>{detail.Food} </td>
                  </tr>
                  <tr>
                    <th>Bed</th>
                    <td>{detail.Bed} </td>
                  </tr>
                  <tr>
                    <th>Selected Rooms</th>
                    <td>You Have Choosed the <strong> {roomcount} </strong>Rooms</td>
                  </tr>
                  

                 





                </thead>
              </table>
            </div>
            <div className="col-md-6 col-12 author-name PriceShown">
            <h6 className="font-weight-bold pt-3">Price  : <span className="labelPrice badge badge-info text-black"><BiRupee /> {detail.Price}</span></h6>
            <h6 className="font-weight-bold ">Taxes - 12%: <span className="labelPrice badge badge-info text-black"><BiRupee /> {detail.Tax}</span></h6>
            <h6 className="font-weight-bold ">CGST - 6%: <span className="labelPrice badge badge-info text-black"><BiRupee /> {detail.CgstTax}</span></h6>
            <h6 className="font-weight-bold ">SGST - 6%: <span className="labelPrice badge badge-info text-black"><BiRupee /> {detail.SGSTTax}</span></h6>
            <h6 className="font-weight-bold ">DiscountPrice : <span className="labelPrice badge badge-info text-black"><BiRupee /> {detail.Discount}</span></h6>
            <h6 className="font-weight-bold pt-3 ">Total Price to be paid : <span className="labelPrice badge badge-info text-black"><BiRupee /> {detail.TotalPrice}</span></h6>
            

                
            
            </div>
            
          </div>
          
          <div className="row pt-5">
          <div className="col-md-6 col-12 author-name">
          <div class="">
          <h6 class="labelHead">Description</h6>
          <p className='pt-2 labelHead'>Please make sure Booking time is from 12 pm to 12 pm, Early Check in to be 750rs will be applicable</p>
          <p className='pt-2 labelHead'></p>

        </div>
          </div>
          <div className="col-md-6 col-12 my-auto">
              
          </div>
          
        </div>
        <div className="row my-4">
            <div className="col-md-6 col-12">
              <div className="form-group">

              </div>
            </div>
            <div className="col-md-6 col-12 my-auto">
              
            </div>
          </div>
        </div>
    </div>
    </div>
    </section></div>
  )
}

export default Usersshow