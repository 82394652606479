import React,{ useState } from 'react';
//import './App.css';
import Slider from './Carousel.js';
import logo from './hotel_logo.png';
import Navbar from './Navbar';
import { Link, useNavigate } from 'react-router-dom';
import Header from './Header.js';
import Popupwindow from './Popupwindow.js';

const Home = () => {
  const navigate = useNavigate();
  const CheckAvail = () => {
    navigate("/rooms");
  }

  return (
    <div>
      <Header />
      <section className="slider-area">
        <div className="slider-wrapper">
          
        <Slider />
        </div>
      </section>
      <section className="about-area">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <form action="#" method="post" className="search-form">
              <div className="form-container fix">
                <div className="box-select">
                  <div className="select date">
                    <input type="date" name="arrive" />
                  </div>
                  <div className="select date">
                    <input type="date" name="departure" />
                  </div>
                  <div className="select arrow">
                    <select name="adults">
                      <option>ADULTS</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                    </select>
                  </div>
                  <div className="select arrow">
                    <select name="children">
                      <option>CHILDREN</option>
                      <option>1</option>
                      <option>2</option>
                    </select>
                  </div>
                </div>
                <button type="submit" onClick={CheckAvail} className="search default-btn"> Check Availability</button>
              </div>
            </form>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-7">
            <div className="video-wrapper mt-90">
              <div className="video-overlay">
              <img src="assets/img/moskva-photos/about.jpg" alt="" />
              </div>
              <Link className="video-popup" to="https://www.youtube.com/watch?v=rXcp6s0VjZk">
                <i className="zmdi zmdi-play-circle-outline"></i>
              </Link>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="about-text">
              <div className="section-title">
                <h3>about us</h3>
                <p>
                Hotel Moskva, Madurai, is a contemporary property endowed with the latest facilities and an enviable location in the city. This hotel is preferred by travellers for its gracious hospitality, myriad of services for leisure, business amenities, banqueting, palatable cosmopolitan fare, and aptly laid-out accommodations.
                </p>
                <p>
                Moskva Hotel is a 3-star property located 15 kilometers from Madurai Airport, 900 meters from Madurai Junction Railway Station, and one kilometer from Periyar Bus Stand.
                </p>
                <p>
                Hotel Moskva features luxurious and well-appointed rooms equipped with modern conveniences such as in-room amenities like cable TV.
                </p>
              </div>
              <div className="about-links">
                <Link to="https://www.facebook.com/"><i className="zmdi zmdi-facebook"></i></Link>
                <Link to="https://www.instagram.com/"><i className="zmdi zmdi-instagram"></i></Link>
                <Link to="https://www.rss.com/"><i className="zmdi zmdi-rss"></i></Link>
                <Link to="https://twitter.com/"><i className="zmdi zmdi-twitter"></i></Link>
                <Link to="https://www.pinterest.com/"><i className="zmdi zmdi-pinterest"></i></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="room-area pt-90">
    <div className="container">
      <div className="row">
        <div className="col-md-8 mx-auto">
          <div className="section-title text-center">
            <h3>our favorite rooms</h3>
            <p>
            The Moskva Hotel, a Madurai hotel, features 44 elegantly appointed spacious rooms, including 9 Standard Rooms, 12 Deluxe Rooms, 16 Fortune Club Rooms, 3 Suites, and 5 Exclusive Club Rooms, considered the best among all the hotels in Madurai.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid overflow-hidden">
      <div className="single-room small">
        <img src="assets/img/moskva-photos/roomsmall/home/DSC0998.jpg" alt="" />
        <div className="room-hover text-center">
          <div className="hover-text">
            <h3><Link to="room-details.html">Classic</Link></h3>
            <p>
            A string of amenities at this hotel cover the major aspects of a comfortable and easy-going stay. Primary services involve room.
            </p>
            <div className="room-btn">
              <Link to="/booking" className="default-btn">DETAILS</Link>
            </div>
          </div>
          <div className="p-amount">
          <span className="count">Double</span>
            <span>₹2500</span>
            <span className="count">Per Night</span>
          </div>
          <div className="p-amount1" >
          <span className="count">Single</span>  
          <span>₹2100</span>
            <span className="count">Per Night</span>
          </div>
        </div>
      </div>
      <div className="single-room large">
        <img src="assets/img/moskva-photos/roomsmall/home/ch/dsc2.jpg" alt="" />
        <div className="room-hover text-center">
          <div className="hover-text">
            <h3><Link to="room-details.html">Continental</Link></h3>
            <p>
            A string of amenities at this hotel cover the major aspects of a comfortable and easy-going stay. Primary services involve room.
            </p>
            <div className="room-btn">
              <Link to="/continental" className="default-btn">DETAILS</Link>
            </div>
          </div>
          <div className="p-amount">
          <span className="count">Double</span> 
            <span>₹3000</span>
            <span className="count">Per Night</span>
          </div>
          <div className="p-amount1" >
          <span className="count">Single</span>  
          <span>₹2500</span>
            <span className="count">Per Night</span>
          </div>
        </div>
      </div>
      <div className="single-room small">
        <img src="assets/img/moskva-photos/roomsmall/home/ch/DSC0980.jpg" alt="" />
        <div className="room-hover text-center">
          <div className="hover-text">
            <h3><Link to="room-details.html">Deluxe Suit</Link></h3>
            <p>
            A string of amenities at this hotel cover the major aspects of a comfortable and easy-going stay. Primary services involve room
            </p>
            <div className="room-btn">
              <Link to="/deluxe" className="default-btn">DETAILS</Link>
            </div>
          </div>
          <div className="p-amount">
          <span className="count">Double</span> 
            <span>₹3500</span>
            <span className="count">Per Night</span>
          </div>
          <div className="p-amount1" >
          <span className="count">Single</span>  
          <span>₹3000</span>
            <span className="count">Per Night</span>
          </div>
        </div>
      </div>
      <div className="single-room medium">
        <img src="assets/img/moskva-photos/roomsmall/home/ch/DSCN6881.jpg" alt="" />
        <div className="room-hover text-center">
          <div className="hover-text">
            <h3><Link to="room-details.html">Presidential Suite</Link></h3>
            <p>
            A string of amenities at this hotel cover the major aspects of a comfortable and easy-going stay. Primary services involve room
            </p>
            <div className="room-btn">
              <Link to="/presidential" className="default-btn">DETAILS</Link>
            </div>
          </div>
          <div className="p-amount">
          <span className="count">Double</span> 
            <span>₹6000</span>
            <span className="count">Per Night</span>
          </div>
          <div className="p-amount1" >
          <span className="count">Single</span>  
          <span>₹6000</span>
            <span className="count">Per Night</span>
          </div>
        </div>
      </div>
    </div>
  </section>
    <section className="services-area ptb-90">
    <div className="container">
      <div className="row">
        <div className="col-md-8 mx-auto">
          <div className="section-title text-center">
            <h3>our awesome services</h3>
            <p>
            Moskva Hotel feature a 24-hour front desk, free wired internet and proximity (500 m) to the famous Madurai Meenakshi Temple. The property is located 12 km from Madurai Airport. We located near Madurai primary railway station, Nayak Palace is 2 km away.
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-5">
          <ul role="tablist" className="nav nav-tabs">
            <li className="nav-item" role="presentation">
              <Link
                className="nav-link active"
                data-bs-toggle="tab"
                role="tab"
                aria-controls="spa"
                to="#spa"
                aria-expanded="true">
                <span className="image p-img"><img src="assets/img/icon/spa.png" alt="" /></span>
                <span className="image s-img"><img src="assets/img/icon/spa-hover.png" alt=""  /></span>
                <span className="title">Room</span>
                <span className="text">The rooms offer a wide range of modern amenities to suit the needs of the discerning traveler.</span>
              </Link>
            </li>

            <li className="nav-item" role="presentation">
              <Link
                className="nav-link"
                data-bs-toggle="tab"
                role="tab"
                aria-controls="swim"
                to="#swim"
                aria-expanded="true">
                <span className="image p-img"><img src="assets/img/icon/swim.png" alt=""/></span>
                <span className="image s-img"><img src="assets/img/icon/swim-hover.png" alt="" /></span>
                <span className="title">Bar</span>
                <span className="text">The hotel also provides guests with bottled water, a coffee or tea maker and mini bar in some rooms.</span>
              </Link>
            </li>

            <li className="nav-item" role="presentation">
              <Link
                className="nav-link"
                data-bs-toggle="tab"
                role="tab"
                aria-controls="restaurant"
                to="#restaurant"
                aria-expanded="true"
              >
                <span className="image p-img"><img src="assets/img/icon/restaurent.png" alt="" /></span>
                <span className="image s-img"><img src="assets/img/icon/restaurent-hover.png" alt="" /></span>
                <span className="title">Restaurant</span>
                <span className="text">Arbatskaya Restaurant at Hotel Moskva Hotel in Madurai may have a traditional touch with unique flavors.</span>
              </Link>
            </li>

            <li className="nav-item" role="presentation">
              <Link
                className="nav-link"
                data-bs-toggle="tab"
                role="tab"
                aria-controls="conference"
                to="#conference"
                aria-expanded="true" >
                <span className="image p-img"><img src="assets/img/icon/conference.png" alt="" /></span>
                <span className="image s-img"><img src="assets/img/icon/conference-hover.png" alt="" /></span>
                <span className="title">Conference</span>
                <span className="text">The widespread arrangement at best party halls in Madurai helps make your event a successful one.</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-lg-7">
          <div className="tab-content">
            <div id="spa" className="tab-pane active" role="tabpanel">
              <img src="assets/img/moskva-photos/Lobby/DSC1017.jpg" alt="" />
            </div>
            <div id="swim" className="tab-pane" role="tabpanel">
              <img src="assets/img/moskva-photos/BAR PHOTOS/DSC05630.jpg" alt="" />
            </div>
            <div id="restaurant" className="tab-pane" role="tabpanel">
              <img src="assets/img/moskva-photos/Restaurant/room108.jpg" alt="" />
            </div>
            <div id="conference" className="tab-pane" role="tabpanel">
              <img src="assets/img/moskva-photos/meetHall1.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="fun-factor-area bg-1 overlay-dark">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="single-fun-factor text-center">
              <h1><span className="counter">112</span></h1>
              <h4>new friendships</h4>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="single-fun-factor text-center">
              <h1><span className="counter">158</span></h1>
              <h4>five start ratings</h4>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="single-fun-factor text-center">
              <h1><span className="counter">430</span></h1>
              <h4>international guests</h4>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="single-fun-factor text-center">
              <h1><span className="counter">745</span></h1>
              <h4>served breakfast</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
   
   
  <section className="advertise-area bg-2 overlay-dark">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="advertise-text">
              <h1>get <span>15% off</span> on any other events...</h1>
              <Link to="#" className="default-btn">Book Now</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="pricing-area ptb-90">
    <div className="container">
      <div className="row">
        <div className="col-md-8 mx-auto">
          <div className="section-title text-center">
            <h3>Our Pricing</h3>
            <p>
            Save on Hotels in Madurai - Search, Compare & Save with trivago™! Find Your Ideal Hotel. Compare Prices and Save on Your Next Stay! Travel Smart, Use trivago. Hotels in Top Cities.
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 col-sm-6">
          <div className="single-pricing">
            <div className="package-name">
              <h3>Classic Pack</h3>
              <h1>
                <span className="currency">₹</span>2100
                <span className="count">/per night</span>
              </h1>
            </div>
            <div className="package-offer">
              <span>Pickup/Drop</span>
              <span>Wifi</span>
              <span>Restaurant (Lunch)</span>
              <span className="light">Treatment</span>
              <span className="light">Face Make</span>
            </div>
            <div className="signup-btn">
              <Link to="/signup" className="default-btn">SIGN UP</Link>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="single-pricing best-offer">
            <div className="package-name">
              <h3>Deluxe Pack</h3>
              <h1>
                <span className="currency">₹</span>3000
                <span className="count">/per night</span>
              </h1>
            </div>
            <div className="package-offer">
              <span>Pickup/Drop</span>
              <span>Wifi</span>
              <span>Restaurant (Lunch)</span>
              <span>Treatment</span>
              <span className="light">Face Make</span>
            </div>
            <div className="signup-btn">
              <Link to="/signup" className="default-btn">SIGN UP</Link>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="single-pricing">
            <div className="package-name">
              <h3>Continental Pack</h3>
              <h1>
                <span className="currency">₹</span>2500
                <span className="count">/per night</span>
              </h1>
            </div>
            <div className="package-offer">
              <span>Pickup/Drop</span>
              <span>Wifi</span>
              <span>Restaurant (Lunch)</span>
              <span>Treatment</span>
              <span>Face Make</span>
            </div>
            <div className="signup-btn">
              <Link to="/signup" className="default-btn">SIGN UP</Link>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="single-pricing">
            <div className="package-name">
              <h3>Presidential Pack</h3>
              <h1>
                <span className="currency">₹</span>6000
                <span className="count">/per night</span>
              </h1>
            </div>
            <div className="package-offer">
              <span>Flight Ticket</span>
              <span>Wifi</span>
              <span>Restaurant (Lunch/Dinner)</span>
              <span>Treatment</span>
              <span>Face Make</span>
            </div>
            <div className="signup-btn">
              <Link to="/signup" className="default-btn">SIGN UP</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Popupwindow />
  </section>
 

<section className="newsletter-area bg-light">
      <div className="container">
        <div className="row">
          <div className="col-xl-8 mx-auto col-12">
            <div className="newsletter-container">
              <h3>NewsLetter Sign-Up</h3>
              <div className="newsletter-form">
                <form action="#" id="mc-form" className="mc-form fix">
                  <input id="mc-email" type="email" name="email" placeholder="Enter your E-mail"/>
                  <button id="mc-submit" type="submit" className="default-btn">subcribes</button>
                </form>
               
                <div className="mailchimp-alerts">
                  <div className="mailchimp-submitting"></div>
                  
                  <div className="mailchimp-success"></div>
                 
                  <div className="mailchimp-error"></div>
                  
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer className="footer-area">
      
      <div className="footer-widget-area bg-dark">
        <div className="container">
          <div className="row mb-n60">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <div className="footer-logo">
                  <Link to="#"><img src={logo} alt="Oestin" style={{ width: "14rem" }} /></Link>
                </div>
                <p>
                Moskva Hotel is a 3-star property located 15 kilometers from Madurai Airport, 900 meters from Madurai Junction Railway Station, and one kilometer from Periyar Bus Stand.
                </p>
                <div className="social-icons">
                  <Link to="#"><i className="zmdi zmdi-facebook"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-instagram"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-rss"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-twitter"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-pinterest"></i></Link>
                </div>
              </div>
             
            </div>
            <div className="col-xl-3 offset-lg-1 col-lg-4  col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <h3>contact us</h3>
                <div className="c-info">
                  <span><i className="zmdi zmdi-pin"></i></span>
                  <span>57, Tamilsangam Road, Madurai,  <br />Tamil Nadu 625001</span>
                </div>
                <div className="c-info">
                  <span><i className="zmdi zmdi-email"></i></span>
                  <span>info@moskvahotel.in<br />info@moskvahotel.in</span>
                </div>
                <div className="c-info">
                  <span><i className="zmdi zmdi-phone"></i></span>
                  <span>+91-8489914838<br />+91-452 4219063</span>
                </div>
                <div style={{display:"flex"}}>
                <div className="footer-logo">
                    <a href="https://play.google.com/store/games?hl=en&gl=US&pli=1"><img src="assets/img/moskva-photos/small/plays.png" alt="Oestin" style={{ width: "10rem" }} /></a>
                  </div>
                  <div className="footer-logo" style={{paddingLeft:"5px"}}>
                    <a href="https://www.apple.com/in/app-store/"><img src="assets/img/moskva-photos/small/play.png" alt="Oestin" style={{ width: "10rem" }} /></a>
                  </div>
                  </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <h3>quick links</h3>
                <ul className="footer-list">
                <li><Link to="/">Home</Link></li>
                    <li><Link to="/rooms">Rooms</Link></li>
                    <li><Link to="/benquetmenu">Banquets Menu</Link></li>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/resturants">Resturants</Link></li>
                    <li><Link to="/bars">Bar</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <h3>collections</h3>
                <div className="instagram-image">
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC0962.jpg" alt=""/></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC0980.jpg" alt=""/></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC1004.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#" ><img src="assets/img/moskva-photos/small/DSC1063.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC1071.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#" ><img src="assets/img/moskva-photos/small/DSC1092.jpg" alt="" /></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='text-center'> 
        <p className="designFont">© 2023 MOSKVA All rights reserved. Designed By <a className="designFont" href="https://jofinity.com/">JOFINITY</a></p>
        </div>
      </div>
     
      
     
    </footer>
    
    </div>
  )
}

export default Home;