import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { BiRupee } from 'react-icons/bi';
import logo from '../masko.jpeg';
import Navbar from '../Navbar';
import ChildSelection from '../../bookForm/childSelection';

var quantity = 2100;
var amount=  10;
const Deluxebookingform = () => {
    const [count, setCount] = useState(1); // useState returns a pair. 'count' is the current state. 'setCount' is a function we can use to update the state.
    //const [stock, setStock] = useState(quantity);
    const [startDate, startTime] = useState(new Date());
    const [endDate, endTime] = useState(new Date());
    const [duration, setDuration] = useState("");
    //const [fruit, setFruit] = useState();
  
    const timeCalc = () => {
      var start = moment(startDate);
      var end = moment(endDate);
      //console.log(end.diff(start, "days", true) + ' days');
      setDuration(end.diff(start, "days") + 1);
    }
    // var a = moment(new Date()); //todays date
    //var b = moment("2022-12-16"); // another date
 
    //var days = b.diff(a, 'days') ;
    //console.log(days)
    //console.log(availableItems[0].quantity);
    const increaseQuantity = () => {
      //const currentItems = [...stock];
        quantity += 0;
      //currentItems[index].amount += 1;
     // setStock(quantity);
      setCount(count+1);
      //console.log(duration * jsonObj[0]['quantity']);
     // var daysPrice = duration * availableItems[0].quantity;
      // var final = daysPrice + 1500;
    //  console.log("Select the room price " + daysPrice);
   
    
    };
  
    const decreaseQuantity = () => {
      //const currentItems = [...stock];
  
      if (quantity > 1) {
        quantity -= 0;
        //currentItems[index].amount -= 1;
        //setStock(quantity);
      }
      setCount(count-1);
    };
  
    // const changeHandler = (e) => {
    //   if (e.target.value === 'country') {
    //     setIsVisible(true);
    //   } else {
    //     setIsVisible(false);
    //   }
    // };
    // const changeRoomHandler = (e) => {
    //   if (e.target.value === 'roomCh') {
    //     setIsShow(true);
    //   } else {
    //     setIsShow(false);
    //   }
    // };
  
  
    
  
  
  
  
  
  
    //console.log(count);
    var durationPrice = duration * quantity;
    //console.log(durationPrice + 400 + 500 + durationPrice);
    var due;
    const v1 = 400;
    const v2 = 500;
      if(count === 1) {
        due = 0;
       }
       else if(count === 2)
       {
        
        due =  v1;
       }
       else if(count === 3)
       {
        due = v1 + v2;
       }
       else if(count === 4)
       {
        alert('Please Select the second Room');
        due =  durationPrice + v1 + v2 ;
       }
       else if(count === 5)
       {
        due =  durationPrice + v1 + v2 + v1;
       }
       else if(count === 6)
       {
        due =  durationPrice + v1 + v2 + v1 + v2;
       }
       else if(count === 7)
       {
        alert('Please Select the third Room');
        due =  durationPrice + v1 + v2 + v1 + v2 + durationPrice;
       }
       else if(count === 8)
       {
        due =  durationPrice + v1 + v2 + v1 + v2 + durationPrice + v1;
       }
       else if(count === 9)
       {
        due =  durationPrice + v1 + v2 + v1 + v2 + durationPrice + v1 + v2;
       }
       else if(count === 10)
       {
        alert('Please Select the fourth Room');
        due =  durationPrice + v1 + v2 + v1 + v2 + durationPrice + v1 + v2 + durationPrice;
       }
       else 
       {
        due =  durationPrice + durationPrice;
       }
   // console.log("Single Room " + duration * availableItems[0].quantity);
    //console.log("Duration of days Room " + duration * availableItems[0].quantity);
    var durationsPrice = duration * quantity;
    var discountPrice = amount;
    var total = durationsPrice - discountPrice + due;
    //console.log(total);
    // var stringify = JSON.stringify(stock);
    //var jsonObj = JSON.parse(stringify);
    // console.log(duration * jsonObj[0]['quantity']);

  return (
    <div>
    <header className="header-area fixed header-sticky" >
    <div className="container">
      <div className="row">
        <div className="col-xl-5 col-lg-4 col-sm-4 col-12">
          <div className="logo">
            <Link to="#"><img src={logo} alt="Oestin" style={{ height: "43px", width: "12rem" }} /></Link>
          </div>
        </div>
        <div className="col-xl-7 col-lg-8 col-sm-8 col-12">
          <div className="header-top fix">
            <div className="header-contact">
              <span className="text-theme">Contact:</span>
              <span>0123456789</span>
            </div>
            <div className="header-links">
              <Link to="https://www.facebook.com/"><i className="zmdi zmdi-facebook"></i></Link>
              <Link to="https://twitter.com/"><i className="zmdi zmdi-twitter"></i></Link>
              <Link to="https://plus.google.com/"><i className="zmdi zmdi-google-plus"></i></Link>
              <Link to="https://www.instagram.com/"><i className="zmdi zmdi-instagram"></i></Link>
              <Link to="https://www.pinterest.com/"><i className="zmdi zmdi-pinterest"></i></Link>
            </div>
          </div>

          <div className="main-menu d-none d-lg-block">
                    <Navbar />
          </div>

        </div>
      </div>
    </div>

    <div className="mobile-menu-area">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="mobile-menu">
              <nav id="dropdown">
                    <Navbar />
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>

  </header>
  <section class="breadcrumb-area overlay-dark-2 bg-3">
<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="breadcrumb-text text-center">
                <h2>Booking the Room</h2>
                <p>A quality room of deluxe room details in maskva</p>
                <div class="breadcrumb-bar">
                    <ul class="breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li>Room Details</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
</section>
  <section className="pt-5 ">
    <div className="container ">
      <div className="row">
        <div className="col-md-10 mx-auto col-12 card shadow-lg border-0 p-4">
          <div>
            <h1 className="display-4 section-title text-center labelHead">Booking the Room</h1>
          </div>
          <div className="row">
            <div className="col-md-6 col-12 my-auto">
              <img src="assets/img/moskva-photos/about.jpg" className="" alt="selected room" style={{ width: "100%", height: "25rem" }} />
            </div>
            <div className="col-md-6 col-12 my-auto">
             
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th>Room Type</th>
                    <td className="labelHead">Single Room - {quantity} / per day</td>
                  </tr>
                  <tr>
                    <th>Name</th>
                    <td> <input class="form-control" type="text" placeholder="Enter the Name" /> </td>
                  </tr>
                  <tr>
                    <th>Phone Number</th>
                    <td> <input class="form-control" type="text" placeholder="Enter the Phone Number" /></td>
                  </tr>
                  <tr>
                    <th>Address</th>
                    <td> <textarea class="form-control" rows="5" id="comment"></textarea></td>
                  </tr>





                </thead>
              </table>
            </div>
          </div>
          <div className="row my-3 checkDate">
            <div className="col-md-3 col-12 form-container fix">
              <label htmlFor="Fromdate" className="font-weight-bolder labelHead">Check In </label>
              <div className="">

                <div class=" ">

                <DatePicker selected={startDate} value={startDate} onChange={startTime}  />
                </div>

              </div>
            </div>
            <div className="col-md-3 col-12">
              <label htmlFor="Todate" className="font-weight-bolder mr-3 labelHead">Check Out </label>
              <div className="">

                <div class="">
                  <DatePicker selected={endDate} value={endDate} onChange={endTime} />

                </div>
              </div>
            </div>
            <div className="col-md-3 col-12">

              <div className="">

                <div class="pt-1">

                  <button className="btn btn-white  search default-btn text-center" onClick={() => timeCalc(startDate, endDate)}>
                    Confirm The Date  {duration}
                  </button>
                  <h6 className="font-weight-bolder pb labelHead pt-2">Number of days : <span class="labelPrice">{duration}</span></h6>
                </div>
              </div>
            </div>

          </div>
          
          <div className="row my-3">
            <div className="col-md-6 col-12 form-container fix">
              <label htmlFor="Fromdate" className="font-weight-bolder labelHead">Adults </label>
              <div className="">

                <div class=" pb-3">

                  <select name="adults" id="fruits" >
                    <option>ADULTS</option>
                    <option value="">1</option>
                    <option value="">2</option>
                    <option value="">3</option>
                    <option value="roomCh">4</option>
                    <option value="roomCh">5</option>
                    <option value="roomCh">6</option>
                  </select>
                </div>
                
              </div>
            </div>
            <div className="col-md-6 col-12">
            <label htmlFor="Fromdate" className="font-weight-bolder labelHead">Children </label>
            <ChildSelection />
            </div>               
          </div>
         
          <div className="row my-3">
         
            <label htmlFor="Fromdate" className="font-weight-bolder labelHead">Adults </label>
            <div className="">
              
                <div key="">
                 <span className="roomName"></span>  <button className="btnAddColor" onClick={() => increaseQuantity()}>+</button><span className="addCount">{count}</span>
                  <button className="btnAddColor" onClick={() => decreaseQuantity()}>-</button>
                </div>
              
            </div>
          </div>
          
          <div className="row pt-2">
            <div className="col-md-6 col-12 author-name">
            <div class="">
            <h6 class="labelHead">Description</h6>
            <p className='pt-2 labelHead'>Please make sure Booking time is from 12 am to 12 pm</p>

          </div>
            </div>
            <div className="col-md-6 col-12 author-name">
              <h6 className="font-weight-bold labelHead">Price  : <span className="labelPrice badge badge-info text-black"><BiRupee /> {durationPrice + due}</span></h6>
              <h6 className="font-weight-bold labelHead">DiscountPrice : <span className="labelPrice badge badge-info text-black"><BiRupee />{discountPrice} </span></h6>
              <h6 className="font-weight-bold pt-3 labelHead">Total Price to be paid : <span className="labelPrice badge badge-info text-black"><BiRupee /> {total}</span></h6>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-md-6 col-12">
              <div className="form-group">

              </div>
            </div>
            <div className="col-md-6 col-12 my-auto">
              <div className="col-md-6 col-12 float-right">
                <button className="btn btn-white  search default-btn text-center" data-toggle="modal" data-target="#thanks">Confirm Booking</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="thanks">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body p-4">
              <h3>Thank you </h3>
              <p className="lead">Your room is booked successfully....</p>
            </div>
            <div className="modal-footer">
              <Link to="/" className="btn btn-dark">Goto Home</Link>
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <footer className="footer-area pt-5 mt-5">
  
  <div className="footer-widget-area bg-dark">
    <div className="container">
      <div className="row mb-n60">
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-60">
          <div className="single-footer-widget">
            <div className="footer-logo">
              <Link to="#"><img src={logo} alt="Oestin" style={{ height: "43px", width: "12rem" }} /></Link>
            </div>
            <p>
              Lorem ipsum dolor sit amet, conse acteir our adipisicing elit,
              sed do eiusmod the a tempor the incididunt ut labore et dolore
              magnaa liqua. Ut enim minimn.
            </p>
            <div className="social-icons">
              <Link to="#"><i className="zmdi zmdi-facebook"></i></Link>
              <Link to="#"><i className="zmdi zmdi-instagram"></i></Link>
              <Link to="#"><i className="zmdi zmdi-rss"></i></Link>
              <Link to="#"><i className="zmdi zmdi-twitter"></i></Link>
              <Link to="#"><i className="zmdi zmdi-pinterest"></i></Link>
            </div>
          </div>
        </div>
        <div className="col-xl-3 offset-lg-1 col-lg-4  col-md-6 col-sm-6 col-12 mb-60">
          <div className="single-footer-widget">
            <h3>contact us</h3>
            <div className="c-info">
              <span><i className="zmdi zmdi-pin"></i></span>
              <span>Your address <br />goes here</span>
            </div>
            <div className="c-info">
              <span><i className="zmdi zmdi-email"></i></span>
              <span>demo@example.com<br />demo@example.com</span>
            </div>
            <div className="c-info">
              <span><i className="zmdi zmdi-phone"></i></span>
              <span>0123456789<br />0123456789</span>
            </div>
          </div>
        </div>
        <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12 mb-60">
          <div className="single-footer-widget">
            <h3>quick links</h3>
            <ul className="footer-list">
              <li><Link to="#">Home</Link></li>
              <li><Link to="#">Stuffs</Link></li>
              <li><Link to="#">Suits &amp; Rooms</Link></li>
              <li><Link to="#">Event</Link></li>
              <li><Link to="#">Location</Link></li>
              <li><Link to="#">Contact</Link></li>
            </ul>
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-60">
          <div className="single-footer-widget">
            <h3>collections</h3>
            <div className="instagram-image">
              <div className="footer-img">
                <Link to="#"><img src="assets/img/moskva-photos/small/DSC0962.jpg" alt=""/></Link>
              </div>
              <div className="footer-img">
                <Link to="#"><img src="assets/img/moskva-photos/small/DSC0980.jpg" alt=""/></Link>
              </div>
              <div className="footer-img">
                <Link to="#"><img src="assets/img/moskva-photos/small/DSC1004.jpg" alt="" /></Link>
              </div>
              <div className="footer-img">
                <Link to="#" ><img src="assets/img/moskva-photos/small/DSC1063.jpg" alt="" /></Link>
              </div>
              <div className="footer-img">
                <Link to="#"><img src="assets/img/moskva-photos/small/DSC1071.jpg" alt="" /></Link>
              </div>
              <div className="footer-img">
                <Link to="#" ><img src="assets/img/moskva-photos/small/DSC1092.jpg" alt="" /></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
 
</footer>
    </div>
  )
}

export default Deluxebookingform