import React from 'react'

const Benqdata = ({veg}) => {
  return (
    <div><h1>{veg.name}</h1>
    <table class="table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">First</th>
      <th scope="col">Last</th>
      <th scope="col">Handle</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>{veg.Fruit}</td>
      <td>{veg.Fruit}</td>
      <td>{veg.Fruit}</td>
    </tr>
    
  </tbody>
</table>
    </div>
    
  )
}

export default Benqdata