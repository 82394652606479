import React,{useState,useEffect} from 'react'
import { Modal, Button } from 'react-bootstrap'

import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  list,
} from "firebase/storage";
import { storage } from "./../firebase/Config";
import { v4 } from "uuid";


const Popupwindow = () => {
  const [isShow, invokeModal] = React.useState(true)
  const [imageUpload, setImageUpload] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);

  const imagesListRef = ref(storage, "images/");
  const uploadFile = () => {
    if (imageUpload == null) return;
    const imageRef = ref(storage, `images/${imageUpload.name + v4()}`);
    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setImageUrls((prev) => [...prev, url]);
      });
    });
  };
  useEffect(() => {
    listAll(imagesListRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setImageUrls((prev) => [...prev, url]);
        });
      });
    });
  }, []);

  const initModal = () => {
     invokeModal(false) 
   // window.location.reload();
  }
  return (
    <div className='pt-5'>   
    
      
  <Modal show={isShow} style={{marginTop: "150px"}}>
    <Modal.Header closeButton onClick={initModal}>
    {imageUrls.map((url) => {
      return <img src={url} className="popImages" />;
    })}
    </Modal.Header>
   
    
  </Modal>
            </div>
  )
}

export default Popupwindow