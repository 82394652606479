import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import './Carousel.css';
import Image from "./com.png";
import Image1 from "./tmplsr.jpg";

const options = {
    loop: true,
    nav: true,
    navText: [""],
    dots: false,
    autoplay: true,
    autoplayTimeout: 4000,
    smartSpeed: 1200,
    autoplayHoverPause: true,
    lazyLoad: true,
    items: 1,

};

class Slider extends Component {
    render() {
        return (
            <div>
                <OwlCarousel className="classic-testimonial-slider slider-items owl-carousel" {...options}>
                    .<div className="item" style={{ height:"70rem" }} >
                        <div className="single-slide" style={{ backgroundImage: 'url(' + Image + ')' }} >

                            <div className="banner-content overlay">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-10 mx-auto">
                                            <div className="text-content-wrapper">
                                                <div className="text-content text-center">
                                                    <h1 className="pt-180">welcome to MOSKVA</h1>
                                                    <p>
                                                    This hotel is preferred by travellers for its gracious hospitality, myriad of services for leisure, business amenities, banqueting, palatable cosmopolitan fare, and aptly laid-out accommodations.
                                                    </p>
                                                    <div className="banner-btn">
                                                        <a className="default-btn" href="#" >explore</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item" style={{ height:"70rem" }}>
                        <div className="single-slide" style={{ backgroundImage: 'url(' + Image1 + ')' }}>

                            <div className="banner-content overlay">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-10 mx-auto">
                                            <div className="text-content-wrapper slide-two">
                                                <div className="text-content text-center">
                                                    <h1 className="pt-180">Welcome to MOSKVA</h1>
                                                    <p>
                                                    Moskva Hotel is a 3-star property located 15 kilometers from Madurai Airport, 900 meters from Madurai Junction Railway Station, and one kilometer from Periyar Bus Stand. Hotel Moskva features luxurious and well-appointed rooms equipped with modern conveniences such as in-room amenities like cable TV.
                                                    </p>
                                                    <div className="banner-btn">
                                                        <a className="default-btn" href="#">explore</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </OwlCarousel>
            </div>
        );
    }
}

export default Slider;