import React, { useState } from 'react'
import logo from './hotel_logo.png';
//import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import { useFirestore } from './../hooks/useFirestore';
import { useAuthContext } from './../hooks/useAuthContext';
import Header from './Header';
import { useNavigate } from 'react-router-dom';

const Benquetmenu = () => {
  const [fruit, setFruit] = useState();
  const [color, setColor] = useState();
  const [salad, setSalad] = useState();
  const [chat, setChat] = useState();
  const [naan, setNaan] = useState();
  const [bread, setBread] = useState();
  const [halwa, setHalwa] = useState();
  const [ice, setIce] = useState();
  const [pulavo, setPulavo] = useState();
  const [dhal, setDhal] = useState();
  const [rice, setRice] = useState();
  const [dry, setDry] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phonenumber, setPhonenumber] = useState();
  //console.log(color);
  const {addDocument,error} = useFirestore('menus');
  const {user,isAuthReady} = useAuthContext();
  const navigate = useNavigate();
  const handleSubmit = async () => {
    //alert(color);
    
   addDocument({Fruit:fruit,Color:color,Salad:salad,Chat:chat,Naan:naan,Bread:bread,Halwa:halwa,Ice:ice,Pulavo:pulavo,Dhal:dhal,Rice:rice,Dry:dry,name:name,email:email,phonenumber:phonenumber,userId:user.uid})
   alert('Ordered Success');
   navigate('/');
  }

  return (
    <div>
    <Header />
      <section className="breadcrumb-area overlay-dark-2 bg-3">
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="breadcrumb-text text-center">
                    <h2>BANQUET MENU</h2>
                    <p>LUNCH/DINNER</p>
                    <div className="breadcrumb-bar">
                        <ul className="breadcrumb">
                            <li><Link to="/">Home</Link></li>
                            <li>Banquet Menu</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    <div className="container pt-5 benqu">
      <div className="row">
      <div>
                <h1 className="display-4 section-title text-center labelHead">Choosed The Banquet Menus </h1>
              </div>
        <form>
          <div className="row">
            <div className="form-group col">
              <label for="inputState" className="banlabel">SOUP</label>
              <select id="inputState" name="adults" className="form-control" onChange={(e) => setFruit(e.target.value)}>
                <option selected>Choose...</option>
                <option value="TOMATO SOUP">TOMATO SOUP</option>
                <option value="MINESTRONE SOUP">MINESTRONE SOUP</option>
                <option value="TOMATO SHORBA">TOMATO SHORBA</option>
                <option value="CORIANDER PEPPER SOUP">CORIANDER PEPPER SOUP</option>
                <option value="VEG MANCHOW SOUP">VEG MANCHOW SOUP</option>
                <option value="MUSHROOM SOUPETT">MUSHROOM SOUPETT</option>
                <option value="VEGETABLE CLEAT SOUP">VEGETABLE CLEAT SOUP</option>
                <option value="MULLIGATWANY SOUP">MULLIGATWANY SOUP</option>
                <option value="DRUMSTICK SOUP">DRUMSTICK SOUP</option>
                <option value="CHETTINAD DHAL SOUP">CHETTINAD DHAL SOUP</option>
                <option value="SWEET CORN VEG SOUP">SWEET CORN VEG SOUP</option>
                <option value="HOT&SOUR VEG SOUP">HOT&SOUR VEG SOUP</option>

              </select>
            </div>
            <div className="form-group col">
              <label for="inputState"  className="banlabel">VEGETARIAN-GRAVY</label>
              <select id="inputState" name="adults" className="form-control" onChange={(e) => setColor(e.target.value)}>
                <option selected>Choose...</option>
                <option value="PANEER SHAHI KURMA">PANEER SHAHI KURMA</option>
                <option value="PANEER BUTTER MASALA">PANEER BUTTER MASALA</option>
                <option value="VEGETABLE ROGAN JOSH">VEGETABLE ROGAN JOSH</option>
                <option value="NAVRATHAN KHURMA">NAVRATHAN KHURMA</option>
                <option value="VEGETABLE KHURMA">VEGETABLE KHURMA</option>
                <option value="PANEER MIRCHI MASALA">PANEER MIRCHI MASALA</option>
                <option value="KADAAI PANEER">KADAAI PANEER</option>
                <option value="BAKED VEGETABLE">BAKED VEGETABLE</option>
                <option value="PANEER JALFREEZY ">PANEER JALFREEZY </option>
                <option value="ALOO GOBI MASALA">ALOO GOBI MASALA</option>
                <option value="PANEER PASANDA">PANEER PASANDA</option>
                <option value="MALAI KOFTHA CURRY">MALAI KOFTHA CURRY</option>
                <option value="DINGRI MUTTER">DINGRI MUTTER</option>
                <option value="MUTTER PANEER">MUTTER PANEER</option>
                <option value="ALOO JEERA/ALOO PALAK">ALOO JEERA/ALOO PALAK</option>
                <option value="ALOO CAPSICUM">ALOO CAPSICUM</option>
                <option value="ALOO MUTTER">ALOO MUTTER</option>

              </select>
            </div>
          </div>
          <div className="row pt-5">
            <div className="form-group col">
              <label for="inputState"  className="banlabel">SALAD CHAT</label>
              <select id="inputState" name="adults" className="form-control" onChange={(e) => setChat(e.target.value)}>
                <option selected>Choose...</option>
                <option value="CHANNA CHAT">CHANNA CHAT</option>
                <option value="FRUIT CHAT">FRUIT CHAT</option>
                <option value="ALCO CHAT">ALCO CHAT</option>
                <option value="ALCO BEANS">ALCO BEANS</option>
                <option value="KOSAMBARI">KOSAMBARI</option>
                <option value="CUCUMBER KIMCHEE">CUCUMBER KIMCHEE</option>
                <option value="COLESLAW">COLESLAW</option>

              </select>
            </div>
            <div className="form-group col">
              <label for="inputState"  className="banlabel">SALAD</label>
              <select id="inputState" name="adults" className="form-control" onChange={(e) => setSalad(e.target.value)}>
                <option selected>Choose...</option>
                <option value="MINESTRONE SOUP">BEETROOT SALAD</option>
                <option value="TOOSED SALAD">TOOSED SALAD</option>
                <option value="GREEN SALAD">GREEN SALAD</option>
                <option value="CARROT & RAISIN SALAD">CARROT & RAISIN SALAD</option>
                <option value="ORIENTAL PEANUT SALAD">ORIENTAL PEANUT SALAD</option>
                <option value="THREE BEANS SALAD">THREE BEANS SALAD</option>
                <option value="BEANS SPROUT SALAD">BEANS SPROUT SALAD</option>
                <option value="PASTA SALAD">PASTA SALAD</option>
              </select>
            </div>
          </div>
          <div className="row pt-5">
            <div className="form-group col">
              <label for="inputState"  className="banlabel">INDIAN BREADS</label>
              <select id="inputState" name="adults" className="form-control" onChange={(e) => setNaan(e.target.value)}>
                <option selected>Choose...</option>
                <option value="PLAIN NAAN">PLAIN NAAN</option>
                <option value="BUTTER NAAN">BUTTER NAAN</option>
                <option value="GARLIC NAAN">GARLIC NAAN</option>
                <option value="CHAPPATHI">CHAPPATHI</option>
              </select>
            </div>
            <div className="form-group col">
              <label for="inputState"  className="banlabel">PARATHA SPECIAL</label>
              <select id="inputState" name="adults" className="form-control" onChange={(e) => setBread(e.target.value)}>
                <option selected>Choose...</option>
                <option value="TAWA PARATHA">TAWA PARATHA</option>
                <option value="TANDOORI PARATHA">TANDOORI PARATHA</option>
                <option value="MASALA KULCHA">MASALA KULCHA</option>
                <option value="TANDOORI ROTI">TANDOORI ROTI</option>
                
              </select>
            </div>
          </div>
          
          <div className="row pt-5">
            <div className="form-group col">
              <label for="inputState"  className="banlabel">DESSERT`S</label>
              <select id="inputState" name="adults" className="form-control" onChange={(e) => setHalwa(e.target.value)}>
                <option selected>Choose...</option>
                <option value="PUMPKIN HALWA">PUMPKIN HALWA</option>
                <option value="CARROT HALWA">CARROT HALWA</option>
                <option value="BREAD HALWA">BREAD HALWA</option>
                <option value="GULAB JAMOON">GULAB JAMOON</option>
                <option value="KALA JAMOON">KALA JAMOON</option>
                <option value="DHALL PAAYAASAM">DHALL PAAYAASAM</option>
                <option value="CARAMEL CUSTAR">CARAMEL CUSTAR</option>
              </select>
            </div>
            <div className="form-group col">
              <label for="inputState"  className="banlabel">DESSERT`S SPECIAL</label>
              <select id="inputState" name="adults" className="form-control" onChange={(e) => setIce(e.target.value)}>
                <option selected>Choose...</option>
                <option value="SHAHITUKURA">SHAHITUKURA</option>
                <option value="FRUIT CUSTARD">FRUIT CUSTARD</option>
                <option value="FRUIT SALAD">FRUIT SALAD</option>
                <option value="BANANA FRITTERS">BANANA FRITTERS</option>
                <option value="VANNILA ICE CREAM">VANNILA ICE CREAM</option>
                <option value="VANNILA ICE CREAM">STRAWBERRY ICE CREAM</option>
                <option value="TRIFFLEPUDDING">TRIFFLEPUDDING</option>
                
              </select>
            </div>
          </div>
          <div className="row pt-5">
            <div className="form-group col">
              <label for="inputState"  className="banlabel">PULAVO/BIRIYANI</label>
              <select id="inputState" name="adults" className="form-control" onChange={(e) => setPulavo(e.target.value)}>
                <option selected>Choose...</option>
                <option value="PANEER PULAO">PANEER PULAO</option>
                <option value="VEGETABLE PULAO">VEGETABLE PULAO</option>
                <option value="NUTS PULAO">NUTS PULAO</option>
                <option value="JEERA PULAO">JEERA PULAO</option>
                <option value="PEAS PULAO">PEAS PULAO</option>
                <option value="KISMISS PULAO">KISMISS PULAO</option>
                <option value="VEGETABLE BIRIYANI">VEGETABLE BIRIYANI</option>
                <option value="PLAIN KUSHKA">PLAIN KUSHKA</option>
                <option value="GHEE RICE">GHEE RICE</option>
                <option value="KASHMIRI PULAO">KASHMIRI PULAO</option>
                <option value="VEGETABLE FRIED RICE">VEGETABLE FRIED RICE</option>
                <option value="SCEZWAN FRIED RICE">SCEZWAN FRIED RICE</option>
              </select>
            </div>
            <div className="form-group col">
              <label for="inputState"  className="banlabel">DHAL</label>
              <select id="inputState" name="adults" className="form-control" onChange={(e) => setDhal(e.target.value)}>
                <option selected>Choose...</option>
                <option value="MASALA DHAL">  DHAL</option>
                <option value="DHAL MAKHNI">DHAL MAKHNI</option>
                <option value="DHAL MAHARANI">DHAL MAHARANI</option>
                <option value="DHAL FALAK">DHAL FALAK</option>
                <option value="DHAL TADKA">DHAL TADKA</option>
                <option value="CHANNA MASALA">CHANNA MASALA</option>
                <option value="RAJMA MASALA">RAJMA MASALA</option>
                <option value="DAL FRY">DAL FRY</option>
              </select>
            </div>
          </div>
          <div className="row pt-5">
            <div className="form-group col">
              <label for="inputState"  className="banlabel">VEGETARIAN-DRY</label>
              <select id="inputState" name="adults" className="form-control" onChange={(e) => setDry(e.target.value)}>
                <option selected>Choose...</option>
                <option value="POTATO KARA CURRY">POTATO KARA CURRY</option>
                <option value="BEANS USILI">BEANS USILI</option>
                <option value="GOBI MANCHURIAN/CHILLY">GOBI MANCHURIAN/CHILLY</option>
                <option value="BHINDI CHOLAPURI">BHINDI CHOLAPURI</option>
                <option value="CRISPY FRIED VEGETABLE">CRISPY FRIED VEGETABLE</option>
                <option value="BOILED VEGETABLE">BOILED VEGETABLE</option>
                <option value="ROASTED POTATO WEDGES">ROASTED POTATO WEDGES</option>
                <option value="ENNAI KATHARIKKAI">ENNAI KATHARIKKAI</option>
                <option value="GOBI-65">GOBI-65</option>
                <option value="ALOO JEERA">ALOO JEERA</option>
                <option value="BHINDI PEPPER FRY">BHINDI PEPPER FRY</option>
                <option value="KARAI KUDI GOBI FRY">KARAI KUDI GOBI FRY</option>
              </select>
            </div>
            <div className="form-group col">
              <label for="inputState"  className="banlabel">VARIETY RICE</label>
              <select id="inputState" name="adults" className="form-control" onChange={(e) => setRice(e.target.value)}>
                <option selected>Choose...</option>
                <option value="CRUD RICE">CRUD RICE</option>
                <option value="BISIBELLA BATH">BISIBELLA BATH</option>
                <option value="CURRY LEAF RICE">CURRY LEAF RICE</option>
                <option value="LEMON RICE">LEMON RICE</option>
                <option value="TAMARIND RICE">TAMARIND RICE</option>
                <option value="STEAMED RICE WITH RASAM/CURD">STEAMED RICE WITH RASAM/CURD</option>
                
              </select>
            </div>
          </div>
          <div className="row pt-5">
          <div className="col">
            <input type="text" className="form-control"  value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
          </div>
          <div className="col">
            <input type="email" className="form-control"  value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
          </div>
          <div className="col">
            <input type="number" className="form-control"  value={phonenumber} onChange={(e) => setPhonenumber(e.target.value)} placeholder="PhoneNumber" />
          </div>
        </div>
        <div className="row">
        <div className="col-12 text-center pt-5">
        <button className="btn btn-white  search default-btn text-center"  onClick={handleSubmit} data-toggle="modal" data-target="#thanks">Confirm</button>
        </div>
        </div>
        </form>
      </div>

    </div>
    <footer className="footer-area pt-5 mt-5">
      
      <div className="footer-widget-area bg-dark">
        <div className="container">
          <div className="row mb-n60">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <div className="footer-logo">
                  <Link to="#"><img src={logo} alt="Oestin" style={{ width: "14rem" }} /></Link>
                </div>
                <p>
                Moskva Hotel is a 3-star property located 15 kilometers from Madurai Airport, 900 meters from Madurai Junction Railway Station, and one kilometer from Periyar Bus Stand.
                </p>
                <div className="social-icons">
                  <Link to="#"><i className="zmdi zmdi-facebook"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-instagram"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-rss"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-twitter"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-pinterest"></i></Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 offset-lg-1 col-lg-4  col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <h3>contact us</h3>
                <div className="c-info">
                  <span><i className="zmdi zmdi-pin"></i></span>
                  <span>57, Tamilsangam Road, Madurai,  <br />Tamil Nadu 625001</span>
                </div>
                <div className="c-info">
                  <span><i className="zmdi zmdi-email"></i></span>
                  <span>info@moskvahotel.in<br />info@moskvahotel.in</span>
                </div>
                <div className="c-info">
                  <span><i className="zmdi zmdi-phone"></i></span>
                  <span>+91-8489914838<br />+91-452 4219063</span>
                </div>
                <div style={{display:"flex"}}>
                <div className="footer-logo">
                    <a href="https://play.google.com/store/games?hl=en&gl=US&pli=1"><img src="assets/img/moskva-photos/small/plays.png" alt="Oestin" style={{ width: "10rem" }} /></a>
                  </div>
                  <div className="footer-logo" style={{paddingLeft:"5px"}}>
                    <a href="https://www.apple.com/in/app-store/"><img src="assets/img/moskva-photos/small/play.png" alt="Oestin" style={{ width: "10rem" }} /></a>
                  </div>
                  </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <h3>quick links</h3>
                <ul className="footer-list">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/rooms">Rooms</Link></li>
                <li><Link to="/benquetmenu">Banquets Menu</Link></li>
                <li><Link to="/about">About</Link></li>
                <li><Link to="/resturants">Resturants</Link></li>
                <li><Link to="/bars">Bar</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <h3>collections</h3>
                <div className="instagram-image">
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC0962.jpg" alt=""/></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC0980.jpg" alt=""/></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC1004.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#" ><img src="assets/img/moskva-photos/small/DSC1063.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC1071.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#" ><img src="assets/img/moskva-photos/small/DSC1092.jpg" alt="" /></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='text-center'> 
        <p className="designFont">© 2023 MOSKVA All rights reserved. Designed By <a className="designFont" href="https://jofinity.com/">JOFINITY</a></p>
        </div>
      </div>
      
      
     
    </footer>
    </div>
  )
}

export default Benquetmenu