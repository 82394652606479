import react from 'react';
import logo from './hotel_logo.png';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import Header from './Header.js';

function App() {
  return (
    <div>
    <Header />
      <section className="breadcrumb-area overlay-dark-2 bg-3">	
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="breadcrumb-text text-center">
							<h2>Moskva - Room view</h2>
							<p>A quality room of Moskva with sea or mountain view</p>
							<div className="breadcrumb-bar">
								<ul className="breadcrumb">
									<li><Link to="index.html">Home</Link></li>
									<li>Moskva - Room view</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
      <section className="room-area pt-90 room-grid">
      <div className="container">
          <div className="row">
              <div className="col-md-8 mx-auto">
                  <div className="section-title text-center">
                      <h3>Our Rooms</h3>
                      <p>The Moskva Hotel, a Madurai hotel, features 44 elegantly appointed spacious rooms, including 9 Standard Rooms, 12 Deluxe Rooms, 16 Fortune Club Rooms, 3 Suites, and 5 Exclusive Club Rooms, considered the best among all the hotels in Madurai. </p>
                  </div>
              </div>
          </div>
      </div>
      <div className="container">
          <div className="row">
              <div className="col-lg-4 col-sm-6 col-12">
                  <div className="single-room">
                      <img className="fit-image" src="assets/img/moskva-photos/roomsmall/rms/DSC1012.jpg" alt="" />
                      <div className="room-hover text-center">
                          <div className="hover-text">
                              <h3><Link to="/booking">Classic / Single</Link></h3>
                              <div className="p-amount">
                                  <span>₹2100</span>
                                  <span className="count">Per Night</span>
                              </div>
                              <p>A string of amenities at this hotel cover the major aspects of a comfortable stay.</p>
                              <div className="room-btn">
                                  <Link to="/booking" className="default-btn">DETAILS</Link>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12">
                  <div className="single-room">
                      <img className="fit-image" src="assets/img/moskva-photos/roomsmall/rms/DSC1004.jpg" alt="" />
                      <div className="room-hover text-center">
                          <div className="hover-text">
                              <h3><Link to="/booking">Classic / Double</Link></h3>
                              <div className="p-amount">
                                  <span>₹2500</span>
                                  <span className="count">Per Night</span>
                              </div>
                              <p>A string of amenities at this hotel cover the major aspects of a comfortable stay.</p>
                              <div className="room-btn">
                                  <Link to="/booking" className="default-btn">DETAILS</Link>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12">
                  <div className="single-room">
                      <img className="fit-image" src="assets/img/moskva-photos/roomsmall/rms/DSC0984.jpg" alt="" />
                      <div className="room-hover text-center">
                          <div className="hover-text">
                              <h3><Link to="/continental">Continental / Single</Link></h3>
                              <p>A string of amenities at this hotel cover the major aspects of a comfortable stay.</p>
                              <div className="p-amount">
                                  <span>₹2500</span>
                                  <span className="count">Per Night</span>
                              </div>
                              <div className="room-btn">
                                  <Link to="/continental" className="default-btn">DETAILS</Link>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12">
                  <div className="single-room">
                      <img className="fit-image" src="assets/img/moskva-photos/roomsmall/rms/dl.jpg" alt="" />
                      <div className="room-hover text-center">
                          <div className="hover-text">
                              <h3><Link to="/continental">Continental / Double</Link></h3>
                              <p>A string of amenities at this hotel cover the major aspects of a comfortable stay.</p>
                              <div className="p-amount">
                                  <span>₹3000</span>
                                  <span className="count">Per Night</span>
                              </div>
                              <div className="room-btn">
                                  <Link to="/continental" className="default-btn">DETAILS</Link>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12">
                  <div className="single-room">
                      <img className="fit-image" src="assets/img/moskva-photos/roomsmall/rms/chk.jpg" alt="" />
                      <div className="room-hover text-center">
                          <div className="hover-text">
                              <h3><Link to="/deluxe">Deluxe Suit / Single</Link></h3>
                              <div className="p-amount">
                                  <span>₹3000</span>
                                  <span className="count">Per Night</span>
                              </div>
                              <p>A string of amenities at this hotel cover the major aspects of a comfortable stay.</p>
                              <div className="room-btn">
                                  <Link to="/deluxe" className="default-btn">DETAILS</Link>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12">
                  <div className="single-room">
                      <img className="fit-image" src="assets/img/moskva-photos/roomsmall/rms/DSC0979.jpg" alt="" />
                      <div className="room-hover text-center">
                          <div className="hover-text">
                              <h3><Link to="/deluxe">Deluxe Suit / Double</Link></h3>
                              <div className="p-amount">
                                  <span>₹3000</span>
                                  <span className="count">Per Night</span>
                              </div>
                              <p>A string of amenities at this hotel cover the major aspects of a comfortable stay.</p>
                              <div className="room-btn">
                                  <Link to="/deluxe" className="default-btn">DETAILS</Link>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12">
              <div className="single-room">
                  <img className="fit-image" src="assets/img/moskva-photos/roomsmall/rms/DSC1071.jpg" alt="" />
                  <div className="room-hover text-center">
                      <div className="hover-text">
                          <h3><Link to="/presidential">Presidential Suit / Single</Link></h3>
                          <div className="p-amount">
                              <span>₹6000</span>
                              <span className="count">Per Night</span>
                          </div>
                          <p>A string of amenities at this hotel cover the major aspects of a comfortable stay.</p>
                          <div className="room-btn">
                              <Link to="/presidential" className="default-btn">DETAILS</Link>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12">
          <div className="single-room">
              <img className="fit-image" src="assets/img/moskva-photos/roomsmall/rms/DSCN6881.jpg" alt="" />
              <div className="room-hover text-center">
                  <div className="hover-text">
                      <h3><Link to="/presidential">Presidential Suit / Double</Link></h3>
                      <div className="p-amount">
                          <span>₹6000</span>
                          <span className="count">Per Night</span>
                      </div>
                      <p>A string of amenities at this hotel cover the major aspects of a comfortable stay.</p>
                      <div className="room-btn">
                          <Link to="/presidential" className="default-btn">DETAILS</Link>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div className="col-lg-4 col-sm-6 col-12">
      <div className="single-room">
          <img className="fit-image" src="assets/img/moskva-photos/roomsmall/hall.jpg" alt="" />
          <div className="room-hover text-center">
              <div className="hover-text">
                  <h3><Link to="/banquets">Hall</Link></h3>
                  <div className="p-amount">
                      <span>₹2100</span>
                      <span className="count">Per Night</span>
                  </div>
                  <p>A string of amenities at this hotel cover the major aspects of a comfortable stay.</p>
                  <div className="room-btn">
                      <Link to="/banquets" className="default-btn">DETAILS</Link>
                  </div>
              </div>
          </div>
      </div>
  </div>
              
          </div>
      </div>
  </section>
  <section className="newsletter-area bg-light">
  <div className="container">
      <div className="row">
      <div className="col-xl-8 mx-auto col-12">
          <div className="newsletter-container">
          <h3>NewsLetter Sign-Up</h3>
          <div className="newsletter-form">
              <form action="#" id="mc-form" className="mc-form fix">
              <input id="mc-email" type="email" name="email" placeholder="Enter your E-mail"/>
              <button id="mc-submit" type="submit" className="default-btn">subcribes</button>
              </form>
             
              <div className="mailchimp-alerts">
              <div className="mailchimp-submitting"></div>
            
              <div className="mailchimp-success"></div>
           
              <div className="mailchimp-error"></div>
             
              </div>
            
          </div>
          </div>
      </div>
      </div>
  </div>
</section>
  


    <footer className="footer-area">
      
      <div className="footer-widget-area bg-dark">
        <div className="container">
          <div className="row mb-n60">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <div className="footer-logo">
                  <Link to="#"><img src={logo} alt="Oestin" style={{ width: "14rem" }} /></Link>
                </div>
                <p>
                Moskva Hotel is a 3-star property located 15 kilometers from Madurai Airport, 900 meters from Madurai Junction Railway Station, and one kilometer from Periyar Bus Stand.
                </p>
                <div className="social-icons">
                  <Link to="#"><i className="zmdi zmdi-facebook"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-instagram"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-rss"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-twitter"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-pinterest"></i></Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 offset-lg-1 col-lg-4  col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <h3>contact us</h3>
                <div className="c-info">
                  <span><i className="zmdi zmdi-pin"></i></span>
                  <span>57, Tamilsangam Road, Madurai,  <br />Tamil Nadu 625001</span>
                </div>
                <div className="c-info">
                  <span><i className="zmdi zmdi-email"></i></span>
                  <span>info@moskvahotel.in<br />info@moskvahotel.in</span>
                </div>
                <div className="c-info">
                  <span><i className="zmdi zmdi-phone"></i></span>
                  <span>+91-8489914838<br />+91-452 4219063</span>
                </div>
                <div style={{display:"flex"}}>
                <div className="footer-logo">
                    <a href="https://play.google.com/store/games?hl=en&gl=US&pli=1"><img src="assets/img/moskva-photos/small/plays.png" alt="Oestin" style={{ width: "10rem" }} /></a>
                  </div>
                  <div className="footer-logo" style={{paddingLeft:"5px"}}>
                    <a href="https://www.apple.com/in/app-store/"><img src="assets/img/moskva-photos/small/play.png" alt="Oestin" style={{ width: "10rem" }} /></a>
                  </div>
                  </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <h3>quick links</h3>
                <ul className="footer-list">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/rooms">Rooms</Link></li>
                <li><Link to="/benquetmenu">Banquets Menu</Link></li>
                <li><Link to="/about">About</Link></li>
                <li><Link to="/resturants">Resturants</Link></li>
                <li><Link to="/bars">Bar</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <h3>collections</h3>
                <div className="instagram-image">
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC0962.jpg" alt=""/></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC0980.jpg" alt=""/></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC1004.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#" ><img src="assets/img/moskva-photos/small/DSC1063.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC1071.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#" ><img src="assets/img/moskva-photos/small/DSC1092.jpg" alt="" /></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='text-center'> 
        <p className="designFont">© 2023 MOSKVA All rights reserved. Designed By <a className="designFont" href="https://jofinity.com/">JOFINITY</a></p>
        </div>
      </div>
      
      
     
    </footer>
    </div>
  );
}

export default App;
