import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import logo from '../../components/hotel_logo.png';
import { collection, onSnapshot, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from './../../firebase/Config';
import { useEffect,  useState} from 'react';
import { useAuthContext } from './../../hooks/useAuthContext';

const Bookhotellist = () => {
    const [views, setViews] = useState([]);
    const {user,isAuthReady} = useAuthContext()
    const usersCollectionRef = collection(db,"booking");
    useEffect(() => {
      const getUsers = async () => {
        const data = await getDocs(usersCollectionRef);
        setViews(data.docs.map((doc) => ({ ...doc.data(), id:doc.id})))
      };
      getUsers();
    },[]);
  return (
    <div>
    <header className="header-area fixed header-sticky" >
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-4 col-sm-4 col-12">
              <div className="logo">
                <Link to="#"><img src={logo} alt="Oestin" style={{ width: "21rem" }} /></Link>
              </div>
            </div>
            <div className="col-xl-7 col-lg-8 col-sm-8 col-12">
              <div className="header-top fix">
                <div className="header-contact">
                  <span className="text-theme">Contact:</span>
                  <span>+91-8489914838</span>
                </div>
                <div className="header-links">
                  <Link to="https://www.facebook.com/"><i className="zmdi zmdi-facebook"></i></Link>
                  <Link to="https://twitter.com/"><i className="zmdi zmdi-twitter"></i></Link>
                  <Link to="https://plus.google.com/"><i className="zmdi zmdi-google-plus"></i></Link>
                  <Link to="https://www.instagram.com/"><i className="zmdi zmdi-instagram"></i></Link>
                  <Link to="https://www.pinterest.com/"><i className="zmdi zmdi-pinterest"></i></Link>
                </div>
              </div>

              <div className="main-menu d-none d-lg-block">
                        <Navbar />
              </div>

            </div>
          </div>
        </div>

        <div className="mobile-menu-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="mobile-menu">
                  <nav id="dropdown">
                        <Navbar />
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

      </header>
      <section class="breadcrumb-area overlay-dark-2 bg-3">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb-text text-center">
                    <h2>Room Booking list</h2>
                    <p>A room booking menu list details in maskva</p>
                    <div class="breadcrumb-bar">
                        <ul class="breadcrumb">
                        <li><Link to="/adminpanels">PRICE</Link></li>
                        <li><Link to="/benqdataview">Booked Banquet Menu</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    <div className="container-fluid outer pt-5">
<table class="table table-dark table-bordered table-striped">
  <thead>
    <tr className="text-center">
      <th scope="col">Id</th>
      <th scope="col">Name</th>
      <th scope="col">Email</th>
      <th scope="col">Phone Number</th>
      <th scope="col">Message</th>
      <th scope="col">Expectation Time</th>
      <th scope="col">Adult</th>
      <th scope="col">Child</th>
      <th scope="col">First Age</th>
      <th scope="col">Food</th>
      <th scope="col">Bed</th>
      <th scope="col">Price</th>
      <th scope="col">CGST Tax</th>
      <th scope="col">SGST Tax</th>
      <th scope="col">Tax</th>
      <th scope="col">Discount</th>
      <th scope="col">Total Price</th>
      <th scope="col">Start Date</th>
      <th scope="col">End Date</th>
      <th scope="col">Created At</th>

      
    </tr>
  </thead>
    {
        views.map((view) => {
          {
          return (
            
          
  <tbody>
    <tr>
      
      <td>{view.userId}</td>
      <td>{view.Name}</td>
      <td>{view.Email}</td>
      <td>{view.PhoneNumber}</td>
      <td>{view.Message}</td>
      <td>{view.ExpectationTime}</td>
      <td>{view.Adult}</td>
      <td>{view.Child}</td>
      <td>{view.FirstAge}</td>
      <td>{view.Food}</td>
      <td>{view.Bed}</td>
      <td>{view.Price}</td>
      <td>{view.CgstTax}</td>
      <td>{view.SGSTTax}</td>
      <td>{view.Tax}</td>
      <td>{view.Discount}</td>
      <td>{view.TotalPrice}</td>
      <td>{view.Startsdate.toDate().toString()}</td>
      <td>{view.EndsDate.toDate().toString()}</td>
      <td>{view.createdAt.toDate().toString()}</td>
      
      

      
      
    </tr>
    
  </tbody>

            
            
            
              
           
          )
          }
        })
       }
       </table>
       </div>
    </div>
  )
}

export default Bookhotellist