import React,{ useState, useEffect } from 'react';
import { BiRupee } from 'react-icons/bi';
import { useFirestore } from './../../hooks/useFirestore';
import { useLocation, useNavigate } from "react-router-dom";

const Continentview = ({disp}) => {
  const [paymentid, setPaymentid] = useState("");
  const [modifiedField,setModifiedField] = useState({})
  //  console.log(cview.TotalPrice);
    //var c = new Date().toLocaleDateString();
    var c = disp.Startsdate.toDate().toString();
   var b = disp.EndsDate.toDate().toString();
   // console.log(b);
   const location = useLocation();
   const navigate = useNavigate();
 
   const { state: post } = location;
   const {updateDocument,error} = useFirestore('booking')
//console.log(c);
var roomcount;
    if(disp.Adult <= 3){
      roomcount = 1;
    }
    else if(disp.Adult < 7)
    {
      roomcount = 2;
    }
    else if(disp.Adult < 10)
    {
      roomcount = 3;
    }
    else if(disp.Adult === 10)
    {
      roomcount = 4;
    }

    const handleSubmit = (e)=>{
        e.preventDefault();
        updateDocument(disp.id,modifiedField)
        //navigate('/presidentialbookshow')
       // console.log(suite.FirstAge);
        const answer = window.confirm(paymentid);
        if (paymentid) {
          // Save it!
          console.log("Thing was saved to the database.");
          alert("Thank You. Your Room Booked Successfully");
          navigate('/')
        } else {
          // Do nothing!
          console.log("Thing was not saved to the database.");
          alert("Sorry Your Room wasn`t Booked");
        }
      }
      useEffect(() => {
        setPaymentid(paymentid)
        
        
      }, [paymentid]);
    
    //console.log(post.RoomName);
      const onTitleChange = (e) => {
        setPaymentid(e.target.value)
        setModifiedField({...modifiedField,paymentid:e.target.value})
       
    }

  return (
    <div><section className="pt-5 ">
    <div className="container ">
    <div className="row">
        <div className="col-md-10 mx-auto col-12 card shadow-lg border-0 p-4">
        <div className="row">
        <div>
        <h1 className="display-4 section-title text-center labelHead">Confirmation Details of Booking</h1>
      </div> 
            <div className="col-md-6 col-12 my-auto">
            
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th>Room Type</th>
                    <td className="labelHead">{disp.RoomType} </td>
                  </tr>
                  <tr>
                    <th>Name</th>
                    <td>{disp.Name} </td>
                  </tr>
                  
                  <tr>
                    <th>Email</th>
                    <td>{disp.Email} </td>
                  </tr>
                  <tr>
                    <th>Phone Number</th>
                    <td>{disp.PhoneNumber} </td>
                  </tr>
                  <tr>
                    <th>Location</th>
                    <td>{disp.Message} </td>
                  </tr>
                  <tr>
                    <th>Expectation Time</th>
                    <td>{disp.ExpectationTime} </td>
                  </tr>
                  <tr>
                    <th>Adults</th>
                    <td>{disp.Adult} </td>
                  </tr>
                  <tr>
                    <th>Child</th>
                    <td>{disp.Child} </td>
                  </tr>
                  <tr>
                  <th>Child Age</th>
                  <td>{disp.FirstAge} </td>
                </tr>
                <tr>
                    <th>Check In Date</th>
                    <td> {c}</td>
                  </tr>
                  <tr>
                    <th>Check Out Date</th>
                    <td> {b}</td>
                  </tr>
                  <tr>
                    <th>Duration Days</th>
                    <td>{disp.DurationTime} </td>
                  </tr>
                  <tr>
                    <th>Food</th>
                    <td>{disp.Food} </td>
                  </tr>
                  <tr>
                    <th>Bed</th>
                    <td>{disp.Bed} </td>
                  </tr>
                  <tr>
                    <th>Selected Rooms</th>
                    <td>You Have Choosed the <strong> {roomcount} </strong>Rooms</td>
                  </tr>
                  

                 





                </thead>
              </table>
            </div>
            <div className="col-md-6 col-12 author-name PriceShown">
            <h6 className="font-weight-bold pt-3">Price  : <span className="labelPrice badge badge-info text-black"><BiRupee /> {disp.Price}</span></h6>
            <h6 className="font-weight-bold ">Taxes - 12%: <span className="labelPrice badge badge-info text-black"><BiRupee /> {disp.Tax}</span></h6>
            <h6 className="font-weight-bold ">CGST - 6%: <span className="labelPrice badge badge-info text-black"><BiRupee /> {disp.CgstTax}</span></h6>
            <h6 className="font-weight-bold ">SGST - 6%: <span className="labelPrice badge badge-info text-black"><BiRupee /> {disp.SGSTTax}</span></h6>
            <h6 className="font-weight-bold ">DiscountPrice : <span className="labelPrice badge badge-info text-black"><BiRupee /> {disp.Discount}</span></h6>
            <h6 className="font-weight-bold pt-3 ">Total Price to be paid : <span className="labelPrice badge badge-info text-black"><BiRupee /> {disp.TotalPrice}</span></h6>
            <div className="row payMark">
                  
                  <div className="col-md-12 ">
                  <h6 className="font-weight-bold pt-3 "><span className="labelPrice badge badge-info text-black" style={{ whiteSpace: "normal" }}>MADASAMY ATHIMOOLAM</span></h6>
                  <h6 className="font-weight-bold pt-3 "><span className="labelPrice badge badge-info text-black" style={{ whiteSpace: "normal" }}>UNION BANK OF INDIA 1553</span></h6>
                  <h6 className="font-weight-bold pt-3 "><span className="labelPrice badge badge-info text-black" style={{ whiteSpace: "normal" }}>Please Scan the QR code to pay on online mode.</span></h6>
                    <img src="assets/img/moskva-photos/paycode.jpeg" className="" alt="selected room" style={{ width: "50%", height: "15rem" }} />
                    <h6 className="font-weight-bold pt-3 "><span className="labelPrice badge badge-info text-black" style={{ whiteSpace: "normal" }}>UPI ID: mathimoolam@okicici</span></h6>
                  </div>
                </div>

                <div className='row pt-3 pb-3'>

                  <div class="col-lg-3 pt-2"> <label htmlFor="Fromdate" className="font-weight-bolder labelHead">Payment ID </label></div>

                  <div class="col-lg-9">
                  <input
                  type="text"
                  className="form-control"
                  value={paymentid}
                  onChange={onTitleChange}
                />
                    <h6 className="font-weight-bold pt-3 "><span className="text-black">Please Enter the payment ID After pay the payment throught the QR code.</span></h6>
                  </div>
                </div>
            
            </div>
            
          </div>
          
          <div className="row pt-5">
          <div className="col-md-6 col-12 author-name">
          <div class="">
          <h6 class="labelHead">Description</h6>
          <p className='pt-2 labelHead'>Please make sure Booking time is from 12 pm to 12 pm, Early Check in to be 750rs will be applicable</p>
          <p className='pt-2 labelHead'></p>

        </div>
          </div>
          <div className="col-md-6 col-12 my-auto">
              
          </div>
          
        </div>
        <div className="row my-4">
            <div className="col-md-6 col-12">
              <div className="form-group">

              </div>
            </div>
            <div className="col-md-6 col-12 my-auto">
              <div className="col-md-6 col-12 float-right">
                <button className="btn btn-white search default-btn text-center" onClick={handleSubmit} data-toggle="modal" data-target="#thanks">Confirm Payment</button>
              </div>
            </div>
          </div>
        </div>
    </div>
    </div>
    </section></div>
  )
}

export default Continentview