import React from 'react';
import { Link } from 'react-router-dom';
//import Appsubmitbutton from "./components/appsubmitbutton/Appsubmitbutton";
import Appsubmitbutton from "./../components/appsubmitbutton/Appsubmitbutton";
import { useAuthentication } from './../hooks/useAuthentication';
import { useAuthContext } from './../hooks/useAuthContext';

const Navbar = () => {
  const {logout} = useAuthentication()

  const {user} = useAuthContext()

  const handleLogout = () => {
    logout()
  }

  return (
    <div>
    <nav className="navbar navbar-expand-md navbar-dark">
    <Link className="navbar-brand headMenu" to="#"> MENU</Link>
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="collapsibleNavbar">
    <ul className="navbar-nav">
    <li><Link to="/">HOME</Link></li>
    <li><Link to="/rooms">ROOMS</Link></li>
    <li><Link to="/banquets" className="dropdown-toggle" data-toggle="dropdown">BANQUETS</Link>
    <div className="dropdown-menu dropManu">
        <Link className="dropdown-item" to="/benquetmenu">BANQUETS MENU</Link>
      </div>
    </li>
    <li> <Link to="/resturants">RESTURANTS</Link></li>
    <li> <Link to="/bars">BAR</Link></li>
    <li><Link to="/about">ABOUT</Link></li>
    <li><Link to="/gallery">GALLERY</Link></li>
    <li className='menuspace'><Link to="/panaroma">360-VIEWS</Link></li>
    <li>{!user && <Link to="/login">
    <p>LOGIN</p>
  </Link>}</li>
    <li>  {!user && <Link to="/signup">
    <p>SIGNUP</p>
  </Link>}</li>
  {user && 
  <li><Link to="#" className="dropdown-toggle usersshow" data-toggle="dropdown">{user.email}</Link>
    <div className="dropdown-menu dropManu">
        <Link className="dropdown-item" to={(user.uid === "xxlHP783TtRg1NE0Tx88wcMzT6v2") ? "/userdetails" : "/userviews"}>Booking List</Link>
        <li > <Appsubmitbutton title="LOGOUT" onClick={handleLogout} /></li>
      </div>
    </li>}
   
    
  </ul>
    </div>  
  </nav>
    </div>
  )
}

export default Navbar