import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import logo from '../../components/hotel_logo.png';
import { collection, onSnapshot, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from './../../firebase/Config';
import { useEffect,  useState} from 'react';

import { useAuthContext } from './../../hooks/useAuthContext';
//import Bookingform from './Bookingform';
import Benqdata from './Benqdata';

const Benqdataview = () => {
    const [views, setViews] = useState([]);
    const {user,isAuthReady} = useAuthContext()
    const usersCollectionRef = collection(db,"menus");
    const queryRef = query(usersCollectionRef, orderBy("createdAt", "desc"))
    useEffect(() => {
      const getUsers = async () => {
        const data = await getDocs(queryRef);
        setViews(data.docs.map((doc) => ({ ...doc.data(), id:doc.id})))
      };
      getUsers();
    },[]);


  return (
    <div>
    <header className="header-area fixed header-sticky" >
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-4 col-sm-4 col-12">
              <div className="logo">
                <Link to="#"><img src={logo} alt="Oestin" style={{ width: "21rem" }} /></Link>
              </div>
            </div>
            <div className="col-xl-7 col-lg-8 col-sm-8 col-12">
              <div className="header-top fix">
                <div className="header-contact">
                  <span className="text-theme">Contact:</span>
                  <span>+91-8489914838</span>
                </div>
                <div className="header-links">
                  <Link to="https://www.facebook.com/"><i className="zmdi zmdi-facebook"></i></Link>
                  <Link to="https://twitter.com/"><i className="zmdi zmdi-twitter"></i></Link>
                  <Link to="https://plus.google.com/"><i className="zmdi zmdi-google-plus"></i></Link>
                  <Link to="https://www.instagram.com/"><i className="zmdi zmdi-instagram"></i></Link>
                  <Link to="https://www.pinterest.com/"><i className="zmdi zmdi-pinterest"></i></Link>
                </div>
              </div>

              <div className="main-menu d-none d-lg-block">
                        <Navbar />
              </div>

            </div>
          </div>
        </div>

        <div className="mobile-menu-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="mobile-menu">
                  <nav id="dropdown">
                        <Navbar />
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

      </header>
      <section class="breadcrumb-area overlay-dark-2 bg-3">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb-text text-center">
                    <h2>Benquet Booking Menu list</h2>
                    <p>A benquet booking menu list details in maskva</p>
                    <div class="breadcrumb-bar">
                        <ul class="breadcrumb">
                        <li><Link to="/adminpanels">PRICE</Link></li>
                        <li><Link to="/userdetails">Room Booking List</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div className="container-fluid outer pt-5 table-responsive">
<table class="table table-dark table-bordered table-striped">
  <thead>
    <tr className="text-center">
      <th scope="col">Id</th>
      <th scope="col">Name</th>
      <th scope="col">Email</th>
      <th scope="col">Phone Number</th>
      <th scope="col">Soup</th>
      <th scope="col">VEGETARIAN-GRAVY</th>
      <th scope="col">SALAD</th>
      <th scope="col">SALAD CHAT</th>
      <th scope="col">INDIAN BREADS</th>
      <th scope="col">INDIAN BREADS SPECIAL</th>
      <th scope="col">DESSERT`S</th>
      <th scope="col">DESSERT`S SPECIAL</th>
      <th scope="col">PULAVO/BIRIYANI</th>
      <th scope="col">DHAL</th>
      <th scope="col">VARIETY RICE</th>
      <th scope="col">VEGETARIAN-DRY</th>
    </tr>
  </thead>
    {
        views.map((view) => {
          {
          return (
            
          
  <tbody>
    <tr>
      
      <td>{view.userId}</td>
      <td>{view.name}</td>
      <td>{view.email}</td>
      <td>{view.phonenumber}</td>
      <td>{view.Fruit}</td>
      <td>{view.Color}</td>
      <td>{view.Salad}</td>
      <td>{view.Chat}</td>
      <td>{view.Naan}</td>
      <td>{view.Bread}</td>
      <td>{view.Halwa}</td>
      <td>{view.Ice}</td>
      <td>{view.Pulavo}</td>
      <td>{view.Dhal}</td>
      <td>{view.Rice}</td>
      <td>{view.Dry}</td>
      
    </tr>
    
  </tbody>

            
            
            
              
           
          )
          }
        })
       }
       </table>
       </div>
       <footer className="footer-area pt-5 mt-5">
      
      <div className="footer-widget-area bg-dark">
        <div className="container">
          <div className="row mb-n60">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <div className="footer-logo">
                  <Link to="#"><img src={logo} alt="Oestin"  style={{ width: "14rem" }} /></Link>
                </div>
                <p>
                Moskva Hotel is a 3-star property located 15 kilometers from Madurai Airport, 900 meters from Madurai Junction Railway Station, and one kilometer from Periyar Bus Stand.
                </p>
                <div className="social-icons">
                  <Link to="#"><i className="zmdi zmdi-facebook"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-instagram"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-rss"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-twitter"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-pinterest"></i></Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 offset-lg-1 col-lg-4  col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <h3>contact us</h3>
                <div className="c-info">
                  <span><i className="zmdi zmdi-pin"></i></span>
                  <span>57, Tamilsangam Road, Madurai,  <br />Tamil Nadu 625001</span>
                </div>
                <div className="c-info">
                  <span><i className="zmdi zmdi-email"></i></span>
                  <span>info@moskvahotel.in<br />info@moskvahotel.in</span>
                </div>
                <div className="c-info">
                  <span><i className="zmdi zmdi-phone"></i></span>
                  <span>+91-8489914838<br />+91-452 4219063</span>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <h3>quick links</h3>
                <ul className="footer-list">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/rooms">Rooms</Link></li>
                <li><Link to="/benquetmenu">Banquets Menu</Link></li>
                <li><Link to="/about">About</Link></li>
                <li><Link to="/resturants">Resturants</Link></li>
                <li><Link to="/bars">Bar</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <h3>collections</h3>
                <div className="instagram-image">
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC0962.jpg" alt=""/></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC0980.jpg" alt=""/></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC1004.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#" ><img src="assets/img/moskva-photos/small/DSC1063.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC1071.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#" ><img src="assets/img/moskva-photos/small/DSC1092.jpg" alt="" /></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      
     
    </footer>
    </div>
  )
}

export default Benqdataview