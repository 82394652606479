import React,{ useState }  from 'react';
//import './App.css';
//import Slider from './Carousel.js';
import logo from './hotel_logo.png';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';

const Header = () => {
    const [colorChange, setColorchange] = useState(false);
    const changeNavbarColor = () =>{
       if(window.scrollY >= 200){
         setColorchange(true);
       }
       else{
         setColorchange(false);
       }
    };
    console.log(window.scrollY );
    window.addEventListener('scroll', changeNavbarColor);
  return (
    <div>
    <header className={colorChange ? 'header-area active fixed header-sticky' : 'header-area fixed header-sticky'} >
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-4 col-sm-4 col-12">
              <div className={colorChange ? 'logo logoTop' : 'logo'} >
                <Link to="#"><img src={logo} alt="Oestin" style={{ width: "21rem" }} /></Link>
              </div>
            </div>
            <div className="col-xl-7 col-lg-8 col-sm-8 col-12">
              <div className={colorChange ? 'header-top fix headTopHide' : 'header-top fix'} >
                <div className="header-contact">
                  <span className="text-theme">Contact:</span>
                  <span>+91-452 4219063</span>
                </div>
                <div className="header-links">
                  <Link to="https://www.facebook.com/"><i className="zmdi zmdi-facebook"></i></Link>
                  <Link to="https://twitter.com/"><i className="zmdi zmdi-twitter"></i></Link>
                  <Link to="https://plus.google.com/"><i className="zmdi zmdi-google-plus"></i></Link>
                  <Link to="https://www.instagram.com/"><i className="zmdi zmdi-instagram"></i></Link>
                  <Link to="https://www.pinterest.com/"><i className="zmdi zmdi-pinterest"></i></Link>
                </div>
              </div>

              <div className="main-menu d-none d-lg-block">
                        <Navbar />
              </div>

            </div>
          </div>
        </div>

        <div className="mobile-menu-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="mobile-menu">
                  <nav id="dropdown">
                        <Navbar />
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

      </header>
    </div>
  )
}

export default Header