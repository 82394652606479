import React from "react";
//import "./Postdetail.css";
import { useLocation, useNavigate } from "react-router-dom";
//import { useFetch } from "./../../hooks/useFetch";
import Appsubmitbutton from "../../components/appsubmitbutton/Appsubmitbutton";
//import { useFirestore } from "./../../hooks/useFirestore";
//import { useAuthContext } from "./../../hooks/useAuthContext";
import logo from "../../components/hotel_logo.png";
import Navbar from '../../components/Navbar';
import { Link } from 'react-router-dom';

export default function Postdetail() {
  const location = useLocation();

  const { state: post } = location;

  //const { user } = useAuthContext();

  //const { deleteDocument } = useFirestore("posts");

  //const { data } = []

  const navigate = useNavigate();

  const handleEdit = () => {
    navigate(`/edit/${post.id}`, { state: post });
  };

  // const handleDelete = () => {
  //   deleteDocument(post.id);
  //   navigate("/");
  // };
//console.log(post.single);
  return (
    <div>
    <header className="header-area fixed header-sticky" >
    <div className="container">
      <div className="row">
        <div className="col-xl-5 col-lg-4 col-sm-4 col-12">
          <div className="logo">
            <Link to="#"><img src={logo} alt="Oestin" style={{ width: "21rem" }} /></Link>
          </div>
        </div>
        <div className="col-xl-7 col-lg-8 col-sm-8 col-12">
          <div className="header-top fix">
            <div className="header-contact">
              <span className="text-theme">Contact:</span>
              <span>0123456789</span>
            </div>
            <div className="header-links">
              <Link to="https://www.facebook.com/"><i className="zmdi zmdi-facebook"></i></Link>
              <Link to="https://twitter.com/"><i className="zmdi zmdi-twitter"></i></Link>
              <Link to="https://plus.google.com/"><i className="zmdi zmdi-google-plus"></i></Link>
              <Link to="https://www.instagram.com/"><i className="zmdi zmdi-instagram"></i></Link>
              <Link to="https://www.pinterest.com/"><i className="zmdi zmdi-pinterest"></i></Link>
            </div>
          </div>

          <div className="main-menu d-none d-lg-block">
            <Navbar />
          </div>

        </div>
      </div>
    </div>

    <div className="mobile-menu-area">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="mobile-menu">
              <nav id="dropdown">
                <Navbar />
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>

  </header>
  <section class="breadcrumb-area overlay-dark-2 bg-3">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb-text text-center">
                    <h2>Price View</h2>
                    
                    <div class="breadcrumb-bar">
                        <ul class="breadcrumb">
                            <li><Link to="/">Home</Link></li>
                            <li>Room Price View</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    <div className="container outer pt-5">
      <div className="jumbotron">
        
        <div className="row">
        <div class="card" >
  
    <div class="card-body">
      <h5 class="card-title">{post.RoomName}</h5>
      
    </div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item"><span>Price</span> - <span>{post.Price}</span></li>
      <li class="list-group-item"><span>Double</span> - <span>{post.Double}</span></li>
      <li class="list-group-item"><span>Third Person Price</span> - <span>{post.Third}</span></li>
      <li class="list-group-item"><span>Food Price</span> - <span>{post.Food}</span></li>
      <li class="list-group-item"><span>Discount Price</span> - <span>{post.Discount}</span></li>
    </ul>
    
    
  </div>
      </div>
        {/* {data.length !== 0 && (
          <div className="alert alert-success" role="alert">
            Post Deleted Successfully!
          </div>
        )} */}

        {(post.userId === post.userId) ? <>
          
            <div className="float-end">
            <Appsubmitbutton onClick={handleEdit} title="Edit" />
          </div>
        </> : <> </>}
      </div>
    </div>
    </div>
  );
}
