import React from 'react';
import logo from './hotel_logo.png';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import  barImage from './bar.jpg';
import Header from './Header.js';

const Bars = () => {
  return (
    <div>
    <Header />
<section className="breadcrumb-area overlay-dark-2 bg-3">	
      <div className="container">
          <div className="row">
              <div className="col-12">
                  <div className="breadcrumb-text text-center">
                      <h2>Moskva - BANQUETS </h2>
                      <p>Booking the halls of Moskva.</p>
                      <div className="breadcrumb-bar">
                          <ul className="breadcrumb">
                              <li><Link to="index.html">Home</Link></li>
                              <li>Moskva - Banquets</li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
  
  <section class="team-area pt-90 team-section">
        <div class="container">
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div className="section-title text-center">
                <h3>BONQUETS</h3>
                <p className="pb-10">The Moskva Hotel features a business centre as well as a meeting and banquet space. Laundry and currency exchange facilities are available. </p>
              </div>
            </div>
          </div>
          <div class="row">
          
          <div class="col-md-12">
          <div class="card flex-md-row mb-4 shadow-sm h-md-250 carder">
             <div class="card-body d-flex flex-column pt-5">
                
                <h1 class="mb-0 text-center">
                   <a class="text-dark " href="#">ZVEZDNAYA</a>
                </h1>
                
                <div className="address-distance fix text-center">
                    <ul class="list-group resturantList text-center banqRest cardList">
                      <li class="list-group-item ">Theater style150-200</li>
                      <li class="list-group-item ">Classroom80</li>
                      <li class="list-group-item">Cluster80</li>
                      <li class="list-group-item ">Causal150</li>
                      <li class="list-group-item ">U Shape60</li>
                      <li class="list-group-item ">Board Room60</li>

                    </ul>
                  </div>
                  <div className="pt-5 text-center room-btn">
                  <Link to="/rooms" className="default-btn btn">DETAILS</Link>
                  </div>
                
             </div>
             <img class="card-img-right flex-auto cardImage" alt="Thumbnail [200x250]" src="assets/img/moskva-photos/gallery/mask1.jpg"  />
          </div>
       </div>
       <div class="col-md-12">
          <div class="card flex-md-row mb-4 shadow-sm h-md-250 carder">
          <img class="card-img-right flex-auto cardImage" alt="Thumbnail [200x250]" src="assets/img/moskva-photos/gallery/mask2.jpg"  />
             <div class="card-body d-flex flex-column pt-5">
                
                <h1 class="mb-0 text-center">
                   <a class="text-dark " href="#">KRASNAYA</a>
                </h1>
                
                <div className="address-distance fix text-center">
                    <ul class="list-group resturantList text-center banqRest cardList">
                      <li class="list-group-item ">Theater style-</li>
                      <li class="list-group-item ">Classroom-25</li>
                      <li class="list-group-item">Cluster-30</li>
                      <li class="list-group-item ">Causal-40</li>
                      <li class="list-group-item ">U Shape-25</li>
                      <li class="list-group-item ">Board Room-25</li>

                    </ul>
                  </div>
                  <div className="pt-5 text-center room-btn">
                  <Link to="/rooms" className="default-btn btn">DETAILS</Link>
                  </div>
                
             </div>
             
          </div>
       </div>
       <div class="col-md-12">
          <div class="card flex-md-row mb-4 shadow-sm h-md-250 carder">
             <div class="card-body d-flex flex-column pt-5">
                
                <h1 class="mb-0 text-center">
                   <a class="text-dark " href="#">OKTABRSKY</a>
                </h1>
                
                <div className="address-distance fix text-center">
                    <ul class="list-group resturantList text-center banqRest cardList">
                      <li class="list-group-item ">Theater style-70</li>
                      <li class="list-group-item ">Classroom-30</li>
                      <li class="list-group-item">Cluster-40</li>
                      <li class="list-group-item ">Causal-70</li>
                      <li class="list-group-item ">U Shape-30</li>
                      <li class="list-group-item ">Board Room-30</li>

                    </ul>
                  </div>
                  <div className="pt-5 text-center room-btn">
                  <Link to="/rooms" className="default-btn btn">DETAILS</Link>
                  </div>
                
             </div>
             <img class="card-img-right flex-auto cardImage" alt="Thumbnail [200x250]" src="assets/img/moskva-photos/gallery/mask3.jpg"  />
          </div>
       </div>
       <div class="col-md-12">
          <div class="card flex-md-row mb-4 shadow-sm h-md-250 carder">
          <img class="card-img-right flex-auto cardImage" alt="Thumbnail [200x250]" src="assets/img/moskva-photos/gallery/mask4.jpg"  />
             <div class="card-body d-flex flex-column pt-5">
                
                <h1 class="mb-0 text-center">
                   <a class="text-dark " href="#">KOMSOMOLSKY</a>
                </h1>
                
                <div className="address-distance fix text-center">
                    <ul class="list-group resturantList text-center banqRest cardList">
                      <li class="list-group-item ">Theater style-50</li>
                      <li class="list-group-item ">Classroom-20</li>
                      <li class="list-group-item">Cluster-30</li>
                      <li class="list-group-item ">Causal-50</li>
                      <li class="list-group-item ">U Shape-20</li>
                      <li class="list-group-item ">Board Room-20</li>

                    </ul>
                  </div>
                  <div className="pt-5 text-center room-btn">
                  <Link to="/rooms" className="default-btn btn">DETAILS</Link>
                  </div>
                
             </div>
             
          </div>
       </div>
       
          </div>
         
        </div>
      </section>


<footer className="footer-area pt-5">
      
      <div className="footer-widget-area bg-dark">
        <div className="container">
          <div className="row mb-n60">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <div className="footer-logo">
                  <Link to="#"><img src={logo} alt="Oestin" style={{ width: "14rem" }} /></Link>
                </div>
                <p>
                Moskva Hotel is a 3-star property located 15 kilometers from Madurai Airport, 900 meters from Madurai Junction Railway Station, and one kilometer from Periyar Bus Stand.
                </p>
                <div className="social-icons">
                  <Link to="#"><i className="zmdi zmdi-facebook"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-instagram"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-rss"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-twitter"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-pinterest"></i></Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 offset-lg-1 col-lg-4  col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <h3>contact us</h3>
                <div className="c-info">
                  <span><i className="zmdi zmdi-pin"></i></span>
                  <span>57, Tamilsangam Road, Madurai,  <br />Tamil Nadu 625001</span>
                </div>
                <div className="c-info">
                  <span><i className="zmdi zmdi-email"></i></span>
                  <span>info@moskvahotel.in<br />info@moskvahotel.in</span>
                </div>
                <div className="c-info">
                  <span><i className="zmdi zmdi-phone"></i></span>
                  <span>+91-8489914838<br />+91-452 4219063</span>
                </div>
                <div style={{display:"flex"}}>
                <div className="footer-logo">
                    <a href="https://play.google.com/store/games?hl=en&gl=US&pli=1"><img src="assets/img/moskva-photos/small/plays.png" alt="Oestin" style={{ width: "10rem" }} /></a>
                  </div>
                  <div className="footer-logo" style={{paddingLeft:"5px"}}>
                    <a href="https://www.apple.com/in/app-store/"><img src="assets/img/moskva-photos/small/play.png" alt="Oestin" style={{ width: "10rem" }} /></a>
                  </div>
                  </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <h3>quick links</h3>
                <ul className="footer-list">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/rooms">Rooms</Link></li>
                <li><Link to="/benquetmenu">Banquets Menu</Link></li>
                <li><Link to="/about">About</Link></li>
                <li><Link to="/resturants">Resturants</Link></li>
                <li><Link to="/bars">Bar</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <h3>collections</h3>
                <div className="instagram-image">
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC0962.jpg" alt=""/></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC0980.jpg" alt=""/></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC1004.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#" ><img src="assets/img/moskva-photos/small/DSC1063.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC1071.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#" ><img src="assets/img/moskva-photos/small/DSC1092.jpg" alt="" /></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='text-center'> 
        <p className="designFont">© 2023 MOSKVA All rights reserved. Designed By <a className="designFont" href="https://jofinity.com/">JOFINITY</a></p>
        </div>
      </div>
      
      
     
    </footer>
    </div>
  )
}

export default Bars