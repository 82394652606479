import React from 'react'
import { collection, onSnapshot, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from './../../firebase/Config';
import { useEffect,  useState} from 'react';
import Deluxebookdetail from './Deluxebookdetail';

const Deluxebooking = () => {
    const [users, setUsers] = useState([]);
    const usersCollectionRef = collection(db,"posts");
    useEffect(() => {
      const getUsers = async () => {
        const data = await getDocs(usersCollectionRef);
        setUsers(data.docs.map((doc) => ({ ...doc.data(), id:doc.id})))
      };
      getUsers();
    },[]);
  return (
    <div>
    {
        users.map((user) => {
          { if (user.id === "TOsrcPqsjBi4LjekATrd") {
          return (
              <Deluxebookdetail post={user} key={user.id} /> 
           
          )
          }}
        })
       }
    </div>
  )
}

export default Deluxebooking