import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ChildSelection = () => {
    const [isVisible, setIsVisible] = useState(false);
    // const [isShow, setIsShow] = useState(false);
    const [child, setChild] = useState(0);
    const [firstage, setFirstage] = useState(0);
    const [secondage, setSecondage] = useState(0);
    const [thirdage, setThirdage] = useState(0);
    const [fourage, setFourage] = useState(0);
    const [fiveage, setFiveage] = useState(0);
    const [secondVisible, setSecondVisible] = useState(false);
    const [thirdVisible, setThirdVisible] = useState(false);
    const [fourVisible, setFourVisible] = useState(false);
    const [fiveVisible, setFiveVisible] = useState(false);
    const [aboveChild, setAboveChild] = useState(false);
    const [belowChild, setBelowChild] = useState(false);

    const childChoose = () => {
        setChild(child + 1);
        if (child === 0) {
            setIsVisible(true);
            setBelowChild(false);
        } else if (child === 1) {
            setSecondVisible(true);
        }
        else if (child === 2) {
            setThirdVisible(true);
        }
        else if (child === 3) {
            setFourVisible(true);
        }
        else if (child === 4) {
            setFiveVisible(true);
        }
        else if (child === 5) {
            //alert('Not Allowd to above five child on per room');
            toast("Not Allowd to above five child on per room", { 
                position: "top-center",
                theme:"dark"
               });
            setAboveChild(true);
            setChild(child - 0);
        }
        
    }
    const childReduce = () => {
        setChild(child - 1);
        if (child === 1) {
            setIsVisible(false);
        } else if (child === 2) {
            setSecondVisible(false);
        }
        else if (child === 3) {
            setThirdVisible(false);
        }
        else if (child === 4) {
            setFourVisible(false);
        }
        else if (child === 5) {
            setFiveVisible(false);
            //alert('Not Allowd to above five child on per room');
            setAboveChild(false);
            setChild(child - 1);
        }
        else if (child >= 0) {
          //  alert('No Response- Please Refresh the page');
            setBelowChild(true);
            setChild(0);
        }
    }
    const FirstChildAgePlus = () => {
        setFirstage(firstage + 1);
        if(firstage >= 6 ){
           toast("Charges Will be applicable for above six years!", { 
            position: "top-center",
            theme:"dark"
           });
            setFirstage(firstage + 0);
        }

    }
    const FirstChildAgeReduce = () => {
        setFirstage(firstage - 1);

    }
    const SecondChildAgePlus = () => {
        setSecondage(secondage + 1);

    }
    const SecondChildAgeReduce = () => {
        setSecondage(secondage - 1);

    }
    const ThirdChildAgePlus = () => {
        setThirdage(thirdage + 1);

    }
    const ThirdChildAgeReduce = () => {
        setThirdage(thirdage - 1);

    }
    const FourChildAgePlus = () => {
        setFourage(fourage + 1);

    }
    const FourChildAgeReduce = () => {
        setFourage(fourage - 1);

    }
    const FiveChildAgePlus = () => {
        setFiveage(fiveage + 1);

    }
    const FiveChildAgeReduce = () => {
        setFiveage(fiveage - 1);

    }
    

    return (
        <div>
            <div className=''>
                <button className="btn btnAddColor" disabled={aboveChild} onClick={childChoose}>+</button> {child}  <button disabled={belowChild} className="btn btnAddColor" onClick={childReduce} >-</button>
            </div>
            <div className='row'>
                <div className='col-md-4 col-12'>
                    {isVisible ? (
                        <div class="pt-3 pb-3">
                            <label htmlFor="Todate" className="font-weight-bolder mr-3 labelHead">Age of Selected First Child </label>
                            <div className=''>
                                <button className="btn btnAddColor" onClick={FirstChildAgePlus}>+</button> {firstage}  <button className="btn btnAddColor" onClick={FirstChildAgeReduce} >-</button>
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className='col-md-4 col-12'>{secondVisible ? (
                    <div class="pt-3 pb-3">
                        <label htmlFor="Todate" className="font-weight-bolder mr-3 labelHead">Age of Selected Second Child </label>
                        <div className=''>
                            <button className="btn btnAddColor" onClick={SecondChildAgePlus}>+</button> {secondage}  <button className="btn btnAddColor" onClick={SecondChildAgeReduce} >-</button>
                        </div>
                    </div>
                ) : null} </div>
                <div className='col-md-4 col-12'>
                    {thirdVisible ? (
                        <div class="pt-3 pb-3">
                            <label htmlFor="Todate" className="font-weight-bolder mr-3 labelHead">Age of Selected Third Child </label>
                            <div className=''>
                                <button className="btn btnAddColor" onClick={ThirdChildAgePlus}>+</button> {thirdage}  <button className="btn btnAddColor" onClick={ThirdChildAgeReduce} >-</button>
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className='col-md-4 col-12'>
                    {fourVisible ? (
                        <div class="pt-3 pb-3">
                            <label htmlFor="Todate" className="font-weight-bolder mr-3 labelHead">Age of Selected Fourth Child </label>
                            <div className=''>
                                <button className="btn btnAddColor" onClick={FourChildAgePlus}>+</button> {fourage}  <button className="btn btnAddColor" onClick={FourChildAgeReduce} >-</button>
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className='col-md-4 col-12'>

                    {fiveVisible ? (
                        <div class="pt-3 pb-3">
                            <label htmlFor="Todate" className="font-weight-bolder mr-3 labelHead">Age of Selected Fivth Child </label>
                            <div className=''>
                                <button className="btn btnAddColor" onClick={FiveChildAgePlus}>+</button> {fiveage}  <button className="btn btnAddColor" onClick={FiveChildAgeReduce} >-</button>
                            </div>
                        </div>
                    ) : null}
                </div>
                <ToastContainer />
            </div>


        </div>
    )
}

export default ChildSelection