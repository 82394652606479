import React,{useEffect} from "react";
import Post from "../../components/post/Post";
import "./Home.css";
import { useFetchCollection } from './../../hooks/useFetchCollection';
//import { useThemeContext } from './../../hooks/useThemeContext';
import { Link } from 'react-router-dom';

export default function Home() {

  const {documents : posts} = useFetchCollection("posts")

  //const {theme} = useThemeContext()

 

 // console.log(posts);
  return (
    <div>
    <section className="breadcrumb-area overlay-dark-2 bg-3">
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="breadcrumb-text text-center">
                    <h2>ADMIN PANEL</h2>
                    <p>ADMIN MAINTAIN THE DATA FOR MASKVA</p>
                    <div className="breadcrumb-bar">
                        <ul className="breadcrumb">
                            
                            <li><Link to="/benqdataview">Booked Banquet Menu</Link></li>
                            <li><Link to="/userdetails">Booking List</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    <div className="container outer pt-5 mt-5">
    <h1 className="text-center">HOTEL ROOM PRICE</h1>
      {posts &&
        posts.map((post) => {
          return <Post post={post} key={post.id} />;
        })}
    </div>
    </div>
  );
}
