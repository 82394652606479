// import logo from './logo.svg';
import './App.css';
//import Slider from './Carousel.js';
//import logo from './masko.jpeg';
//import background from ".public/assets/img/slider/1.jpg";
import Home from './components/Home';
import Rooms from './components/Rooms';
import About from './components/About';
import Resturants from './components/Resturants';
import Gallery from './components/Gallery';
import Panaroma from './components/panaroma';
import Booking from './components/Booking';
import Booknow from './components/Booknow';
import Bars from './components/Bars.js';
import Banquets from './components/Banquets';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Deluxe from './components/Carousel/deluxe';
import Continental from './components/Carousel/Continental';
import Presidential from './components/Carousel/Presidential';
import Bookingform from './components/Bookingform';
import Deluxebookingform from './components/Carousel/Deluxebookingform';
import Homes from "./screens/home/Home";
 import Createpost from "./screens/createpost/Createpost";
 import Editpost from "./screens/editpost/Editpost";
 import Postdetail from "./screens/postdetail/Postdetail";
 import Login from './screens/login/Login';
 import Signup from './screens/singup/Signup';
 import { useAuthContext } from './hooks/useAuthContext';
import Bookingformfetch from './components/Bookingformfetch';
import Bookingview from './components/Bookingview';
import Paymentrazor from './components/Paymentrazor';
import Classicbookview from './components/Classicbookview';
import Deluxebookdetail from './components/BookingPages/Deluxebookdetail';
import Deluxebooking from './components/BookingPages/Deluxebooking';
import Deluxebookingview from './components/BookingPages/Deluxebookingview';
import Deluxeshow from './components/BookingPages/Deluxeshow';
import Continentbookdetail from './components/Continental/Continentbookdetail';
import Continentbooking from './components/Continental/Continentbooking';
import Continentshow from './components/Continental/Continentshow';
import Continentview from './components/Continental/Continentview';
import Presidentialbookdetail from './components/Presidential/Presidentialbookdetail';
import Presidentialbooking from './components/Presidential/Presidentialbooking';
import Presidentialbookshow from './components/Presidential/Presidentialbookshow';
import Presidentialbookview from './components/Presidential/Presidentialbookview';
import Benquetmenu from './components/Benquetmenu';
import Benqdata from './screens/home/Benqdata';
import Benqdataview from './screens/home/Benqdataview';
import Bookhoteldata from './screens/home/Bookhoteldata';
import Bookhotellist from './screens/home/Bookhotellist';
import Userdetails from './components/UserDetails/Userdetails';
import Userviews from './components/UserDetails/Userviews';
import Popupwindow from './components/Popupwindow';
function App() {
    const {user,isAuthReady} = useAuthContext()
    return (
        <BrowserRouter>
       
        {isAuthReady&&<Routes>
            <Route path="/" element={ <Home /> } />
            <Route path="/rooms" element={ <Rooms /> } />
            <Route path="/banquets" element={ <Banquets /> } />
            <Route path="/resturants" element={ <Resturants /> } />
            <Route path="/bars" element={ <Bars /> } />
            <Route path="/about" element={ <About /> } />
            <Route path="/gallery" element={ <Gallery /> } />
            <Route path="/panaroma" element={ <Panaroma /> } />
            <Route path="/booking" element={ <Booking /> } />
            <Route path="/booknow" element={ <Booknow /> } />
            <Route path="/deluxe" element={ <Deluxe /> } />
            <Route path="/benquetmenu" element={user ? <Benquetmenu /> : <Navigate to='/login'/> } />
            <Route path="/continental" element={ <Continental /> } />
            <Route path="/presidential" element={ <Presidential /> } />
            <Route path="/bookform" element={user ? <Bookingform /> : <Navigate to='/login'/> } />
            <Route path="/deluxebookingform" element={ <Deluxebookingform /> } />
            <Route path="/bookingformfetch" element={user ? <Bookingformfetch /> : <Navigate to='/login'/> } />
            <Route path="/bookingview" element={user ? <Bookingview /> : <Navigate to='/login'/> } />
            <Route path="/classicbookview" element={user ? <Classicbookview /> : <Navigate to='/login'/> } />
            <Route path="/adminpanels" element={user ? <Homes /> : <Navigate to='/login'/> } />
      <Route path="/create" element={ user ? <Createpost /> : <Navigate to='/login'/> } />
      <Route path="/post/:id" element={ user ? <Postdetail /> : <Navigate to='/login'/> } />
      <Route path="/edit/:id" element={ user ? <Editpost/> : <Navigate to='/login'/> }/>
      <Route path="/login" element={!user ? <Login/> : <Navigate to={(user.uid === "xxlHP783TtRg1NE0Tx88wcMzT6v2") ? "/adminpanels" : "/"} />}/>
      <Route path="/signup" element={!user ?  <Signup/> : <Navigate to='/login'/>}/>
      <Route path="/paymentrazor" element={ <Paymentrazor /> } />
      <Route path="/deluxebooking" element={user ? <Deluxebooking /> : <Navigate to='/login'/> } />
      <Route path="/deluxebookdetail" element={user ? <Deluxebookdetail /> : <Navigate to='/login'/> } />
      <Route path="/deluxebookingview" element={user ? <Deluxebookingview /> : <Navigate to='/login'/> } />
      <Route path="/deluxeshow" element={user ? <Deluxeshow /> : <Navigate to='/login'/> } />
      <Route path="/continentbookdetail" element={user ? <Continentbookdetail /> : <Navigate to='/login'/> } />
      <Route path="/continentbooking" element={user ? <Continentbooking /> : <Navigate to='/login'/> } />
      <Route path="/continentshow" element={user ? <Continentshow /> : <Navigate to='/login'/> } />
      <Route path="/continentview" element={user ? <Continentview /> : <Navigate to='/login'/> } />
      <Route path="/presidentialbookdetail" element={user ? <Presidentialbookdetail /> : <Navigate to='/login'/> } />
      <Route path="/presidentialbooking" element={user ? <Presidentialbooking /> : <Navigate to='/login'/> } />
      <Route path="/presidentialbookshow" element={user ? <Presidentialbookshow /> : <Navigate to='/login'/> } />
      <Route path="/presidentialbookview" element={user ? <Presidentialbookview /> : <Navigate to='/login'/> } />
      <Route path="/benqdata" element={ <Benqdata /> } />
      <Route path="/benqdataview" element={user ? <Benqdataview /> : <Navigate to='/login'/> } />
      <Route path="/bookhoteldata" element={ <Bookhoteldata /> } />
      <Route path="/bookhotellist" element={user ?  <Bookhotellist /> : <Navigate to='/login'/> } />
      <Route path="/userdetails" element={user ?  <Userdetails /> : <Navigate to='/login'/> } />
      <Route path="/userviews" element={user ?  <Userviews /> : <Navigate to='/login'/> } />
      <Route path="/popupwindow" element={ <Popupwindow /> } />
      
            
        </Routes>}
       
        </BrowserRouter>
        
    );
}

export default App;