import React from 'react';
import logo from './masko.jpeg';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';

const Booknow = () => {
  return (
    <div>
    <header className="header-area fixed header-sticky" >
    <div className="container">
      <div className="row">
        <div className="col-xl-5 col-lg-4 col-sm-4 col-12">
          <div className="logo">
            <Link to="#"><img src={logo} alt="Oestin" style={{ height: "43px", width: "12rem" }} /></Link>
          </div>
        </div>
        <div className="col-xl-7 col-lg-8 col-sm-8 col-12">
          <div className="header-top fix">
            <div className="header-contact">
              <span className="text-theme">Contact:</span>
              <span>0123456789</span>
            </div>
            <div className="header-links">
              <Link to="https://www.facebook.com/"><i className="zmdi zmdi-facebook"></i></Link>
              <Link to="https://twitter.com/"><i className="zmdi zmdi-twitter"></i></Link>
              <Link to="https://plus.google.com/"><i className="zmdi zmdi-google-plus"></i></Link>
              <Link to="https://www.instagram.com/"><i className="zmdi zmdi-instagram"></i></Link>
              <Link to="https://www.pinterest.com/"><i className="zmdi zmdi-pinterest"></i></Link>
            </div>
          </div>

          <div className="main-menu d-none d-lg-block">
                    <Navbar />
          </div>

        </div>
      </div>
    </div>

    <div className="mobile-menu-area">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="mobile-menu">
              <nav id="dropdown">
                    <Navbar />
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>

</header>
<section className="breadcrumb-area overlay-dark-2 bg-3">	
      <div className="container">
          <div className="row">
              <div className="col-12">
                  <div className="breadcrumb-text text-center">
                      <h2>Moskva - BOOK NOW </h2>
                      <p>Booking the Rooms of Moskva.</p>
                      <div className="breadcrumb-bar">
                          <ul className="breadcrumb">
                              <li><Link to="index.html">Home</Link></li>
                              <li>Moskva - Booknow</li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
<section className="pt-5">
    <div className="container ">
    <div className="row">
        <div className="col-md-10 mx-auto col-12 card shadow-lg border-0 p-4">
            <div>
                <h1 className="display-4 section-title text-center">Booking</h1>
            </div>
            <div className="row">
                <div className="col-md-6 col-12 my-auto">
                    <img src="assets/img/room/3.jpg" className="" alt="selected room"  style={{ width: "100%" , height: "25rem"}} />
                </div>
                <div className="col-md-6 col-12 my-auto">
                    <h1 className="section-title">Rooms Details</h1>
                    <table className="table">
                        <thead className="thead-light">
                        <tr>
                        <th>Room Type</th>
                        <td>Single Room</td>
                    </tr>
                    <tr>
                        <th>Adults</th>
                        <td>2</td>
                    </tr>
                    <tr>
                        <th>Children</th>
                        <td>2 </td>
                    </tr>
                    <tr>
                        <th>Breakfast</th>
                        <td>Yes</td>
                    </tr>
                    <tr>
                        <th>Pickup/Drop</th>
                        <td>No</td>
                    </tr>
                        </thead>
                    </table>
                </div>
            </div>
            <div className="row my-3">
                <div className="col-md-6 col-12 form-container fix">
                <label htmlFor="Fromdate" className="font-weight-bolder ">From Date </label>
                    <div className="form-group box-select">
                   
                        <div class="select date ">
                        
                        <input type="date" name="arrive" />
                    </div>
                   
                    </div>
                </div>
                <div className="col-md-6 col-12">
                <label htmlFor="Todate" className="font-weight-bolder mr-3">To Date </label>
                    <div className="form-group box-select">
                        
                        <div class="select date">
                                        <input type="date" name="arrive" />
                                    </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 col-12 author-name">
                    <h6 className="font-weight-bolder pb">Number of days : 4</h6>
                    <p className='pt-2'>Please make sure Checkin time is from 9 am to 12 pm</p>
                </div>
                <div className="col-md-6 col-12 author-name">
                    <h6 className="font-weight-bold">Price per day : <span className="badge badge-info text-black">Rs 250</span></h6>
                    <h6 className="font-weight-bold pt-2">Total Price to be paid : <span className="badge badge-info text-black">Rs 300</span></h6>
                </div>
            </div>
            <div className="row my-4">
                <div className="col-md-6 col-12">
                    <div className="form-group">
                        <label htmlFor="payment" className="font-weight-bolder">Payment Options</label>
                        <select className="form-control">
                            <option disabled>Select payment option</option>
                           
                            <option value="Debit">Debit Card</option>
                            <option value="checkin">Pay during Checkin</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-6 col-12 my-auto">
                    <div className="col-md-6 col-12 float-right">
                        <button className="btn btn-white  search default-btn text-center" data-toggle="modal" data-target="#thanks">Confirm Booking</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="modal fade" id="thanks">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-body p-4">
                    <h3>Thank you </h3>
                    <p className="lead">Your room is booked successfully....</p>
                </div>
                <div className="modal-footer">
                    <Link to="/" className="btn btn-dark">Goto Home</Link>
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
</section>

<footer className="footer-area pt-5">
      
      <div className="footer-widget-area bg-dark">
        <div className="container">
          <div className="row mb-n60">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <div className="footer-logo">
                  <Link to="#"><img src={logo} alt="Oestin" style={{ height: "43px", width: "12rem" }} /></Link>
                </div>
                <p>
                  Lorem ipsum dolor sit amet, conse acteir our adipisicing elit,
                  sed do eiusmod the a tempor the incididunt ut labore et dolore
                  magnaa liqua. Ut enim minimn.
                </p>
                <div className="social-icons">
                  <Link to="#"><i className="zmdi zmdi-facebook"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-instagram"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-rss"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-twitter"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-pinterest"></i></Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 offset-lg-1 col-lg-4  col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <h3>contact us</h3>
                <div className="c-info">
                  <span><i className="zmdi zmdi-pin"></i></span>
                  <span>Your address <br />goes here</span>
                </div>
                <div className="c-info">
                  <span><i className="zmdi zmdi-email"></i></span>
                  <span>demo@example.com<br />demo@example.com</span>
                </div>
                <div className="c-info">
                  <span><i className="zmdi zmdi-phone"></i></span>
                  <span>0123456789<br />0123456789</span>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <h3>quick links</h3>
                <ul className="footer-list">
                  <li><Link to="#">Home</Link></li>
                  <li><Link to="#">Stuffs</Link></li>
                  <li><Link to="#">Suits &amp; Rooms</Link></li>
                  <li><Link to="#">Event</Link></li>
                  <li><Link to="#">Location</Link></li>
                  <li><Link to="#">Contact</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <h3>collections</h3>
                <div className="instagram-image">
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/footer/1.jpg" alt=""/></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/footer/2.jpg" alt=""/></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/footer/3.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#" ><img src="assets/img/footer/4.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/footer/5.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#" ><img src="assets/img/footer/6.jpg" alt="" /></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      
     
    </footer>
    </div>
  )
}

export default Booknow