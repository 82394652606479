import React from 'react';
import logo from './hotel_logo.png';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import  barImage from './bar.jpg';
import Header from './Header.js';

const Bars = () => {
  return (
    <div>
    <Header />
<section className="breadcrumb-area overlay-dark-2 bg-3">	
      <div className="container">
          <div className="row">
              <div className="col-12">
                  <div className="breadcrumb-text text-center">
                      <h2>Moskva - BARS </h2>
                      <p>Booking the bars of Moskva.</p>
                      <div className="breadcrumb-bar">
                          <ul className="breadcrumb">
                              <li><Link to="index.html">Home</Link></li>
                              <li>Moskva - Bars</li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
  
  <section class="team-area pt-90 team-section">
        <div class="container">
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div className="section-title text-center">
                <h3>BARS</h3>
                <p className="pb-10">The hotel also provides guests with bottled water, a coffee or tea maker and mini bar in some rooms.“Great location, wonderful view, cool design and a great roof top bar and restaurant”
                Located 6 km away from Meenakshi Temple, Poppys Hotel features the Melting Pot restaurant and an elegant, well-stocked bar. </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-sm-6">
              <div class="single-team">
                <div class="team-image"><img src="assets/img/bar/bar1.jpg" alt="" style={{ height: "480px" }} /></div>
                <div class="team-hover">
                  <h4>DOROGYA MOSKVA</h4>
                  <span class="block">( Dorogya Moskva )</span>
                  <div className="address-distance fix">
                    <ul class="list-group resturantList text-center">
                      <li class="list-group-item ">DOMESTIC BEER</li>
                      <li class="list-group-item ">IMPORTED BEER</li>
                      

                    </ul>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="single-team">
                <div class="team-image"><img src="assets/img/bar/bar2.jpg" alt="" style={{ height: "480px" }} /></div>
                <div class="team-hover">
                  <h4>MOSKVA BARS</h4>
                  <span class="block">( Moskva Bars )</span>
                  <div className="address-distance fix">
                    <ul class="list-group resturantList text-center">
                      <li class="list-group-item ">WHISKY IMPORTED</li>
                      <li class="list-group-item ">DOMESTIC WHISKY</li>
                      
                    </ul>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="single-team">
                <div class="team-image"><img src="assets/img/bar/bar3.jpg" alt="" style={{ height: "480px" }} /></div>
                <div class="team-hover">
                  <h4>MOSKVA BARS</h4>
                  <span class="block">( Moskva Bars )</span>
                  <div className="address-distance fix">
                    <ul class="list-group resturantList text-center">
                      <li class="list-group-item ">DOMESTIC BRANDY</li>
                      <li class="list-group-item ">COGNAG </li>
                      <li class="list-group-item">RUM</li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>

            <div class="col-lg-4 col-sm-6">
              <div class="single-team">
                <div class="team-image"><img src="assets/img/bar/bar4.jpg" alt="" style={{ height: "480px" }} /></div>
                <div class="team-hover">
                  <h4>MOSKVA BARS</h4>
                  <span class="block">( Moskva Bars )</span>
                  <div className="address-distance fix">
                    <ul class="list-group resturantList text-center">
                      <li class="list-group-item ">IMPORTED VODKA</li>
                      <li class="list-group-item ">IMPORTED WINE</li>
                      <li class="list-group-item">DOMESTIC VODKA</li>
                      <li class="list-group-item">DOMESTIC WINE</li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="single-team">
                <div class="team-image"><img src="assets/img/bar/bar5.jpg" alt="" style={{ height: "480px" }} /></div>
                <div class="team-hover">
                  <h4>MOSKVA BARS</h4>
                  <span class="block">( Moskva Bars )</span>
                  <div className="address-distance fix">
                    <ul class="list-group resturantList text-center">
                      <li class="list-group-item ">COCKTAILS</li>
                      <li class="list-group-item ">MIXTURE & COOLERS</li>
                      <li class="list-group-item">MOCKTAILS</li>
                      
                    </ul>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="single-team">
                <div class="team-image"><img src="assets/img/bar/bar6.jpg" alt="" style={{ height: "480px" }} /></div>
                <div class="team-hover">
                  <h4>MOSKVA BARS</h4>
                  <span class="block">( Moskva Bars )</span>
                  <div className="address-distance fix">
                    <ul class="list-group resturantList text-center">
                      <li class="list-group-item ">SNACKS</li>
                      <li class="list-group-item ">BITS / VEG</li>
                      <li class="list-group-item">NON-VEG</li>
                      
                    </ul>
                  </div>

                </div>
              </div>
            </div>
            


          </div>
        </div>
      </section>


<footer className="footer-area pt-5">
      
      <div className="footer-widget-area bg-dark">
        <div className="container">
          <div className="row mb-n60">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <div className="footer-logo">
                  <Link to="#"><img src={logo} alt="Oestin" style={{ width: "14rem" }} /></Link>
                </div>
                <p>
                Moskva Hotel is a 3-star property located 15 kilometers from Madurai Airport, 900 meters from Madurai Junction Railway Station, and one kilometer from Periyar Bus Stand.
                </p>
                <div className="social-icons">
                  <Link to="#"><i className="zmdi zmdi-facebook"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-instagram"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-rss"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-twitter"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-pinterest"></i></Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 offset-lg-1 col-lg-4  col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <h3>contact us</h3>
                <div className="c-info">
                  <span><i className="zmdi zmdi-pin"></i></span>
                  <span>57, Tamilsangam Road, Madurai,  <br />Tamil Nadu 625001</span>
                </div>
                <div className="c-info">
                  <span><i className="zmdi zmdi-email"></i></span>
                  <span>info@moskvahotel.in<br />info@moskvahotel.in</span>
                </div>
                <div className="c-info">
                  <span><i className="zmdi zmdi-phone"></i></span>
                  <span>+91-8489914838<br />+91-452 4219063</span>
                </div>
                <div style={{display:"flex"}}>
                <div className="footer-logo">
                    <a href="https://play.google.com/store/games?hl=en&gl=US&pli=1"><img src="assets/img/moskva-photos/small/plays.png" alt="Oestin" style={{ width: "10rem" }} /></a>
                  </div>
                  <div className="footer-logo" style={{paddingLeft:"5px"}}>
                    <a href="https://www.apple.com/in/app-store/"><img src="assets/img/moskva-photos/small/play.png" alt="Oestin" style={{ width: "10rem" }} /></a>
                  </div>
                  </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <h3>quick links</h3>
                <ul className="footer-list">
                <li><Link to="/">Home</Link></li>
                    <li><Link to="/rooms">Rooms</Link></li>
                    <li><Link to="/benquetmenu">Banquets Menu</Link></li>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/resturants">Resturants</Link></li>
                    <li><Link to="/bars">Bar</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <h3>collections</h3>
                <div className="instagram-image">
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC0962.jpg" alt=""/></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC0980.jpg" alt=""/></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC1004.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#" ><img src="assets/img/moskva-photos/small/DSC1063.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC1071.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#" ><img src="assets/img/moskva-photos/small/DSC1092.jpg" alt="" /></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='text-center'> 
        <p className="designFont">© 2023 MOSKVA All rights reserved. Designed By <a className="designFont" href="https://jofinity.com/">JOFINITY</a></p>
        </div>
      </div>
      
      
     
    </footer>
    </div>
  )
}

export default Bars