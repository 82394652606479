import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import './Carousel.css';
//import Image from "./1.jpg";
//import Image1 from "./2.jpg";

const options = {
  loop: true,
  nav: true,
  navText: [""],
  dots: false,
  autoplay: true,
  autoplayTimeout: 4000,
  smartSpeed: 1200,
  autoplayHoverPause: true,
  lazyLoad: true,
  items: 1,

};

class Slider extends Component {
  render() {
    return (
      <div>
        <OwlCarousel className="classic-testimonial-slider slider-items owl-carousel" {...options}>
          .<div className="item">

            <div class="slider-image">
              <img src="assets/img/moskva-photos/Classic/DSC_0998.jpg" alt="" />
              
            </div>


          </div>
          <div className="item" >
            <div class="slider-image">
              <img src="assets/img/moskva-photos/Classic/DSC_1003.jpg" alt="" />
              
            </div>

          </div>
          <div className="item" >
            <div class="slider-image">
              <img src="assets/img/moskva-photos/Classic/DSC_1004.jpg" alt="" />
              
            </div>

          </div>
          <div className="item" >
            <div class="slider-image">
              <img src="assets/img/moskva-photos/Classic/DSC_1009.jpg" alt="" />
              
            </div>

          </div>
          <div className="item" >
            <div class="slider-image">
              <img src="assets/img/moskva-photos/Classic/DSC_1011.jpg" alt="" />
              
            </div>

          </div>
        </OwlCarousel>
      </div>
    );
  }
}

export default Slider;