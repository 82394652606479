import React from "react";
import { useNavigate } from "react-router-dom";
import "./Post.css";
import logo from './../hotel_logo.png';
import Navbar from './../Navbar';
import { Link } from 'react-router-dom';
import Header from "../Header";
//import { useThemeContext } from './../../hooks/useThemeContext';
//import moment from "moment";
export default function Post({post}) {

  //const {theme} = useThemeContext()

  const navigate = useNavigate()


  const handleClick = () => {
    navigate(`/post/${post.id}`,{state:post})
  }
  //console.log(post.single);
  return (
    <div>
    <Header />
  
    <div className="pt-5 " onClick={handleClick}>
      
    <div class="card" >
  
    <div class="card-body">
      <h5 class="card-title">{post.RoomName}</h5>
      
    </div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item"><span>Price</span> - <span>{post.Price}</span></li>
      <li class="list-group-item"><span>Double</span> - <span>{post.Double}</span></li>
      <li class="list-group-item"><span>Third Person Price</span> - <span>{post.Third}</span></li>
      <li class="list-group-item"><span>Food Price</span> - <span>{post.Food}</span></li>
      <li class="list-group-item"><span>Discount Price</span> - <span>{post.Discount}</span></li>
    </ul>
    
    
  </div>
    </div>
    
    
    </div>

    
  );
}
