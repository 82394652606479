import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore'
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyCfunuxMaKb-ym4NArUVzRxUT_PHcU_TGM",
    authDomain: "moskva-158dc.firebaseapp.com",
    projectId: "moskva-158dc",
    storageBucket: "moskva-158dc.appspot.com",
    messagingSenderId: "101341043502",
    appId: "1:101341043502:web:1a1b29e652de88441db8bf"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore();
const auth = getAuth();
const storage = getStorage(app);

export { db, auth, storage };