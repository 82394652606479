import React from 'react';
import logo from './hotel_logo.png';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import Header from './Header.js';


const Gallery = () => {
  return (
    <div>
    <Header />

<section className="breadcrumb-area overlay-dark-2 bg-3">	
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="breadcrumb-text text-center">
							<h2>GALLERY</h2>
							<p>Gallery Views of MASKVA</p>
							<div className="breadcrumb-bar">
								<ul className="breadcrumb">
									<li><Link to="index.html">Home</Link></li>
									<li>Gallery</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>


    <section className="event-area pt-90">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 mx-auto">
                        <div className="section-title text-center">
                            <h3>GALLERY</h3>
                            <p>Arbatskaya Restaurant, the best homemade food online service in Madurai. Especially, we provide the dishes that are healthy, nutrition and organic. We prepare a variety of fresh foods with the taste of our customers in mind.  </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="event-menu">                                                 
                            <button data-filter="*" className="active"> All</button>
                            <button data-filter=".music">Music Party</button>
                            <button data-filter=".birthday">Birthday Party</button>
                            <button data-filter=".conference">Conference</button>
                            <button data-filter=".wedding">Wedding Party</button>
                            <button data-filter=".photography">Photography Party</button>
                        </div>
                    </div>
                </div>
                <div className="row event-items">
                    <div className="single-event col-md-8 col-12 conference">
                        <div className="single-event-item">
                           <img src="assets/img/moskva-photos/gallery/small/moskva777.jpg" alt="" />
                           <a href="assets/img/moskva-photos/gallery/moskva.jpg" class="fancybox" data-fancybox="gallery1">
                            <div className="event-text">
                                <h3 className="event-title">management conferences</h3>
                                <h3 className="author-name">By : Maskva</h3>
                                <p>Hotel Moskva is a contemporary property endowed with the latest facilities and an enviable location in the city.</p>
                            </div>
                            </a>
                        </div>
                    </div>
                    <div className="single-event col-md-4 small col-12 music">
                        <div className="single-event-item">
                           <img src="assets/img/moskva-photos/gallery/small/1569929676-383.jpg" alt="" />
                           <a href="assets/img/moskva-photos/gallery/1569929676.jpg" class="fancybox" data-fancybox="gallery1">
                            <div className="event-text">
                                <h3 className="event-title">Meetings & Events</h3>
                                <h3 className="author-name">By : Maskva</h3>
                                <p>We can organize your special events with us at one of the best banquet halls in Moskva Hotel.</p>
                            </div>
                            </a>
                        </div>
                    </div>
                    <div className="single-event col-md-4 small col-12 birthday">
                        <div className="single-event-item">
                           <img src="assets/img/moskva-photos/gallery/small/207832595-383.jpg" alt="" /> 
                           <a href="assets/img/moskva-photos/gallery/207832595.jpg" class="fancybox" data-fancybox="gallery1">
                            <div className="event-text">
                                <h3 className="event-title">conferences</h3>
                                <h3 className="author-name">By : Maskva</h3>
                                <p>The widespread arrangement at best party halls in Madurai helps make your event a successful one.</p>
                            </div>
                            </a>
                        </div>
                    </div>
                    <div className="single-event col-md-8 col-12 wedding">
                        <div className="single-event-item">
                           <img src="assets/img/moskva-photos/gallery/small/DSC_1062-777.jpg" alt="" />
                           <a href="assets/img/moskva-photos/gallery/DSC1062.jpg" class="fancybox" data-fancybox="gallery1">
                            <div className="event-text">
                                <h3 className="event-title">Restaurant </h3>
                                <h3 className="author-name">By : Maskva</h3>
                                <p>Arbatskaya Restaurant at Hotel Moskva Hotel in Madurai may have a traditional touch with unique flavors.</p>
                            </div>
                            </a>
                        </div>
                    </div>
                    <div className="single-event col-md-8 col-12 photography">
                        <div className="single-event-item">
                           <img src="assets/img/moskva-photos/gallery/small/DSC_1072(1)-777.jpg" alt="" />
                           <a href="assets/img/moskva-photos/gallery/DSC1072.jpg" class="fancybox" data-fancybox="gallery1">
                            <div className="event-text">
                                <h3 className="event-title">continental</h3>
                                <h3 className="author-name">By : Maskva</h3>
                                <p>The rooms offer a wide range of modern amenities to suit the needs of the discerning traveler.</p>
                            </div>
                            </a>
                        </div>
                    </div>
                    <div className="single-event col-md-4 small col-12 photography">
                        <div className="single-event-item">
                           <img src="assets/img/moskva-photos/gallery/small/DSC_0979-383.jpg" alt="" />
                           <a href="assets/img/moskva-photos/gallery/DSC0979.jpg" class="fancybox" data-fancybox="gallery1">
                            <div className="event-text">
                                <h3 className="event-title">presidential</h3>
                                <h3 className="author-name">By : Maskva</h3>
                                <p>The Moskva Hotel, a Madurai hotel, features 44 elegantly appointed spacious rooms.</p>
                            </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="newsletter-area bg-light">
        <div className="container">
            <div className="row">
            <div className="col-xl-8 mx-auto col-12">
                <div className="newsletter-container">
                <h3>NewsLetter Sign-Up</h3>
                <div className="newsletter-form">
                    <form action="#" id="mc-form" className="mc-form fix">
                    <input id="mc-email" type="email" name="email" placeholder="Enter your E-mail"/>
                    <button id="mc-submit" type="submit" className="default-btn">subcribes</button>
                    </form>
                   
                    <div className="mailchimp-alerts">
                    <div className="mailchimp-submitting"></div>
                    
                    <div className="mailchimp-success"></div>
                    
                    <div className="mailchimp-error"></div>
                    
                    </div>
                    
                </div>
                </div>
            </div>
            </div>
        </div>
    </section>


<footer className="footer-area">
      
      <div className="footer-widget-area bg-dark">
        <div className="container">
          <div className="row mb-n60">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <div className="footer-logo">
                  <Link to="#"><img src={logo} alt="Oestin" style={{ width: "14rem" }} /></Link>
                </div>
                <p>
                Moskva Hotel is a 3-star property located 15 kilometers from Madurai Airport, 900 meters from Madurai Junction Railway Station, and one kilometer from Periyar Bus Stand.
                </p>
                <div className="social-icons">
                  <Link to="#"><i className="zmdi zmdi-facebook"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-instagram"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-rss"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-twitter"></i></Link>
                  <Link to="#"><i className="zmdi zmdi-pinterest"></i></Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 offset-lg-1 col-lg-4  col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <h3>contact us</h3>
                <div className="c-info">
                  <span><i className="zmdi zmdi-pin"></i></span>
                  <span>57, Tamilsangam Road, Madurai,  <br />Tamil Nadu 625001</span>
                </div>
                <div className="c-info">
                  <span><i className="zmdi zmdi-email"></i></span>
                  <span>info@moskvahotel.in<br />info@moskvahotel.in</span>
                </div>
                <div className="c-info">
                  <span><i className="zmdi zmdi-phone"></i></span>
                  <span>+91-8489914838<br />+91-452 4219063</span>
                </div>
                <div style={{display:"flex"}}>
                <div className="footer-logo">
                    <a href="https://play.google.com/store/games?hl=en&gl=US&pli=1"><img src="assets/img/moskva-photos/small/plays.png" alt="Oestin" style={{ width: "10rem" }} /></a>
                  </div>
                  <div className="footer-logo" style={{paddingLeft:"5px"}}>
                    <a href="https://www.apple.com/in/app-store/"><img src="assets/img/moskva-photos/small/play.png" alt="Oestin" style={{ width: "10rem" }} /></a>
                  </div>
                  </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <h3>quick links</h3>
                <ul className="footer-list">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/rooms">Rooms</Link></li>
                <li><Link to="/benquetmenu">Banquets Menu</Link></li>
                <li><Link to="/about">About</Link></li>
                <li><Link to="/resturants">Resturants</Link></li>
                <li><Link to="/bars">Bar</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-60">
              <div className="single-footer-widget">
                <h3>collections</h3>
                <div className="instagram-image">
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC0962.jpg" alt=""/></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC0980.jpg" alt=""/></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC1004.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#" ><img src="assets/img/moskva-photos/small/DSC1063.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#"><img src="assets/img/moskva-photos/small/DSC1071.jpg" alt="" /></Link>
                  </div>
                  <div className="footer-img">
                    <Link to="#" ><img src="assets/img/moskva-photos/small/DSC1092.jpg" alt="" /></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='text-center'> 
        <p className="designFont">© 2023 MOSKVA All rights reserved. Designed By <a className="designFont" href="https://jofinity.com/">JOFINITY</a></p>
        </div>
      </div>
      
      
     
    </footer>
    </div>
  )
}

export default Gallery